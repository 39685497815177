/* lato-300 - latin */
/*@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin-300.eot'); !* IE9 Compat Modes *!
  src: local('Lato Light'), local('Lato-Light'),
  url('../fonts/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-300.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-300.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-300.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-300.svg#Lato') format('svg'); !* Legacy iOS *!
}

!* lato-regular - latin *!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-regular.eot'); !* IE9 Compat Modes *!
  src: local('Lato Regular'), local('Lato-Regular'),
  url('../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-regular.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-regular.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); !* Legacy iOS *!
}

!* lato-700 - latin *!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v16-latin-700.eot'); !* IE9 Compat Modes *!
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-700.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-700.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-700.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-700.svg#Lato') format('svg'); !* Legacy iOS *!
}

!* lato-900 - latin *!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v16-latin-900.eot'); !* IE9 Compat Modes *!
  src: local('Lato Black'), local('Lato-Black'),
  url('../fonts/lato-v16-latin-900.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-900.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-900.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-900.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-900.svg#Lato') format('svg'); !* Legacy iOS *!
}*/

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/eot/iranyekanwebbold(fanum).eot');
  src: url('../fonts/eot/iranyekanwebbold(fanum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff2/iranyekanwebbold(fanum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/woff/iranyekanwebbold(fanum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebbold(fanum).ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/eot/iranyekanweblight(fanum).eot');
  src: url('../fonts/eot/iranyekanweblight(fanum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff2/iranyekanweblight(fanum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/woff/iranyekanweblight(fanum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanweblight(fanum).ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/eot/iranyekanwebregular(fanum).eot');
  src: url('../fonts/eot/iranyekanwebregular(fanum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff2/iranyekanwebregular(fanum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/woff/iranyekanregular(fanum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebregular(fanum).ttf') format('truetype');
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?rmm2sz');
  src:  url('../fonts/icons/icomoon.eot?rmm2sz#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.ttf?rmm2sz') format('truetype'),
    url('../fonts/icons/icomoon.woff?rmm2sz') format('woff'),
    url('../fonts/icons/icomoon.svg?rmm2sz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-buy-text:before {
    content: "\e946";
}
.icon-arrow-double:before {
    content: "\e945";
}
.icon-minus-border:before {
    content: "\e944";
}
.icon-contact-us-icon:before {
    content: "\e943";
}
.icon-mobile:before {
    content: "\e942";
}
.icon-wix-shop:before {
    content: "\e941";
}
.icon-share:before {
    content: "\e940";
}
.icon-arrow-circle:before {
    content: "\e93f";
}
.icon-amazing-offers:before {
    content: "\e93c";
}
.icon-toman:before {
    content: "\e93d";
}
.icon-to:before {
    content: "\e93e";
}
.icon-close:before {
    content: "\e900";
}
.icon-home:before {
    content: "\e901";
}
.icon-report:before {
    content: "\e902";
}
.icon-filter:before {
    content: "\e903";
}
.icon-uniE904:before {
    content: "\e904";
}
.icon-uniE905:before {
    content: "\e905";
}
.icon-pen:before {
    content: "\e906";
}
.icon-recycle-bin:before {
    content: "\e907";
}
.icon-close1:before {
    content: "\e908";
}
.icon-heart:before {
    content: "\e909";
}
.icon-guard:before {
    content: "\e90a";
}
.icon-umbrella:before {
    content: "\e90b";
}
.icon-user:before {
    content: "\e90c";
}
.icon-comment:before {
    content: "\e90d";
}
.icon-drop-down:before {
    content: "\e90e";
}
.icon-more-vertical:before {
    content: "\e90f";
}
.icon-tick:before {
    content: "\e910";
}
.icon-polygon:before {
    content: "\e911";
}
.icon-page-customer:before {
    content: "\e912";
}
.icon-mail:before {
    content: "\e913";
}
.icon-reply:before {
    content: "\e914";
}
.icon-like:before {
    content: "\e915";
}
.icon-arrow-right:before {
    content: "\e916";
}
.icon-logo-text:before {
    content: "\e917";
}
.icon-pluse-border:before {
    content: "\e918";
}
.icon-gift:before {
    content: "\e919";
}
.icon-arrow-left:before {
    content: "\e91a";
}
.icon-ask:before {
    content: "\e91b";
}
.icon-card:before {
    content: "\e91c";
}
.icon-cart:before {
    content: "\e91d";
}
.icon-chief:before {
    content: "\e91e";
}
.icon-computer:before {
    content: "\e91f";
}
.icon-cube:before {
    content: "\e920";
}
.icon-facebook:before {
    content: "\e921";
}
.icon-game:before {
    content: "\e922";
}
.icon-gmail:before {
    content: "\e923";
}
.icon-instagram:before {
    content: "\e924";
}
.icon-linkdin:before {
    content: "\e925";
}
.icon-location:before {
    content: "\e926";
}
.icon-logo:before {
    content: "\e927";
}
.icon-logo-shop:before {
    content: "\e928";
}
.icon-menu:before {
    content: "\e929";
}
.icon-more:before {
    content: "\e92a";
}
.icon-mouse:before {
    content: "\e92b";
}
.icon-percent:before {
    content: "\e92c";
}
.icon-phone-pad:before {
    content: "\e92d";
}
.icon-photography:before {
    content: "\e92e";
}
.icon-pluse:before {
    content: "\e92f";
}
.icon-programming:before {
    content: "\e930";
}
.icon-rate:before {
    content: "\e931";
}
.icon-search:before {
    content: "\e932";
}
.icon-support:before {
    content: "\e933";
}
.icon-telegram:before {
    content: "\e934";
}
.icon-transportation:before {
    content: "\e935";
}
.icon-user-plus:before {
    content: "\e936";
}
.icon-whats-app:before {
    content: "\e937";
}
.icon-accessories:before {
    content: "\e938";
}
.icon-momentary-products:before {
    content: "\e939";
}
.icon-buy-product:before {
    content: "\e93a";
}
.icon-offer:before {
    content: "\e93b";
}