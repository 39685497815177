@media (max-width: 1700px) {
  .amazing-offers {
    &__bg-image {
      display: none;
    }
  }
}

@media (max-width: 1450px) {
  .buyer {
    transform: scale(.8);
  }
  .seller {
    transform: scale(.8);
  }
}

@media (max-width: 1350px) {
  .buyer {
    &__animation {
      right: 90px;
    }

    &__item {
      &--info {
        right: -140px;
      }
    }
  }

  .seller {
    &__animation {
      left: 160px;
    }
  }
}

@media (max-width: 1200px) {
  .main-info {
    margin-top: -130px;
  }
  .buyer {
    $self: &;
    transform: scale(.7);

    &__animation {
      right: 40px;
    }

    &__background {
      display: none;
    }

    &__item {
      #{$self}__item {
        &--info {
          display: none;
        }
      }
    }
  }
  .seller {
    $self: &;
    transform: scale(.7);

    &__animation {
      left: 120px;
    }

    &__background {
      display: none;
    }

    &__item {
      #{$self}__item {
        &--info {
          display: none;
        }
      }
    }
  }
  .store {
    top: 340px;
  }
  .product {
    $self: &;

    &__header {
      h2 {
        margin-left: 15px;
      }

      #{$self}__header-cat {
        #{$self}__header-cat-item {
          padding: 0 15px 0 15px;
        }
      }
    }

    &__content {
      .swiper-slide {
        transition: .1s;
        opacity: 0;

        &.swiper-slide-active {
          opacity: 1;

          + .swiper-slide {
            opacity: 1;

            + .swiper-slide {
              opacity: 1;

              + .swiper-slide {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
  .top-cat {
    padding-top: 70px;
    $self: &;

    &__item {
      #{$self}__item-image {
        &--first {
          left: 0;
        }

        &--second {
          left: 0;
        }

        &--third {
          left: 0;
        }
      }
    }
  }
  .top-sellers {
    &__nav {
      display: flex;
    }
  }
  .roadmap {
    $self: &;

    &__road {
      #{$self}__road-item {
        &--step1 {
          top: -62px;
          right: 17%;
        }

        &--step2 {
          right: 32.5%;
          top: -55px;

          > i {
            right: -38%;
            top: 84px;
          }
        }

        &--step3 {
          right: 49.5%;
          top: -73px;

          > i {
            right: -24px;
            top: 109px;
          }
        }

        &--step4 {
          right: 67.9%;
          top: -56px;

          > i {
            right: -27px;
            top: 76px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .store {
    transform: scale(.8);
    left: 35%;
  }
  .seller {
    &__animation {
      left: -60px;
    }

    &__item {
      &--info {
        top: initial;
        left: -30px;
      }

      &--arrow {
        display: none;
      }
    }

  }

  .buyer {
    &__animation {
      right: -80px;
    }

    &__item {
      &--info {
        top: initial;
        right: -10px;
      }

      &--arrow {
        display: none;
      }
    }
  }
  .product {
    $self: &;

    &__header {
      margin-bottom: 30px;

      #{$self}__header-cat {
        #{$self}__header-cat-item {
          height: 25px;
          font-size: 13px;
          padding: 0 7px 0 7px;
        }
      }

      #{$self}__header-more {
        font-size: 13px;

        i {
          font-size: 2px;
        }
      }

      h2 {
        font-size: 15px;
      }
    }

    &__content {
      .swiper-slide {
        transition: .1s;
        opacity: 0;

        &.swiper-slide-active {
          opacity: 1;

          + .swiper-slide {
            opacity: 1;

            + .swiper-slide {
              opacity: 0;

              + .swiper-slide {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
  .top-cat {
    padding-bottom: 5px;

    &__item {
      margin-bottom: 30px;
    }
  }
  .top-sellers {
    $self: &;

    &__content {
      #{$self}__content-swiper {
        padding-bottom: 30px;
        margin-bottom: -30px;
        padding-right: 30px;
        margin-right: -30px;
        padding-top: 30px;
        margin-top: -30px;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
  .roadmap {
    $self: &;

    &__road {
      #{$self}__road-item {
        #{$self}__road-item-step {
          font-size: 50px;
        }

        &--step1 {
          top: -62px;
          right: 16%;
        }

        &--step2 {
          right: 31.5%;
          top: -55px;

          > i {
            right: -25%;
            top: 77px;
          }
        }

        &--step3 {
          right: 48.5%;
          top: -73px;

          > i {
            right: -13px;
            top: 99px;
          }
        }

        &--step4 {
          right: 66.9%;
          top: -56px;

          > i {
            right: -27px;
            top: 76px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .site-header{
    &__navigation {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li{
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .seller {
    display: none;

  }
  .buyer {
    display: none;
  }
  .store {
    transform: scale(1) translate(-50%, 0);
    top: 20px;
    left: 50%;
  }
  .main-info {
    height: 210px;
    background-position: center 0;
    background-size: 74%;
    position: relative;
    margin-top: -50px;
  }
  .product {
    $self: &;

    &__header {
      flex-wrap: wrap;

      #{$self}__header-cat {
        margin-top: 15px;
        width: 100%;
        order: 1;
      }

      #{$self}__header-more {
        order: 0;
      }
    }
  }
  .amazing-offers {
    $self: &;

    &__header {
      margin-bottom: 15px;

      #{$self}__header-title {
        i {
          font-size: 25px;
        }
      }

      #{$self}__header-range {
        position: relative;
        top: 0;
      }
    }
  }
  .banners {
    &__banner {
      margin-bottom: 20px;
    }
  }
  .roadmap {
    $self: &;

    &__road {
      #{$self}__road-item {
        > i {
          font-size: 8px;
        }

        #{$self}__road-item-desc {
          display: none;
        }

        &--step1 {
          top: -34px;
          right: 16.3%;
        }

        &--step2 {
          right: 31.5%;
          top: -45px;

          > i {
            right: -26%;
            top: 63px;
          }
        }

        &--step3 {
          right: 48.5%;
          top: -46px;

          > i {
            right: -9px;
            top: 69px;
          }
        }

        &--step4 {
          right: 66.9%;
          top: -42px;

          > i {
            right: -11px;
            top: 55px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .store {
    top: 20px;

    &__create {
      margin-bottom: 15px;
    }
  }
  .product {
    $self: &;

    &__header {
      position: relative;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 80px;
        height: 42px;
        background-image: linear-gradient(to left, transparent, #fff);
        pointer-events: none;
      }

      #{$self}__header-cat {
        white-space: nowrap;
        padding-bottom: 10px;
        overflow: scroll;
      }
    }
  }
  .popular-cat {
    $self: &;
    margin-bottom: 1px;

    &__header {
      margin-bottom: 15px;

      #{$self}__header-title {
        font-size: 15px;
        padding-left: 8px;
      }

      #{$self}__header-link {
        padding-right: 8px;
      }
    }

    &__content {
      #{$self}__item {
        height: 155px;
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  .top-sellers {
    $self: &;
    margin-top: 25px;
    margin-bottom: 25px;
    &__header {
      margin-bottom: 20px;

      h2 {
        font-size: 15px;
      }

      #{$self}__header-link {
        font-size: 13px;

        i {
          font-size: 3px;
        }
      }
    }
  }
  .amazing-offers {
    $self: &;
    padding-bottom: 29px;
    margin-bottom: 0;

    &__header {
      flex-wrap: wrap;

      #{$self}__header-range {
        order: 1;
      }
    }
  }
  .roadmap {
    $self: &;
    margin-bottom: 50px;
    &__header{
      margin-bottom: 60px;
    }
    &__road {
      #{$self}__road-item {
        #{$self}__road-item-icon {
          flex-direction: column;
          margin-bottom: 0;
          span {
            font-size: 14px;
          }

          i {
            &.icon-user-plus {
              font-size: 17px;
            }
            &.icon-cart{
              font-size: 18px;
              margin-left: 0;
            }
            &.icon-transportation{
              font-size: 17px;
              margin-left: 0;
            }
            &.icon-card{
              font-size: 15px;
              margin-left: 0;
            }
          }
        }

        #{$self}__road-item-step {
          font-size: 35px;
        }

        &--step1 {
          top: -34px;
          right: 18.1%;
        }

        &--step2 {
          right: 33.5%;
          top: -30px;

          > i {
            right: -64%;
            top: 43px;
          }
          #{$self}__road-item-step {
            margin-bottom: 0;
          }
        }

        &--step3 {
          right: 50%;
          top: -39px;

          > i {
            right: -18px;
            top: 55px;
          }
        }

        &--step4 {
          right: 68.5%;
          top: -31px;

          > i {
            right: -20px;
            top: 39px;
          }
          #{$self}__road-item-step {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .site-header{
    $self:&;
    &__momentary-products{
      #{$self}__momentary-products-title{
        justify-content: center;
      }
    }
  }
}

@media (max-width: 400px) {
  .amazing-offers {
    $self: &;

    &__header {
      #{$self}__header-title {
        i {
          font-size: 18px;
        }
      }

      #{$self}__header-link {
        font-size: 13px;

        i {
          font-size: 3px;
        }
      }
    }
  }
  .roadmap {
    $self: &;

    &__road {
      #{$self}__road-item {

        &--step1 {
          top: -41px;
          right: 16.1%;
        }

        &--step2 {
          right: 31.5%;
          top: -30px;

          > i {
            right: -34%;
            top: 41px;
          }
          #{$self}__road-item-step {
            margin-bottom: 0;
          }
        }

        &--step3 {
          right: 48%;
          top: -46px;

          > i {
            right: -8px;
            top: 58px;
          }
        }

        &--step4 {
          right: 66.5%;
          top: -31px;

          > i {
            right: -10px;
            top: 39px;
          }
          #{$self}__road-item-step {
            margin-bottom: 0;
            font-size: 30px;
          }
        }
      }
    }
  }
}
