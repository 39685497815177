.comments{
  $self:&;
  margin-bottom: 53px;
  &__list{
    margin-bottom: 47px;
   #{$self}__list-item{
      border-bottom: 1px solid #e1e5ea;
      padding-bottom: 32px;
      margin-bottom: 31px;
      #{$self}__list-header{
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        #{$self}__list-avatar{
          border-radius: 60px;
          margin-left: 16px;
        }
        #{$self}__list-name{
          color: #383c48;
          font-size: 17px;
          font-weight: 700;
          margin-left: 18px;
        }
        #{$self}__list-rule{
          height: 22px;
          display: flex;
          align-items: center;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          border-radius: 8px 0 8px 0;
          padding: 0 10px 0 8px;
          &--buying{
            background-color: #12b540;
          }
          &--seller{
            background-color: #3a67dc;
          }
        }
      }
      #{$self}__list-text{
        color: #383c48;
        font-size: 13px;
        font-weight: 500;
        line-height: 35px;
        margin-bottom: 18px;
        text-align: justify;
      }
      #{$self}__list-footer{
        display: flex;
        align-items: center;
        #{$self}__list-like{
          display: flex;
          align-items: center;
          margin-left: 47px;
          .like{
            width: 42px;
            height: 41px;
            box-shadow: 0 1px 1px rgba(50, 59, 82, 0.37);
            background-color: #f5f7f9;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 24px;
            transition: .3s;
            i{
              font-size: 19px;
              color: #383c48;
            }
            &:hover{
              box-shadow: 0 1px 8px rgba(50, 59, 82, 0.37);
            }
          }
          .count{
            color: #383c48;
            font-size: 19px;
            font-weight: 700;
            margin-left: 7px;
          }
          .title{
            color: #383c48;
            font-size: 13px;
            font-weight: 700;
          }
        }
        #{$self}__list-reply{
          display: flex;
          align-items: center;
          .reply{
            width: 42px;
            height: 41px;
            box-shadow: 0 1px 1px rgba(50, 59, 82, 0.37);
            background-color: #f5f7f9;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 24px;
            transition: .3s;
            i{
              font-size: 11px;
              color: #383c48;
            }
            &:hover{
              box-shadow: 0 1px 8px rgba(50, 59, 82, 0.37);
            }
          }
          .title{
            color: #383c48;
            font-size: 14px;
            font-weight: 700;
          }
        }
        #{$self}__list-date{
          color: #777c89;
          font-size: 12px;
          font-weight: 400;
          margin-right: auto;
        }
      }
      &--reply{
        background-color: #f5f7f9;
        border-radius: 0 0 0 20px;
        padding: 32px 40px;
        padding-bottom: 32px !important;
        margin-top: 33px;
        margin-bottom: 0;
        position: relative;
        border-bottom: none;
        &:before{
          content: '\e914';
          font-family: 'icomoon';
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 13px;
          color: #b4bacc;
        }
        #{$self}__list-footer{
          #{$self}__list-like{
            .like{
              background-color: #ffffff;
            }
          }
          #{$self}__list-reply{
            .reply{
              background-color: #ffffff;
            }
          }
        }
      }
      &.has-childe{
        border-bottom: none;
        padding-bottom: 0;
      }
     &:nth-last-of-type(1){
       border-bottom: none;
       padding-bottom: 0;
     }
    }
  }
  &__form{
    background-color: #f5f7f9;
    border-radius: 14px;
    overflow: hidden;
    textarea{
      background-color: #f5f7f9;
      width: 100%;
      resize: none;
      height: 181px;
      padding-top: 33px;
      padding-right: 33px;
      padding-left: 30px;
      &::placeholder{
        color: #787e90;
        font-size: 17px;
        font-weight: 400;
        opacity: 1;
      }
    }
    #{$self}__form-btn{
      display: flex;
      align-items: center;
      padding: 0 23px 25px 23px;
      .item{
        display: flex;
        align-items: center;
        width: 281px;
        background-color: #fff;
        height: 49px;
        box-shadow: 0 2px 2px rgba(102, 119, 136, 0.13);
        padding: 0 18px;
        border-radius: 5px;
        margin-left: 9px;
        i{
          font-size: 17px;
          color: #777c8c;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #eef1f4;
          padding-left: 17px;
          margin-left: 19px;
        }
        input{
          color: #828796;
          font-size: 15px;
          font-weight: 400;
          &::placeholder{
            color: #828796;
            font-size: 15px;
            font-weight: 400;
            opacity: 1;
          }
        }
      }
      button{
        height: 49px;
        box-shadow: 0 2px 2px rgba(102, 119, 136, 0.13);
        background-color: #12b540;
        width: 141px;
        color: #ffffff;
        font-size: 17px;
        font-weight: 700;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 20px;
        i{
          font-size: 13px;
          color: #ffffff;
        }
      }
    }
  }
}