.cart-method{
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding-top: 33px;
  padding-bottom: 51px;
  margin-bottom: 31px;
  $self:&;
  &__nav{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    width: 40px;
    height: 40px;
    border-radius: 60px;
    display: none;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 6px 15px -4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 20;
    cursor: pointer;
    i{
      font-size: 13px;
    }
    &--next{
      right: 0;
    }
    &--prev{
      left: 0;
    }
  }
  &__title{
    color: #4d5367;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 66px;
  }
  &__items{
    margin-bottom: 47px;
    padding-bottom: 30px;
    margin-top: -30px;
    #{$self}__items-item{
      height: 278px;
      input{
        display: none;
        &:checked{
          + #{$self}__items-label{
            background-color: #f7f8fb;
            #{$self}__items-discount{
              &:after{
                background-color: #f7f8fb;
              }
            }
          }
          ~ #{$self}__items-discount{
            &:after{
              background-color: #f7f8fb;
            }
          }
        }
      }
      #{$self}__items-label{
        position: relative;
        border: 1px solid #d4d7e2;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 50px;
        padding-bottom: 41px;
        cursor: pointer;
        background-color: #fff;
        transition: .3s;
        border-radius: 8px;
        #{$self}__items-discount{
          display: flex;
          align-items: center;
          position: absolute;
          top: 19px;
          right: -4px;
          background-color: #ef473a;
          height: 25px;
          border-radius: 60px 0 60px 60px;
          padding: 0 6px 0 8px;
          .numb{
            color: #ffffff;
            font-size: 18px;
            font-weight: 400;
            margin-left: 1px;
            letter-spacing: -0.94px;
          }
          i{
            font-size: 8px;
            color: #ffffff;
          }
          &:after{
            content: '';
            position: absolute;
            right: 0px;
            left: 0;
            top: -17px;
            height: 19px;
            border-radius: 60px;
            background-color: #fff;
            transition: .3s;
            z-index: 20;
          }
          &:before{
            content: '';
            right: 0;
            left: 0;
            height: 14px;
            background-color: #a22016;
            border-radius: 60px 60px 10px 60px;
            position: absolute;
            top: -13px;
            z-index: 10;
            transform: skew(15deg) rotateY(31deg);
            transform-origin: 100% 50%;
          }
        }
        > i{
          font-size: 18px;
          color: #454a5b;
          margin-bottom: 36px;
        }
        #{$self}__items-title{
          color: #4d5367;
          font-size: 27px;
          font-weight: 700;
          margin-bottom: 7px;
        }
        #{$self}__items-desc{
          color: #4d5367;
          font-size: 15px;
          font-weight: 700;
        }
        #{$self}__show-info{
          color: #4d5367;
          font-size: 15px;
          font-weight: 700;
          display: flex;
          align-items: center;
          margin-top: auto;
          i{
            font-size: 8px;
            color: #454a5b;
            margin-right: 17px;
          }
        }
        #{$self}__items-port{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;
          width: 100%;
          padding: 0 33px;
        }
      }
      #{$self}__items-discount-desc{
        color: #8a8c93;
        font-size: 13px;
        font-weight: 700;
        margin-top: 8px;
        display: flex;
        align-items: center;
        span{
          color: #ef473a;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.94px;
          display: flex;
          align-items: center;
          margin-left: 10px;
          i{
            font-size: 8px;
            color: #ef473a;
            margin-right: 1px;
          }
        }
      }
      &--online{
        #{$self}__items-label{
          padding-bottom: 35px;
        }
      }
      &--location{
        #{$self}__items-label{
          padding-top: 0;
          padding-bottom: 0;
          > i{
            margin-bottom: 46px;
          }
        }
      }
    }
  }
  &__coupon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    #{$self}__coupon-title{
      color: #4d5367;
      font-size: 22px;
      font-weight: 700;
    }
    #{$self}__coupon-input{
      display: flex;
      align-items: center;
      input{
        color: #979baa;
        font-size: 15px;
        font-weight: 700;
        width: 373px;
        height: 57px;
        border: 1px solid #d4d7e2;
        background-color: #ffffff;
        border-radius: 6px;
        padding-right: 23px;
        margin-left: 18px;
        &::placeholder{
          color: #979baa;
          font-size: 15px;
          font-weight: 700;
          opacity: 1;
        }
      }
      button{
        width: 140px;
        height: 57px;
        background-color: #a8abb5;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        border-radius: 6px;
      }
    }
  }
}