.seller-info{
  margin-bottom: 50px;
  margin-top: -660px;
  $self:&;
  &__title{
    h2{
      opacity: 0.8;
      color: #5f6578;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 19px;
    }
  }
  &__content{
    padding-bottom: 38px;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #383c48;
      opacity: 0.8;
      z-index: 20;
    }
    #{$self}__content-logo{
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 51px;
      margin-bottom: 23px;
      figure{
        position: relative;
        > i{
          font-size: 19px;
          color: #12b540;
          right: -4px;
          top: 43px;
          position: absolute;
          i{
            font-size: 7px;
            color: #ffffff;
            position: absolute;
            top: 50%;
            transform: translate(50%, -50%);
            right: 50%;
          }
        }
      }

    }
    #{$self}__content-info{
      position: relative;
      z-index: 20;
      margin-bottom: 28px;
      #{$self}__content-title{
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 11px;
      }
      #{$self}__content-url{
        opacity: 0.5;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.48px;
        display: block;
      }
    }
    #{$self}__content-features{
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 29px;
      .item{
        width: 36px;
        height: 36px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        cursor: pointer;
        position: relative;
        &:nth-last-of-type(1){
          margin-left: 0;
        }
        &__tooltip{
          position: absolute;
          top: -24px;
          transition: .3s;
          opacity: 0;
          visibility: hidden;
          transform: scale(.9) translate(-50%,0);
          width: 109px;
          height: 25px;
          background-color: #ffffff;
          color: #383c48;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 50%;
          border-radius: 60px;
          &:before{
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 2px;
            position: absolute;
            bottom: -3.5px;
            left: 50%;
            transform: translate(-50%, 0) rotate(45deg);
            background-color: #fff;
          }
        }
        &:hover{
          .item__tooltip{
            transform: scale(1) translate(-50%,0);
            opacity: 1;
            visibility: visible;
          }
        }
        i{
          font-size: 19px;
          color: #ffffff;
          &.icon-comment{
            font-size: 17px;
          }
          &.icon-heart{
            font-size: 18px;
          }
        }
        &--umbrella{
          background-color: #39a92a;
        }
        &--guard{
          background-color: #d48912;
        }
        &--comment{
          background-color: #3482da;
        }
        &--heart{
          background-color: #2ebac7;
        }
        &--gift{
          background-color: #fb4b63;
        }
        &--more{
          color: #ffffff;
          font-size: 22px;
          font-weight: 400;
          letter-spacing: -1.32px;
          background-color: #12b540;
        }
      }
    }
    #{$self}__content-desc{
      position: relative;
      z-index: 20;
      padding: 0 30px;
      margin-bottom: 27px;
      p{
        color: #ffffff;
        font-size: 13px;
        font-weight: 700;
        line-height: 27px;
        text-align: justify;
      }
    }
    #{$self}__content-statistics{
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 45px;
      .items{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 24px;
        .item{
          width: 77px;
          height: 77px;
          background: url("../img/bg-seller-info.png");
          clip-path: circle(40%);
          position: relative;
          margin-bottom: 6px;
          &__inside{
            background-color: #383c48;
            opacity: 0.6;
            position: absolute;
            top:10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            clip-path: circle(47%);
          }
          &__rate{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            color: #ffffff;
            font-size: 24px;
            font-weight: 700;
          }
        }
        &__title{
          color: #ffffff;
          font-size: 13px;
          font-weight: 700;
        }
        &:nth-last-of-type(1){
          margin-left: 0;
        }
      }

    }
    #{$self}__content-meta{
      position: relative;
      z-index: 20;
      padding: 0 28px;
      margin-bottom: 48px;
      .item{
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;
        &__title{
          opacity: 0.7;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          order: 0;
          margin-left: 15px;
        }
        &__value{
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          order: 2;
          margin-right: 13px;
          &--rate{
            display: flex;
            align-items: center;
            .count{
              opacity: 0.6;
              color: #ffffff;
              font-size: 14px;
              font-weight: 700;
              display: flex;
              align-items: center;
              margin-left: 11px;
              &:before{
                content: '[';
                margin-left: 3px;
              }
              &:after{
                content: ']';
                margin-right: 3px;
              }
            }
            .rate{
              color: #ffffff;
              font-size: 20px;
              font-weight: 700;
              margin-left: 3px;
            }
            i{
              font-size: 13px;
              color: #12b540;
            }
          }
        }
        &:before{
          content: '';
          border-bottom: 1px solid #ffffff;
          opacity: .3;
          order:1;
          flex-grow: 1;
          position: relative;
          top: 1px;
        }
        &--rate{
          margin-bottom: 15px;
        }
        &--membership{
          margin-bottom: 15px;
        }
      }
    }
    #{$self}__content-footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 20;
      padding: 0 28px;
      .item{
        display: flex;
        align-items: center;
        i{
          color: #ffffff;
          background-color: #12b540;
          &.icon-page-customer{
            font-size: 16px;
          }
          &.icon-pluse-border{
            font-size: 22px;
          }
        }
        span{
          color: #ffffff;
          font-weight: 700;
          font-size: 14px;
        }
        &--follow{
          i{
            border-radius: 60px;
            margin-right: 14px;
          }
        }
        &--page{
          i{
            padding: 3px;
            border-radius: 4px;
            margin-left: 12px;
          }
        }
      }
    }
  }
}

.progress-ring{
  &__circle{
    transform: rotate(-90deg);
    transform-origin: 50%;
  }
}