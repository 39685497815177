.popular-cat{

  $self:&;
  overflow: hidden;
  padding-bottom: 20px;
  padding-right: 15px;
  margin-bottom: 49px;
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 26px;
    &:after{
      content: '';
      position: absolute;
      top: 15px;
      right: 0;
      left: 0;
      border-bottom: 1px solid  #e3e6f1;
      z-index: 9;
    }
    #{$self}__header-title{
      color: #454a5b;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      z-index: 10;
      background-color: #fff;
      padding-left: 25px;
    }

    #{$self}__header-link{
      color: #14a13b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
      background-color: #fff;
      z-index: 10;
      padding-right: 52px;
      i{
        font-size: 4px;
        color: #14a13b;
        margin-right: 19px;
        margin-top: 3px;
      }
    }

  }
  &__content{
    .popular-cat-swiper{
      padding: 30px;
      margin: -30px;
    }
    #{$self}__item{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 223px;
      padding-bottom: 40px;
      padding-top: 48px;
      border-radius: 15px;
      box-shadow: 22px 15px 30px -20px rgba(0, 0, 0, 0.12);
      position: relative;
      transition: .3s;
      > a{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
      i{
        color: #14a13b;
        &.icon-computer{
          font-size: 49px;
        }
        &.icon-chief{
          font-size: 53px;
        }
        &.icon-accessories{
          font-size: 55px;
        }
        &.icon-photography{
          font-size: 45px;
        }
        &.icon-game{
          font-size: 45px;
        }
        &.icon-mouse{
          font-size: 55px;
        }
        &.icon-programming{
          font-size: 47px;
        }
      }
      #{$self}__item-title{
        margin-top: auto;
        color: #14a13b;
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 7px;
      }
      #{$self}__item-count{
        color: #6e7488;
        font-size: 15px;
        font-weight: 700;
      }
      &:hover{
        box-shadow: 10px 15px 30px -20px rgba(0, 0, 0, 0.12);
      }
    }
  }
}