.roadmap{
  $self:&;
  margin-bottom: 115px;
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 84px;
    i{
      font-size: 24px;
      color: #12b540;
      margin-left: 13px;
    }
    #{$self}__header-title{
      color: #4d5367;
      font-size: 16px;
      font-weight: 700;
    }
  }
  &__road{
    position: relative;
    #{$self}__road-item{
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      > i {
        font-size: 11px;
        color: #1049dc;
        position: absolute;
        transition: .3s;
      }
      #{$self}__road-item-icon{
        display: flex;
        align-items: center;
        i{
          color: #4d5367;
          transition: .3s;
          &.icon-user-plus{
            font-size: 21px;
            margin-left: 9px;
          }
          &.icon-cart{
            font-size: 23px;
            color: #4d5367;
            margin-left: 11px;
          }
          &.icon-transportation{
            font-size: 21px;
            margin-left: 9px;
          }
          &.icon-card{
            font-size: 19px;
            margin-left: 10px;
          }
        }
        span{
          color: #4d5367;
          font-size: 17px;
          font-weight: 700;
          transition: .3s;
        }
      }
      #{$self}__road-item-desc{
        max-width: 120px;
        color: #6e7487;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
      }
      #{$self}__road-item-step{
        color: #1049dc;
        font-size: 71px;
        font-weight: 700;
        transition: .3s;
      }
      &:hover{
        > i{
          color: #14a13b;
        }
        #{$self}__road-item-step{
          color: #14a13b;
        }
        #{$self}__road-item-icon{
          i,span{
            color: #14a13b;
          }
        }
      }
      &--step1{
        top: -51px;
        right: 18%;
        #{$self}__road-item-icon{
          margin-bottom: 16px;
        }
        #{$self}__road-item-desc{
          margin-bottom: 5px;
        }
      }
      &--step2{
        right: 33.88%;
        top: -55px;
        > i {
          right: -60%;
          top: 91px;
          transform: rotate(124deg);
        }
        #{$self}__road-item-step{
          margin-bottom: 9px;
        }
        #{$self}__road-item-icon{
          margin-bottom: 17px;
        }
      }
      &--step3{
        right: 50.8%;
        top: -51px;
        > i {
          right: -47px;
          top: 100px;
          transform: rotate(60deg);
        }
        #{$self}__road-item-icon{
          margin-bottom: 15px;
        }
        #{$self}__road-item-desc{
          margin-bottom: -4px;
        }
      }
      &--step4{
        right: 69.9%;
        top: -49px;
        > i {
          right: -60px;
          top: 76px;
          transform: rotate(115deg);
        }
        #{$self}__road-item-step{
          margin-bottom: 3px;
        }
        #{$self}__road-item-icon{
          margin-bottom: 15px;
        }
      }
    }
  }
}