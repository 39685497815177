.cat-filter-header{
  $self:&;
  border-bottom: 1px solid #eceff1;
  padding-bottom: 27px;
  &__wrapper{
    display: flex;
    align-items: center;
    #{$self}__breadcrumb{
      display: flex;
      align-items: center;
      a{
        color: #5c616d;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-left: 20px;
        &:after{
          content: '\e91a';
          font-family: 'icomoon';
          font-size: 9px;
          color: #a4abb3;
          margin-right: 20px;
        }
      }
      span{
        color: #959bad;
        font-size: 13px;
        font-weight: 500;
      }
    }
    #{$self}__amazing-offers{
      margin-right: auto;
      color: #383c48;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-content: center;
      i{
        font-size: 13px;
        color: #ffffff;
        width: 30px;
        height: 23px;
        background-color: #ef473a;
        border-radius: 60px 60px 60px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
      }
    }
  }

}