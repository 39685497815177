.product-info {
  margin-bottom: 24px;
  $self: &;

  &__image {
    border-radius: 10px;
    position: relative;
    margin-bottom: 27px;

    &:after {
      content: '';
      position: absolute;
      top: 80px;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 10px;
      box-shadow: 0 0 40px -15px rgba(0, 0, 0, 0.25);
      z-index: 10;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 10px;
      background-color: #fff;
      z-index: 20;
    }

    .swiper-container{
      z-index: 100;
    }
    figure {
      height: 378px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      position: relative;
      z-index: 30;
    }
  }

  &__gallery {
    position: relative;
    padding: 15px;
    margin: -15px;
    direction: ltr;
    #{$self}__gallery-item {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid #e1e4ed;
      margin-right: 19px;
      transition: .3s;
      cursor: pointer;

      figure {
        width: 100%;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        box-shadow: 0 3px 8px rgba(77, 83, 103, 0.15);
        border-color: transparent;
      }

      &:nth-of-type(1) {
        margin-right: 0;
      }
    }
    .swiper-slide{
      &.swiper-slide-thumb-active{
        #{$self}__gallery-item{
          box-shadow: 0 3px 8px rgba(77, 83, 103, 0.15);
          border-color: transparent;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      right: -40px;
      width: 142px;
      top: 0;
      bottom: 0;
      pointer-events: none;
      background-image: linear-gradient(to left, #fff 18%, transparent);
      z-index: 20;
    }
  }

  &__information {
    margin-bottom: 20px;

    #{$self}__information-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 13px;
      margin-bottom: 43px;

      #{$self}__information-name {
        a {
          color: #8c91a4;
          font-size: 14px;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 7px;
        }

        h2 {
          color: #383c48;
          font-size: 26px;
          font-weight: 700;
        }
      }

      #{$self}__information-share {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .share{
          cursor: pointer;
          position: relative;
          &__social{
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 13px 0 23px -13px rgba(0, 0, 0, 0.5);
            padding: 12px;
            position: absolute;
            left: 42px;
            top: 50%;
            transform: translate(0,-50%);
            opacity: 0;
            visibility: hidden;
            transition: .3s;
            a{
              margin-left: 15px;
              display: flex;
              transition: .3s;
              transform: scale(0);
              &:nth-of-type(1){
                transition-delay: .075s;
              }
              &:nth-of-type(2){
                transition-delay: .15s;
              }
              &:nth-of-type(3){
                transition-delay: .225s;
              }
              &:nth-of-type(4){
                transition-delay: .3s;
              }
              &:nth-last-of-type(1){
                margin-left: 0;
              }
              i{
                color: #383c48;
                font-size: 18px;
                margin-left: 0;
                transition: .3s;

              }
            }
          }
          &.active{
            .share__social{
              opacity: 1;
              visibility: visible;
              a{
                transform: scale(1);
              }
            }
          }
        }
        .heart{
          cursor: pointer;
        }
        i {
          font-size: 17px;
          color: #727888;

          &:nth-of-type(1) {
            margin-left: 23px;
          }
        }
      }
    }

    #{$self}__information-perform {
      margin-bottom: 46px;

      #{$self}__information-perform-title {
        position: relative;
        margin-bottom: 30px;

        .title {
          color: #828797;
          font-size: 16px;
          font-weight: 400;
          display: inline-block;
          background-color: #fff;
          padding-left: 30px;
          position: relative;
          z-index: 20;
        }

        &:before {
          content: '';
          right: 0;
          left: 0;
          top: 14px;
          position: absolute;
          border-bottom: 1px solid #eeeff3;
          z-index: 10;
        }
      }

      #{$self}__information-perform-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          display: flex;
          align-items: center;

          > i {
            font-size: 8px;
            color: #ffffff;
            width: 20px;
            height: 20px;
            background-color: #12b540;
            border-radius: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 19px;
          }

          &__rate {
            display: flex;
            align-items: center;
            margin-left: 17px;

            i {
              font-size: 13px;
              color: #12b540;
            }
          }

          &__rate-avg {
            color: #4d5367;
            font-size: 20px;
            font-weight: 700;
            margin-left: 3px;
          }

          &__title {
            color: #4d505b;
            font-size: 14px;
            font-weight: 700;
          }

          &__count {
            color: #c2c6d2;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;

            &:before {
              content: '[';
              margin-left: 3px;
            }

            &:after {
              content: ']';
              margin-right: 3px;
            }
          }

          &--rate {
            .item__title {
              margin-left: 15px;
            }
          }
        }
      }
    }

    #{$self}__information-properties {
      border-top: 1px solid #eeeff3;
      padding-top: 46px;

      .properties {
        position: relative;

        &__title {
          position: absolute;
          color: #828797;
          font-size: 16px;
          font-weight: 400;
          right: 0;
          top: -61px;
          background-color: #fff;
          padding-left: 20px;
        }

        &__items {
          display: flex;
          flex-wrap: wrap;
        }

        &__items-item {
          flex: 1 0 50%;
          display: flex;
          flex-direction: column;
          margin-bottom: 26px;

          .title {
            color: #5e6370;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 6px;
          }

          .value {
            color: #383c48;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .description {
        &__title {
          position: absolute;
          color: #828797;
          font-size: 16px;
          font-weight: 400;
          right: 15px;
          top: -61px;
          background-color: #fff;
          padding-left: 15px;
          padding-right: 15px;
        }

        p {
          color: #4d505b;
          font-size: 13px;
          font-weight: 500;
          line-height: 41px;
          text-align: justify;
          margin-top: -13px;
        }
      }
    }
  }

  &__add-cart {
    height: 62px;
    box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
    padding: 0 30px 0 21px;
    background-color: #12b540;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 19px;
    transition: .3s;

    i {
      font-size: 22px;
      color: #ffffff;
      margin-left: 19px;
    }

    .title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }

    .title-responsive {
      display: none;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }

    .price {
      display: flex;
      align-items: center;
      margin-right: auto;

      ins {
        text-decoration: none;
        color: #ffffff;
        font-size: 23px;
        font-weight: 700;
        margin-left: 6px;
      }

      span {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &:hover {
      box-shadow: 0 1px 8px rgba(101, 106, 120, 0.35);
    }
  }

  &__conversation {
    height: 62px;
    box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
    background-color: #f9fafb;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 30px 0 27px;
    transition: .3s;

    i {
      font-size: 20px;
      color: #747b8f;
      margin-left: 18px;
    }

    .title {
      color: #656b7a;
      font-size: 15px;
      font-weight: 700;
    }

    .status {
      margin-right: auto;
      color: #a5abbe;
      font-size: 15px;
      font-weight: 700;
    }

    &:hover {
      box-shadow: 0 1px 8px rgba(101, 106, 120, 0.35);
    }
  }

  &__meta {
    border-top: 1px solid #eceef3;
    border-bottom: 1px solid #eceef3;
    padding-bottom: 28px;
    padding-top: 32px;
    margin-top: 14px;
    margin-bottom: 26px;

    #{$self}__meta-color {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;

      .title {
        position: absolute;
        color: #828797;
        font-size: 18px;
        font-weight: 400;
        background-color: #fff;
        padding-left: 20px;
        top: -48px;
        right: 0;
      }

      .item {
        height: 41px;
        margin-bottom: 8px;
        box-shadow: 0 1px 4px rgba(101, 106, 120, 0.4);
        padding: 0 16px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 21px;
        transition: .3s;
        display: none;

        &:nth-last-of-type(2) {
          margin-left: 18px;
        }

        &:nth-last-of-type(1) {
          margin-left: 0;
        }

        &:nth-of-type(1) {
          display: flex;
        }

        &:nth-of-type(2) {
          display: flex;
        }

        &:nth-of-type(3) {
          display: flex;
        }

        &:nth-of-type(4) {
          display: flex;
        }

        i {
          font-size: 9px;
          color: #ffffff;
          opacity: 0.4;
          transition: .3s;
        }

        &__name {
          color: #ffffff;
          font-size: 0;
          font-weight: 700;
          margin-bottom: 4px;
          opacity: 0;
          transition: .3s;
        }

        &.active {
          padding: 0 20px;

          i {
            font-size: 12px;
            opacity: 1;
            margin-left: 20px;
          }

          .item__name {
            font-size: 17px;
            opacity: 1;
          }
        }

        &--more {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 43px;
          height: 41px;
          border: 1px solid #dfe3e7;
          border-radius: 4px;
          box-shadow: none;

          i {
            font-size: 19px;
            color: #383c48;
            opacity: 1;
          }
        }

        &:hover {
          box-shadow: 0 1px 10px rgba(101, 106, 120, 0.4);
        }
      }
    }

    #{$self}__meta-item {
      position: relative;

      .title {
        position: absolute;
        background-color: #fff;
        color: #828797;
        font-size: 18px;
        font-weight: 400;
        padding-left: 35px;
        padding-right: 15px;
        top: -48px;
        right: -15px;
      }

      .selectize-control {
        .selectize-input {
          box-shadow: none;
          border-radius: 5px;
          padding: 8px 15px;
          height: 41px;
          padding-top: 9px;

          &:after {
            content: '\e90e';
            margin-top: 0;
            font-family: 'icomoon';
            font-size: 9px;
            color: #383c48;
            width: initial;
            height: initial;
            border-style: none;
            border-width: 0;
            border-color: transparent;
            transform: translate(0, -50%) rotateX(0);
            transition: .3s;
          }

          .item {
            color: #383c48;
            font-size: 14px;
            font-weight: 700;
          }

          &.dropdown-active {
            &:after {
              transform: translate(0, -50%) rotateX(180deg);
            }
          }
        }

        .selectize-dropdown {
          .option {
            cursor: pointer;
          }
        }
      }

      &--size {
        margin-bottom: 80px;
        position: relative;

        #{$self}__meta-entry {
          height: 62px;
          box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
          padding: 0 30px 0 21px;
          background-color: #12b540;
          border-radius: 4px;
          width: 100%;
          display: none;
          margin-bottom: 19px;
          transition: .3s;
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
        }

        .close {
          display: none;
        }

        &:before {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: -48px;
          border-bottom: 1px solid #eceef3;
        }

        .title {
          position: absolute;
          background-color: #fff;
          color: #828797;
          font-size: 18px;
          font-weight: 400;
          padding-left: 35px;
          padding-right: 15px;
          bottom: -11px;
          top: initial;
          right: -15px;
        }
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eceef3;
    padding-bottom: 38px;
    flex-wrap: wrap;

    #{$self}__price-features {
      #{$self}__price-features-items {
        display: flex;
        align-items: center;

        .item {
          display: flex;
          align-items: center;
          margin-left: 40px;

          i {
            color: #888d9f;
            margin-left: 15px;

            &.icon-transportation {
              font-size: 18px;
            }

            &.icon-card {
              font-size: 16px;
            }

            &.icon-cube {
              font-size: 21px;
            }

            &.icon-comment {
              font-size: 18px;
            }
          }

          &__title {
            color: #686e81;
            font-size: 14px;
            font-weight: 700;
          }

          &:nth-last-of-type(1) {
            margin-left: 0;
          }
        }
      }
    }

    #{$self}__price-items {
      margin-right: auto;

      .price {
        display: flex;
        align-items: baseline;
        margin-bottom: 2px;

        ins {
          color: #383c48;
          font-size: 30px;
          font-weight: 700;
          text-decoration: none;
          margin-left: 6px;
        }

        &__unit {
          color: #383c48;
          font-size: 14px;
          font-weight: 700;
          position: relative;
          top: -5px;
        }
      }

      .discount {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &__percent {
          display: flex;
          align-items: center;
          height: 30px;
          background-color: #ef473a;
          border-radius: 0 60px 60px 60px;
          padding: 0 14px 0 12px;
          color: #ffffff;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: -0.94px;
          position: relative;

          i {
            font-size: 11px;
            color: #ffffff;
            margin-right: 1px;
          }

          &:after {
            content: '';
            position: absolute;
            top: -15px;
            height: 15px;
            right: 20px;
            left: 0;
            border-radius: 60px 60px 60px 0;
            background-color: #ef473a;
            z-index: 10;
          }

          &:before {
            content: '';
            position: absolute;
            top: -16px;
            height: 16px;
            right: 19px;
            left: 0;
            border-radius: 0 60px 60px 60px;
            background-color: #fff;
            z-index: 20;
          }
        }

        &__price {
          color: #9094a1;
          font-size: 17px;
          font-weight: 400;
          margin-left: 14px;
        }
      }
    }
  }

}

.price-pieces {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 45px;
  margin-bottom: 15px;
  //border-bottom: 1px solid #eceef3;
  position: relative;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
  height: 104px;
  $self: &;
  &:before{
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #eceef3;
    bottom: 13px;
  }
  .title{
    bottom: 3px !important;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    &:nth-last-of-type(1){
      margin-left: 0;
    }
    #{$self}__item-piece {
      color: #4d505b;
      font-size: 14px;
      font-weight: 500;
    }

    #{$self}__item-price {
      color: #383c48;
      font-size: 17px;
      font-weight: 700;
      display: flex;
      align-items: center;

      ins {
        text-decoration: none;
        margin-left: 5px;
      }

      .unit {
        font-size: 13px;
      }
    }

    &.active {
      #{$self}__item-piece {
        color: #12b540;
      }

      #{$self}__item-price {
        color: #12c841;
      }
    }

  }
}

.variations {
  $self: &;

  &__tab {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    #{$self}__tab-size {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      height: 41px;
      border: 1px solid #dfe3e7;
      border-radius: 4px;
      box-shadow: none;
      font-size: 15px;
      font-weight: 600;
      color: #383c48;
      margin-left: 10px;
      cursor: pointer;
      transition: .3s;

      &:nth-last-of-type(1) {
        margin-left: 0;
      }

      &:hover {
        color: #dfe3e7;
        background-color: #383c48;
        border-color: transparent;
      }

      &.active {
        color: #dfe3e7;
        background-color: #383c48;
        border-color: transparent;
      }
    }
  }

  &__tabc {
    #{$self}__tabc-item {
      #{$self}__tabc-color {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eceef3;

        .color {
          height: 41px;
          width: 41px;
          border-radius: 4px;
          box-shadow: 0 1px 4px rgba(101, 106, 120, 0.4);
        }

        .price {
          color: #383c48;
          font-weight: 700;
          display: flex;
          align-items: center;

          &__numb {
            font-size: 20px;
            text-decoration: none;
            margin-left: 5px;
          }

          &__unit {
            font-size: 14px;
          }
        }

        .number {
          display: flex;
          align-items: center;

          input {
            width: 60px;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            height: 25px;
            text-align: center;
            color: #818181;
          }

          &__item {
            cursor: pointer;
            height: 41px;
            width: 41px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 22px;
              color: #39a92a;
            }
          }
        }
      }
    }
  }
}

.variations-count {
  $self: &;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #eceef3;
  margin-bottom: 25px;
  flex-wrap: wrap;

  &__pieces {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    #{$self}__pieces-price {
      display: flex;
      align-items: center;
      margin-left: 25px;

      .price {
        text-decoration: none;
        color: #4d505b;
        font-size: 16px;
        font-weight: 500;
        margin-left: 5px;
      }

      .unit {
        color: #4d505b;
        font-size: 13px;
        font-weight: 500;
      }
    }

    #{$self}__pieces-count {
      display: flex;
      align-items: center;
      color: #4d505b;
      font-size: 14px;
      font-weight: 500;

      .count {
        margin-left: 5px;
        font-size: 16px;
      }
    }
  }

  &__shipping {
    color: #4d505b;
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 15px;

    #{$self}__shipping-price {
      text-decoration: none;
    }

    #{$self}__shipping-unit {
      font-size: 14px;
    }
  }

  &__total {
    color: #4d505b;
    font-size: 16px;
    font-weight: 700;

    #{$self}__total-price {
      color: #4d505b;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }

    #{$self}__total-unit {
      color: #4d505b;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}