@media (max-width: 1200px) {
  .product-info{
    $self:&;
    &__gallery{
      #{$self}__gallery-items{
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: initial;
        overflow: scroll;
        padding-bottom: 20px;
      }
    }
    &__add-cart{
      padding: 0 14px 0 14px;
      margin-top: -76px;
      i{
        font-size: 20px;
        margin-left: 11px;
      }
      .title{
        font-size: 15px;
      }
      .price {
        ins{
          font-size: 18px;
          margin-left: 6px;
        }
        span{
          font-size: 13px;
        }
      }
    }
    &__price{
      justify-content: center;
      #{$self}__price-items{
        margin-right: 0;
      }
      #{$self}__price-features{
        #{$self}__price-features-items{
          margin-bottom: 15px;
        }
      }
    }
  }
  .single-content{
    .pl39{
      padding-left: 10px;
    }
  }
  .product-content{
    $self:&;
    &__nav{
      #{$self}__nav-item{
        margin-left: 50px;
      }
    }
  }
  .seller-info{
    $self:&;
    margin-top: -834px;
    &__content{
      background-size: 120%;
      #{$self}__content-footer{
        padding: 0 10px;
      }
    }
  }
  .comments{
    $self:&;
    &__form{
      #{$self}__form-btn{
        flex-wrap: wrap;
        .item{
          width: 100%;
          margin-bottom: 10px;
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .breadcrumb{
    $self:&;
    &__content {
      #{$self}__home{
        min-width: 154px;
        i{
          margin-right: 10px;
        }
      }
      #{$self}__items {
        flex-wrap: wrap;
        #{$self}__items-item{
          font-size: 12px;
          &:after{
            font-size: 3px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .seller-head{
    $self:&;
    &__info{
      margin-left: 0;
      #{$self}__info-item{
        margin-left: 18px;
      }
    }
    &__logo{
      width: 220px;
    }
  }
  .product-info{
    $self:&;
    &__price{
      flex-wrap: wrap;
      justify-content: center;
      #{$self}__price-features{
        position: relative;
        overflow: hidden;
        &:before{
          content: '';
          left: 0;
          width: 60px;
          background-image: linear-gradient(to right , #fff , transparent);
          position: absolute;
          top: 0;
          bottom: 0;
          pointer-events: none;
        }
        #{$self}__price-features-items{
          flex-wrap: nowrap;
          white-space: nowrap;
          padding-bottom: 15px;
          overflow: scroll;
        }
      }
      #{$self}__price-items{
        margin-right: 0;
      }
    }
    &__meta{
      #{$self}__meta-color{
        margin-bottom: 31px;
        .item{
          margin-bottom: 8px;
        }
      }
      #{$self}__meta-item{
        border-top: 1px solid #eceef3;
        padding-top: 20px;
        .title{
          top: -18px;
        }
        &--size{
          .title{
            top: initial;
          }
        }
      }
    }
    &__gallery{
      overflow: scroll;
      padding: 20px;
      margin: -20px;
    }
    &__information {
      #{$self}__information-perform {
        #{$self}__information-perform-title{
          margin-bottom: 15px;
        }
        #{$self}__information-perform-content{
          .item{
            margin-bottom: 15px;
            &:nth-last-of-type(1){
              margin-bottom: 0;
            }
          }
        }
      }
      #{$self}__information-properties{
        padding-top: 27px;
        .pr30{
          padding-right: 0;
        }
        .description{
          border-top: 1px solid #eeeff3;
          padding-top: 30px;
          &__title{
            top: -14px;
            right: 0;
            padding-right: 0;
          }
        }
        .properties{
          &__title{
            top: -42px;
          }
        }
      }
    }
    &__add-cart{
      margin-top: -360px;
    }
  }
  .single-content{
    .pl39{
      padding-left: 39px;
    }
  }
  .seller-info{
    $self:&;
    margin-top: -1270px;
    &__content{
      background-size: 150%;
      #{$self}__content-footer{
        padding: 0 8px;
      }
    }
  }
  .product-content{
    &__nav{
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: scroll;
      justify-content: flex-start;
      padding: 0 15px;
    }
  }
  .comments{
    $self:&;
    &__list{
      #{$self}__list-item {
        #{$self}__list-footer{
          flex-wrap: wrap;
          #{$self}__list-date{
            margin-top: 15px;
          }
        }
        &--reply{
          #{$self}__list-footer{
            #{$self}__list-date{
              margin-top: 0;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 768px) {
  .breadcrumb{
    margin-bottom: 15px;
    $self:&;
    &__content {
      position: relative;
      flex-wrap: wrap;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        height: 30px;
        left: 0;
        width: 80px;
        background-image: linear-gradient(to right ,#fff,transparent);
        z-index: 20;
        pointer-events: none;
      }
      #{$self}__items {
        overflow: scroll;
        padding-bottom: 13px;
        width: 100%;
        white-space: nowrap;
        flex-wrap: nowrap;
        margin-bottom: 11px;
      }
    }
  }
  .seller-head{
    padding-right: 30px;
    &__logo{
      display: none;
    }
    &__info{
      margin-right: 0;
    }
    &__name{
      margin-left: 22px;
    }
  }
  .product-info{
    $self:&;
    &__add-cart{
      margin-top: 0;
    }
    &__conversation{
      margin-bottom: 15px;
    }
    &__price{
      #{$self}__price-features{
        #{$self}__price-features-items{
          margin-bottom: 10px;
        }
      }
    }
  }
  .single-content{
    .pl39{
      padding-left: 0;
    }
  }
  .seller-info{
    margin-top: 0;
    $self:&;
    &__content{
      #{$self}__content-footer{
        padding: 0 25px;
      }
    }
  }

  .comments{
    $self:&;
    &__list{
      #{$self}__list-item {
        #{$self}__list-footer{
          flex-wrap: wrap;
          #{$self}__list-date{
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .other-products{
    $self:&;
    margin-bottom: 23px;
    &__header{
      padding-right: 15px;
      padding-top: 20px;
      margin-bottom: 20px;
      #{$self}__header-info{
        #{$self}__header-logo{
          margin-left: 8px;
        }
      }
    }
    &--other-sellers{
      #{$self}__header{
        margin-bottom: 20px;
        padding-top: 20px;
        > h2{
          font-size: 14px;
        }
        #{$self}__header-link{
          font-size: 12px;
          i{
            margin-right: 8px;
          }
        }
      }
    }
  }
  .seller-head{
    $self:&;
    height: initial;
    /*position: fixed;
    bottom: 0;
    right: 0;
    left: 0;*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 300;
    margin-bottom: 0;
    border-radius: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    flex-direction: column;
    overflow: visible;
    &__info{
      #{$self}__info-item{
        i{
          &.icon-transportation{

          }
        }
      }
    }
    &__logo {
      display: flex;
      position: absolute;
      background-size: contain;
      width: initial;
      height: initial;
      /* transform: rotate(180deg); */
      left: -30px;
      top: -39px;
      background-image: none;
      padding: 5px;
      border-radius: 60px;
      background-color: #fff;
      overflow: visible;
    }
    &__name{
      margin-left: 0;
      margin-bottom: 15px;
      #{$self}__name-title{
        text-align: center;
      }
    }
    &__link{
      left: 15px;
      display: none;
    }
    &:before{
      border-radius: 10px;
    }
  }
  .product-content{
    $self:&;
    margin-bottom: 10px;
    .features {
      margin-bottom: 10px;
      table {
        td{
          padding: 10px 0;
        }
      }
      &__key{
        border-left: 15px solid #fff;
      }
    }
    &__nav{
      height: 50px;
      margin-bottom: 20px;
    }
    &__contents{
      #{$self}__contents-content{
        p,h3{
          margin-bottom: 13px;
        }
      }
    }
  }
  .comments{
    $self:&;
    margin-bottom: 20px;
    &__list {
      #{$self}__list-item{
        padding-bottom: 15px;
        margin-bottom: 15px;
        #{$self}__list-footer{
          flex-wrap: wrap;
          justify-content: center;
          #{$self}__list-date{
            margin-right: 0;
            margin-top: 20px;
          }
        }
        #{$self}__list-header{
          margin-bottom: 10px;
        }
        #{$self}__list-text{
          line-height: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .product-info{
    margin-bottom: 20px;
    $self:&;
    .pl39{
      padding-left: 0;
    }
    .pr11{
      padding-right: 0;
    }
    &__add-cart {
      margin-top: 0;
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 200;
      margin-bottom: 0;
      border-radius: 0;
      opacity: .95;
      justify-content: center;
      .title{
        display: none;
      }
      .title-responsive{
        display: block;
      }
      .price{
        display: none;
      }
    }
    &__information{
      #{$self}__information-header{
        margin-bottom: 15px;
      }
      #{$self}__information-perform{
        margin-bottom: 32px;
      }
    }
    &__meta{
      padding-bottom: 14px;
      margin-bottom: 20px;
      #{$self}__meta-color{
        margin-bottom: 27px;
      }
      #{$self}__meta-item{
        &--size{
          position: fixed;
          visibility: hidden;
          opacity: 0;
          bottom: -100%;
          right: 0;
          width: 100%;
          transition: .4s ease-in-out;
          background-color: #fff;
          z-index: 1000;
          padding: 0 15px;
          margin-bottom: 0;
          padding-top: 50px;
          border-top: none;
          box-shadow: 0 -5px 25px -16px rgba(0, 0, 0, 0.4);
          border-radius: 15px 15px 0 0;
          #{$self}__meta-entry{
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .close{
            font-size: 16px;
            color: #000;
            cursor: pointer;
            display: block;
            position: absolute;
            top: 15px;
            left: 15px;
            transition: .3s;
          }
          &.active{
            visibility: visible;
            opacity: 1;
            bottom: 0;
          }
        }
      }
    }
    &__price{
      padding-bottom: 22px;
    }
  }
  .variations-count{
    margin-bottom: 15px;
  }
  .seller-info{
    margin-top: 0;
    margin-bottom: 20px;
  }
  .single-content{
    .pl39{
      padding-left: 0;
    }
    .pr11{
      padding-right: 0;
    }
  }
}

@media (max-width: 480px) {
  .seller-head{
    $self:&;
    &__name {
      //margin-left: auto;
      #{$self}__name-title{
        font-size: 13px;
      }
      h2{
        font-size: 15px;
      }
    }
     #{$self}__info{
       #{$self}__info-item{
         margin-left: 12px;
         i{
           &.icon-transportation{
             margin-bottom: 7px;
           }
           &.icon-cube{
             margin-bottom: 5px;
           }
           &.icon-location{
             margin-bottom: 3px;
           }
         }
       }
     }
  }
  .product-info{
    $self:&;
    &__information{
      #{$self}__information-header{
        flex-wrap: wrap;
        margin-bottom: 11px;
        #{$self}__information-name{
          width: 100%;
          margin-bottom: 15px;
          h2{
            font-size: 21px;
          }
        }
      }
      #{$self}__information-perform{
        margin-bottom: 30px;
      }
      #{$self}__information-properties{
        .properties{
          &__items-item{
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .seller-info{
    $self:&;
    &__content{
      #{$self}__content-meta{
        .item{
          &__title{
            font-size: 12px;
          }
          &__value{
            font-size: 12px;
          }
        }
      }
      #{$self}__content-footer{
        padding: 0 10px;
        .item{
          span{
            font-size: 13px;
          }
        }
      }
    }
  }
  .product-content{
    margin-bottom: 15px;
  }
  .comments{
    $self:&;
    &__list{
      #{$self}__list-item{
        &--reply{
          padding: 32px 10px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .seller-head{
    $self:&;
    height: initial;
    flex-direction: column;
    align-items: center;
    background-size: 418%;
    padding: 10px 0;
    &__name {
      width: 100%;
      margin-left: 0;
      align-items: center;
      margin-bottom: 10px;
      #{$self}__name-title{
        font-size: 12px;
      }
      h2{
        font-size: 13px;
      }
    }
    &__link{
      left: 8px;
      font-size: 20px;
    }
  }
  .comments{
    $self:&;
    &__list{
      #{$self}__list-item{
        #{$self}__list-header{
          #{$self}__list-name{
            margin-left: 10px;
          }
        }
        #{$self}__list-footer{
          #{$self}__list-like{
            margin-left: 15px;
            .like{
              margin-left: 10px;
            }
          }
          #{$self}__list-reply{
            .reply{
              margin-left: 10px;
            }
          }
        }
        &--reply{
          padding: 32px 10px;
          padding-bottom: 15px !important;
          #{$self}__list-footer{
            #{$self}__list-like{
              margin-left: 10px;
              .like{
                margin-left: 5px;
              }
            }
            #{$self}__list-reply{
              .reply{
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .comments{
    $self:&;
    &__form{
      #{$self}__form-btn{
        .item{
          input{
            width: 70%;
          }
        }
      }
    }
  }
}