
.other-products{
  background-color: #f5f7f9;
  border-radius: 0 15px 15px 0;
  margin-left: -64vh;
  overflow: hidden;
  padding-bottom: 33px;
  position: relative;
  margin-bottom: 44px;
  $self:&;
  &:before{
    content: '';
    top: 40px;
    bottom: 0;
    width: 40px;
    right: -40px;
    position: absolute;
    box-shadow: -24px 0px 30px -18px rgba(0, 0, 0, 0.42);
    z-index: 100;
    pointer-events: none;
  }
  .next{
    position: absolute;
    right: 41px;
    top: 50%;
    transform: translate(0, -50%);
    width: 46px;
    height: 41px;
    background-color: #12b540;
    border-radius: 60px 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 110;
    padding-left: 4px;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: .3s;
    i{
      font-size: 17px;
      color: #ffffff;
    }
    &.swiper-button-disabled{
      opacity: 0;
      visibility: hidden;
    }
  }
  .prev{
    position: absolute;
    left: calc(52vh - 45px);
    top: 50%;
    transform: translate(0, -50%);
    width: 46px;
    height: 41px;
    background-color: #12b540;
    border-radius: 0 60px 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 110;
    padding-right: 4px;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: .3s;
    i{
      font-size: 17px;
      color: #ffffff;
    }
    &.swiper-button-disabled{
      opacity: 0;
      visibility: hidden;
    }
  }
  &__header{
    display: flex;
    align-items: center;
    padding-right: 33px;
    padding-top: 28px;
    margin-bottom: 35px;
    justify-content: space-between;
    > h2 {
      color: #383c48;
      font-size: 15px;
      font-weight: 700;
    }
    #{$self}__header-info{
      display: flex;
      align-items: center;
      #{$self}__header-logo{
        margin-left: 23px;
      }
      #{$self}__header-title{
        h2{
          color: #383c48;
          font-size: 15px;
          font-weight: 700;
          margin-bottom: 4px;
        }
        span{
          color: #737889;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
    #{$self}__header-link{
      color: #6f7484;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      position: relative;
      right: -64vh;
      i{
        font-size: 3px;
        color: #828695;
        margin-right: 13px;
      }
    }

  }
  &--other-sellers{
    #{$self}__header{
      margin-bottom: 44px;
      padding-top: 43px;
    }
  }
}