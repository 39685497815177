.cart-items{
  $self:&;
  margin-bottom: 30px;
  &__item{
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
    padding: 32px 0 32px 0;
    .wrapper{
      display: flex;
      align-items: center;
    }
    #{$self}__item-information{
      flex-grow: 1;
    }
    #{$self}__item-info{
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #d5d8e1;
      padding-bottom: 28px;
      #{$self}__item-img{
        margin-left: 23px;
      }
      #{$self}__item-info-right{
        #{$self}__item-title{
          color: #4d5367;
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 52px;
        }
        #{$self}__item-meta{
          display: flex;
          align-items: center;
          span{
            color: #787d8b;
            font-size: 14px;
            font-weight: 700;
            margin-left: 40px;
            &:nth-last-of-type(1){
              margin-left: 0;
            }
          }
        }
      }
      #{$self}__item-info-left{
        display: flex;
        align-items: center;
        margin-right: auto;
        position: relative;
        top: 9px;
        #{$self}__item-add{
          cursor: pointer;
          i{
            font-size: 23px;
            color: #454a5b;
            transition: .3s;
          }
          &:hover{
            i{
              color: #12a53b;
            }
          }
        }
        .count{
          color: #454a5b;
          font-size: 20px;
          font-weight: 700;
          margin-right: 27px;
          margin-left: 27px;
        }
        #{$self}__item-minus{
          cursor: pointer;
          i{
            font-size: 23px;
            color: #454a5b;
            transition: .3s;
          }
          &:hover{
            i{
              color: #12a53b;
            }
          }
        }
      }
    }
    #{$self}__footer{
      display: flex;
      align-items: center;
      padding-top: 10px;
      > div{
        margin-left: 40px;
      }
      #{$self}__footer-seller{
        display: flex;
        align-items: center;
        .logo{
          margin-left: 8px;
        }
        .title{
          color: #787d8b;
          font-size: 14px;
          font-weight: 700;
        }
      }
      #{$self}__footer-available{
        color: #787d8b;
        font-size: 14px;
        font-weight: 700;
      }
      #{$self}__footer-item{
        color: #787d8b;
        font-size: 14px;
        font-weight: 700;
      }
      #{$self}__item-price{
        display: flex;
        align-items: center;
        margin-right: auto;
        margin-left: 0;
        .price{
          color: #454a5b;
          font-size: 27px;
          font-weight: 700;
          letter-spacing: -1.08px;
          margin-left: 6px;
        }
        .unit{
          color: #454a5b;
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}