.amazing-offers{
  $self:&;
  overflow: hidden;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 20px;
  &__bg-image{
    position: absolute;
    top: 120px;
    left: 37px;
  }
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 37px;
    #{$self}__header-title{
      margin-left: 8px;
      i{
        font-size: 31px;
        color: #ef473a;
      }
    }
    #{$self}__header-range{
      padding: 0 12px 0 9px;
      height: 21px;
      background-color: #ef473a;
      display: flex;
      align-items: center;
      border-radius: 60px;
      position: relative;
      top: -4px;
      i{
        font-size: 12px;
        color: #ffffff;
        &:nth-of-type(1){
          margin-left: 7px;
        }
      }
      .price{
        color: #ffffff;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -1.26px;
        margin-left: 6px;
      }
    }
    #{$self}__header-link{
      margin-right: auto;
      display: flex;
      align-items: center;
      color: #ef473a;
      font-size: 15px;
      font-weight: 700;
      i{
        font-size: 4px;
        color: #ef473a;
        margin-top: 2px;
        margin-right: 19px;
      }
    }
  }
  &__content{
    position: relative;
    z-index: 100;
    .new-product__nav{
      width: 35px;
      height: 81px;
      background-color: rgba(69,74,91,.1);
      .arrow{
        &:before{
          height: 2px;
          background-color: #454a5b;
        }
        &:after{
          height: 2px;
          background-color: #454a5b;
        }
        &__line{
          height: 2px;
          background-color: #454a5b;
        }
      }
      &.swiper-button-disabled{
        .arrow{
          &__line{
            height: 6px;
          }
        }
      }
      &.new-product__nav--next{
        left: 10px;
      }
      &.new-product__nav--prev{
        right: 10px;
      }
    }
  }
}