@media (min-width: 1600px) {
  .mega-menu-cat{
    $self:&;
    > .container {
      #{$self}__cat-wrapper{
        right: 400px;
      }
    }
  }
}

@media (min-width: 1800px) {
  .mega-menu-cat{
    $self:&;
    > .container {
      #{$self}__cat-wrapper{
        right: 500px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .site-header {
    $self: &;
    &__left {
      justify-content: center;
      #{$self}__left-item {
        &--user-plus {
          margin-left: 30px;
        }
      }

      #{$self}__search {
        margin-left: 30px;
      }
    }
  }
}

@media (max-width: 992px) {
  .mega-menu-cat {
    $self:&;
    .container {
      > ul {
        > li {
          #{$self}__cat-wrapper {
            right: 200px;
            .container{
              flex-direction: column;
              justify-content: center;
            }
          }
          #{$self}__cat-img{
            margin-right: 0;
            margin-top: 15px;
          }
        }
      }
      #{$self}__cat-wrapper{
        .container{
          flex-wrap: wrap;
        }
      }
    }
  }
  .site-header {
    &__wrapper {
      flex-wrap: wrap;
    }

    &__right {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__left{
      margin-right: 0;
      width: 100%;
      justify-content: center;
    }
  }
}

@media (max-width: 768px) {
  .site-header{
    $self:&;
    &__left{
      flex-wrap: wrap;
      #{$self}__search{
        width: 100%;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }
  .mega-menu-cat {
    $self:&;
    #{$self}__cat-sub {
      flex-wrap: wrap;
      #{$self}__cat-sub-item{
        margin-left: 50px;
      }
    }
  }
}

@media (max-width: 576px) {
  .mega-menu-cat{
    display: none;
  }
  .site-header{
    $self:&;
    &__wrapper{
      margin-top: 50px;
      margin-bottom: 15px;
    }
    &__navigation{
      display: none;
    }
    .response-head{
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 5px 24px -14px rgba(0,0,0,.2);
      z-index: 100;
      background-color: rgba(255,255,255,.9);
      &__menu{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0,-50%);
        width: 50px;
        height: 50px;
        border-radius: 60px;
        box-shadow: 5px 3px 25px -11px rgba(0,0,0,.23);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .mega-menu-site-responsive{
      &__location{
        display: flex;
        align-items: center;
        border: 1px solid #e7e8f2;
        border-radius: 5px;
        padding: 0 15px 0 17px;
        height: 36px;
        cursor: pointer;
        i{
          font-size: 18px;
          color: #4d5367;
          margin-left: 13px;
        }
        span{
          color: #4d5367;
          font-size: 15px;
          font-weight: 700;
        }
      }
      &__search{
        button{
          i{
            font-size: 17px;
            color: #5d6273;
          }
        }
      }
    }
    &__right{
      display: none;
    }
    &__left{
      #{$self}__search{
        display: none;
      }
    }
    &__momentary-products{
      .swiper-container{
        padding-top: 12px;
        padding-bottom: 15px;
        padding-right: 15px;

      }
      .pl20{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &__momentary-products-title{
      justify-content: center !important;
      padding-top: 5px !important;
      padding-bottom: 10px;
    }
    &--sec-header{

    }
  }
}

@media (max-width: 400px) {
  .response-head{
    &__logo{
      padding-right: 15px;
    }
  }
}
