@media (max-width: 576px) {
  .container{
    padding: 0 15px;
  }
}

body{
  &.overflow-none{
    overflow: hidden;
  }
}