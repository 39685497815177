@media (max-width: 1200px) {
  .filters-summary {
    $self: &;

    &__sorting {
      #{$self}__sorting-title {
        margin-left: 10px;
      }

      #{$self}__sorting-items {
        #{$self}__sorting-item {
          padding: 0 10px 0 10px;
          font-size: 13px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .filters-summary {
    $self: &;
    margin-bottom: 18px;
    &__sorting {
      #{$self}__sorting-items {
        display: none;
      }

      #{$self}__sorting-responsive {
        display: block;
        flex: 0 1 30%;

        .selectize-control {
          height: 35px;

          &.single {
            .selectize-input {
              &:after {
                content: '\e90e';
                font-size: 8px;
                font-family: 'icomoon';
                border: none;
                width: initial;
                height: initial;
                top: 38%;
                transition: .3s;
                transform: rotateX(0);
              }

              &.dropdown-active {
                &:after {
                  transform: rotateX(180deg);
                }
              }
            }
          }
        }
      }
    }
    &__apply{
      #{$self}__apply-content{
        #{$self}__apply-items{
          #{$self}__apply-item{
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .cat-products {
    $self: &;

    [class*=col]:nth-of-type(2n) {
      #{$self}__item {
        border-left: none;
      }
    }

    [class*=col]:nth-of-type(2n + 1) {
      #{$self}__item {
        border-right: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .filters-summary {
    $self: &;

    &__sorting {
      #{$self}__sorting-responsive {
        flex: 0 1 40%;
      }
    }
  }
  .cat-filter-sidebar{
    overflow: scroll;
    .close{
      display: block;

      &:hover{
        opacity: .8;
      }
    }
  }
  .cat-filter-sidebar{
    position: fixed;
    left: 0;
    right: 0;
    top: 100%;
    transition: .3s;
    z-index: 200;
    background-color: #fff;
    &.active{
      top: 0;
      bottom: 0;
    }
  }
  .cat-filter-responsive{
    display: flex;
    justify-content: center;
    font-size: 16px;
    position: fixed;
    background-color: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px 0;
    cursor: pointer;
    box-shadow: 0 -5px 32px -18px rgba(0,0,0,.4);
    z-index: 300;
    i{
      font-size: 24px;
      color: #adbac9;
      margin-left: 20px;
      &.icon-menu{
        font-size: 20px;
      }
    }
  }
  .filter{
    $self:&;
    &__category{
      padding-left: 0;
      padding-bottom: 15px;
      #{$self}__category-head{
        margin-bottom: 10px;
      }
      #{$self}__category-search{
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 15px;
      }
      #{$self}__category-content{
        h2{
          margin-bottom: 15px;
        }
      }
    }
    &__brand{
      padding-left: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      #{$self}__brand-head{
        padding-bottom: 10px;
      }
      #{$self}__brand-search{
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
    &__true-false{
      padding-left: 0;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    &__color{
      padding-left: 0;
      #{$self}__color-head{
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
    }
  }
}


@media (max-width: 576px) {
  .filters-summary {
    $self: &;
    margin-bottom: 15px;
    &__sorting {
      flex-wrap: wrap;
      padding-top: 15px;
      margin-bottom: 10px;
      #{$self}__sorting-responsive {
        flex: 1 0 auto;
      }

      #{$self}__sorting-count-product {
        width: 100%;
      }
    }
    &__apply{
      #{$self}__apply-header{
        margin-bottom: 15px;
      }
    }
  }
  .cat-filter-header {
    $self: &;
    padding-bottom: 15px;

    &__wrapper {
      flex-wrap: wrap;
      justify-content: center;

      #{$self}__breadcrumb {
        margin-bottom: 5px;
        flex-wrap: wrap;

        a, span {
          margin-bottom: 10px;
        }
      }

      #{$self}__amazing-offers {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 450px) {
  .filters-summary {
    $self: &;

    &__sorting {
      #{$self}__sorting-title {
        font-size: 14px;
        margin-left: 5px;
      }

      #{$self}__sorting-responsive {

        .selectize-control {
          &.single {
            .selectize-input {
              &:after {
                left: 5px;
                font-size: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .filters-summary {
    $self: &;

    &__sorting {
      #{$self}__sorting-title {
        margin-bottom: 15px;
      }

      #{$self}__sorting-responsive {
        flex: 1 0 100%;
        margin-bottom: 15px;
      }
    }
  }
}