.cart-footer{
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding: 28px 0;
  margin-bottom: 32px;
  $self:&;
  &__wrapper{
    display: flex;
    align-items: center;
  }
  &__back{
    width: 152px;
    height: 49px;
    box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
    background-color: #f7f8fb;
    border-radius: 5px;
    color: #777c8d;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 0 29px;
    i{
      font-size: 17px;
      color: #777c8d;
    }
  }
  &__continue{
    width: 202px;
    height: 49px;
    box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
    background-color: #12b540;
    color: #ffffff;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0 25px 0 20px;
    margin-right: 67px;
    i{
      font-size: 17px;
      color: #ffffff;
    }
  }
  &__info{
    display: flex;
    align-items: center;
    margin-right: auto;
    #{$self}__info-item{
      display: flex;
      align-items: center;
      margin-left: 70px;
      #{$self}__info-title{
        color: #999ca7;
        font-size: 16px;
        font-weight: 700;
        margin-left: 8px;
      }
      #{$self}__info-value{
        display: flex;
        align-items: center;
        color: #454a5b;
        font-size: 16px;
        ins{
          color: #454a5b;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.72px;
          text-decoration: none;
          margin-left: 3px;
        }
        .unit{
          color: #8c919f;
          font-size: 13px;
          font-weight: 700;
        }
      }
      &:nth-last-of-type(1){
        margin-left: 0;
      }
    }

  }
}