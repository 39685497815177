.top-sellers {
  $self: &;
  margin-top: 70px;
  margin-bottom: 76px;

  &__nav {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 60px;
    box-shadow: -2px 7px 16px -5px rgba(0, 0, 0, .2);
    z-index: 100;
    background-color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    i {
      font-size: 14px;
      color: #12b540;
      transition: .3s;
    }

    &--next {
      right: 10px;
    }

    &--prev {
      left: 10px;
    }

    &.swiper-button-disabled {
      i {
        color: #b6bbcb
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 51px;

    h2 {
      color: #454a5b;
      font-size: 20px;
      font-weight: 700;
    }

    #{$self}__header-link {
      color: #14a13b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;

      i {
        font-size: 4px;
        color: #14a13b;
        margin-top: 3px;
        margin-right: 19px;
      }
    }
  }

  &__content {
    #{$self}__content-swiper {
      padding: 30px;
      margin: -30px;
    }

    #{$self}__item {
      position: relative;
      height: 188px;
      border-radius: 9px;

      > a {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        top: 72px;
        bottom: 0;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
        border-radius: 0 0 9px 9px;
      }

      #{$self}__item-curve {
        height: 33px;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
      }

      #{$self}__item-info {
        position: absolute;
        right: 0;
        left: 0;
        top: 72px;
        bottom: 0;
        background-color: #fff;
        border-radius: 0 0 9px 9px;

        #{$self}__item-logo {
          position: absolute;
          left: 49%;
          top: -23px;
          transform: translate(-50%, 0);
        }

        #{$self}__item-title {
          color: #3c4153;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          margin-top: 44px;
          margin-bottom: 15px;
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 10px;
        }

        #{$self}__item-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px 0 19px;

          #{$self}__item-footer-location {
            display: flex;
            align-items: center;

            i {
              font-size: 18px;
              color: #4d5367;
              margin-left: 7px;
            }

            .city {
              color: #4d5367;
              font-size: 14px;
              font-weight: 700;
            }
          }

          #{$self}__item-footer-rate {
            display: flex;
            align-items: baseline;

            .numb {
              color: #6d7388;
              font-size: 19px;
              font-weight: 700;
              position: relative;
              top: .5px;
              margin-left: 3px;
            }

            i {
              font-size: 13px;
              color: #1049dc;
            }
          }
        }
      }

      &--create-store {
        position: relative;
        box-shadow: 0 16px 16px -14px rgb(96, 178, 119);
        transition: .3s;

        .info {
          position: absolute;
          top: 49%;
          left: 0;
          right: 0;
          transform: translate(0, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;

          i {
            font-size: 33px;
            color: #fff;
            margin-bottom: 10px;
          }

          &__desc {
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
          }
        }

        .link {
          position: absolute;
          display: flex;
          align-items: center;
          color: #ffffff;
          font-size: 15px;
          font-weight: 700;
          bottom: 16px;
          left: 16px;
          right: initial;
          top: initial;

          i {
            font-size: 10px;
            color: #ffffff;
            margin-right: 6px;
            margin-top: 2px;
          }
        }

        &:hover {
          box-shadow: 0 8px 16px -14px rgb(96, 178, 119);
        }
      }
    }
  }

  &--cat-one {
    margin-bottom: 57px;

    #{$self}__header {
      margin-bottom: 36px;
      justify-content: flex-start;

      #{$self}__header-cat {
        color: #646a7c;
        font-size: 15px;
        font-weight: 700;
        margin-right: 17px;
      }

      #{$self}__header-link {
        color: #454a5b;
        margin-right: auto;

        i {
          margin-right: 9px;
          color: #454a5b;
        }
      }
    }

    #{$self}__content {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        width: 20px;
        left: -20px;
        box-shadow: 14px 0 14px -9px rgba(0, 0, 0, 0.4);
        z-index: 100;
      }
    }
  }
}