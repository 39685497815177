.site-footer{
  background-color: #edf0f4;
  padding-top: 85px;
  padding-bottom: 50px;
  $self:&;
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 52px;
    figure{
      margin-left: 42px;
    }
    #{$self}__header-info{
      border-top: 1px solid #d4dbe7;
      border-bottom: 1px solid #d4dbe7;
      padding-top: 6px;
      padding-bottom: 10px;
      .working-hours{
        color: #7d818e;
        font-size: 12.33px;
        font-weight: 700;
        margin-left: 58px;
      }
      .customer-guide{
        color: #7d818e;
        font-size: 12.33px;
        font-weight: 400;
        a{
          color: #454a5b;
          font-weight: 700;
        }
      }
    }
    #{$self}__header-phone{
      height: 40px;
      border-radius: 60px;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      padding: 0 24px 0 21px;
      box-shadow: 0 20px 14px -7px rgba(0,0,0,.06);
      margin-right: auto;
      .phone{
        color: #454a5b;
        font-size: 17.5px;
        font-weight: 700;
        letter-spacing: 0.42px;
        margin-left: 4px;
      }
      .pre-phone{
        color: #767b8e;
        font-size: 16px;
        font-weight: 400;
      }
      i{
        font-size: 18px;
        color: #ef473a;
        margin-right: 15px;
      }
    }
  }
  &__content{
    margin-bottom: 47px;
    #{$self}__content-item{
      ul{
        li{
          color: #454a5b;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 18px;
          transition: .3s;
          &:last-child{
            margin-bottom: 0;
          }
          &:hover{
            color: #12b540;
          }
        }
      }
    }
    #{$self}__content-social{
      .newsletters{
        margin-bottom: 43px;
        .title{
          color: #454a5b;
          font-size: 15px;
          font-weight: 400;
          display: inline-block;
          position: relative;
          top: -1px;
          margin-bottom: 29px;
        }
        form{
          max-width: 376px;
          position: relative;

          input{
            width: 100%;
            height: 58px;
            background-color: #dee4eb;
            border-radius: 8px;
            padding-right: 23px;
            &::placeholder{
              color: #797e90;
              font-size: 16px;
              font-weight: 400;
              opacity: 1;
            }
          }
          button{
            width: 54px;
            height: 44px;
            box-shadow: 0 2px 2px rgba(80, 92, 106, 0.11);
            background-color: #edf0f4;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 7px;
            top: 50%;
            transform: translate(0,-50%);
            border-radius: 6px;
            i{
              font-size: 16px;
              color: #12b540;
            }
          }
        }
      }
      .social-media{
        .title{
          color: #454a5b;
          font-size: 15px;
          font-weight: 400;
          display: inline-block;
          margin-bottom: 30px;
        }
        &__content{
          display: flex;
          align-items: center;
          .item{
            display: flex;
            align-items: center;
            margin-left: 10px;
            transition: .3s;
            height: 20px;
            i{
              font-size: 19px;
              color: #878d9f;
              margin-left: 21px;
              transition: .3s;
            }
            span{
              color: #454a5b;
              font-size: 0;
              font-weight: 700;
              transition: .3s;
            }
            &:hover{
              margin-left: 37px;
              i{
                color: #454a5b;
              }
              span{
                font-size: 14px;
              }
            }
            &:last-child{
              margin-left: 0;
            }
          }
        }
      }

    }
    #{$self}__content-enamad{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      .item{
        &:nth-of-type(1){
          margin-bottom: 32px;
        }
        &:nth-of-type(2){
          margin-bottom: 24px;
        }
      }
    }
  }
  &__footer{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    #{$self}__footer-feature{
      margin-bottom: 10px;
      .items{
        display: flex;
        align-items: center;
        border-top: 1px solid  #d4dbe7;
        border-bottom: 1px solid  #d4dbe7;
        padding: 17px 0 19px 0;
      }
      .item{
        display: flex;
        align-items: center;
        color: #454a5b;
        font-size: 12.1px;
        font-weight: 700;
        margin-left: 45px;
        i{
          color: #454a5b;
          margin-left: 19px;
          &.icon-cube{
            font-size: 24px;
          }
          &.icon-support{
            font-size: 22px;
          }
          &.icon-ask{
            font-size: 22px;
          }
          &.icon-transportation{
            font-size: 20px;
          }
          &.icon-card{
            font-size: 20px;
          }
        }
        &:nth-last-of-type(1){
          margin-left: 0;
        }
      }
    }
    #{$self}__footer-service{
      height: 51px;
      background-color: #edf0f4;
      width: 238px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      box-shadow: -32px 21px 32px -22px rgba(0, 0, 0, 0.45);
      transition: .3s;
      padding-bottom: 11px;
      margin-right: auto;
      span{
        color: #454a5b;
        font-size: 13.2px;
        font-weight: 700;
        margin-left: 27px;
      }
      i{
        font-size: 23px;
        color: #b6c0cd;
      }
      &:hover{
        box-shadow: -22px 15px 32px -27px rgba(0, 0, 0, 0.45);
      }
    }
  }
  &__desc{
    color: #5f6373;
    font-size: 13px;
    font-weight: 400;
    line-height: 39px;
    margin-bottom: 42px;
  }
  &__copy-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      max-width: 382px;
      color: #5f6373;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  &--sec-footer{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      height: 272px;
      left: 0;
      right: 0;
      background-color: #ffffff;
      background-image: linear-gradient(to top, #edf0f4 0%, #ffffff 100%);
      z-index: 5;
    }
    #{$self}__header{
      position: relative;
      z-index: 10;
    }
    #{$self}__content{
      position: relative;
      z-index: 10;
    }
    #{$self}__footer{
      position: relative;
      z-index: 10;
    }
    #{$self}__desc{
      position: relative;
      z-index: 10;
    }
    #{$self}__copy-right{
      position: relative;
      z-index: 10;
    }
  }
}