@media (max-width: 1200px) {
  .cart-footer{
    $self:&;
    &__wrapper{
      flex-wrap: wrap;
      #{$self}__back{
        order: 1;
      }
      #{$self}__info{
        order: 0;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      #{$self}__continue{
        margin-right: auto;
        order: 2;
      }
    }
  }
  .cart-method{
    &__nav{
      display: flex;
    }
  }
}

@media (max-width: 992px) {
  .header-cart {
    $self:&;
    padding-bottom: 15px;
    &__wrapper{
      flex-wrap: wrap;
      justify-content: center !important;
      #{$self}__breadcrumb{
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      #{$self}__count{
        margin-bottom: 10px;
      }
    }
  }
  .cart-info{
    $self:&;
    &__wrapper{
      flex-wrap: wrap;
      justify-content: space-between;
      #{$self}__item{
        margin-left: 0;
      }
      #{$self}__continue{
        justify-content: center;
        width: 100%;
        margin-top: 8px;
      }
    }
  }
  .cart-items{
    $self:&;
    &__item {
      #{$self}__footer {
      > div{
        margin-left: 25px;
      }
      }
    }
  }
  .cart-footer{
    $self:&;
    &__wrapper{
      justify-content: center;
    }
    &__info{
      flex-wrap: wrap;
      margin-bottom: 15px;
      order: 0;
    }
    #{$self}__back{
      order: 1;
    }
    #{$self}__continue{
      margin-right: auto;
      order: 2;
    }
  }
  .cart-address{
    $self:&;
    &__items{
      #{$self}__items-item{
        flex-wrap: wrap;
      }
    }
  }
  .cart-method{
    $self:&;
    &__coupon{
      flex-wrap: wrap;
      #{$self}__coupon-title{
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .cart-info{
    $self:&;
    padding: 15px 0 15px 0;
    &__wrapper{
      #{$self}__item{
        margin-left: 0;
        #{$self}__item-title{
          font-size: 14px;
        }
        #{$self}__item-value{
          ins{
            font-size: 16px;
          }
          .unit{
            font-size: 12px;
          }
        }
      }
    }
  }
  .cart-items{
    $self:&;
    &__item {

      #{$self}__item-info{
        #{$self}__item-info-right {
          #{#{$self}}__item-title{
            font-size: 20px;
            margin-bottom: 30px;
          }
        }
      }
      #{$self}__footer {
        #{$self}__item-price{
          .price{
            font-size: 20px;
          }
          .unit{
            font-size: 13px;
          }
        }
      }
    }
  }
  .cart-footer{
    $self:&;
    &__wrapper{
      #{$self}__info{
        justify-content: center;
        margin-bottom: 5px;
        #{$self}__info-item{
          margin-left: 30px;
          margin-bottom: 10px;
        }
      }
    }

  }
}

@media (max-width: 576px) {
  .cart-info{
    $self:&;
    margin-bottom: 15px;
    &__wrapper{
      justify-content: center;
      #{$self}__item{
        margin-left: 20px;
        margin-bottom: 8px;
      }
      #{$self}__continue{
        margin-top: 0;
      }
    }
  }
  .cart-items{
    $self:&;
    margin-bottom: 15px;
    &__item{
      padding: 15px 0 15px 0;
      margin-bottom: 15px;
      .wrapper{
        flex-wrap: wrap;
        justify-content: center;
      }
      #{$self}__item-img{
        margin-bottom: 10px;
      }
      #{$self}__item-info{
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 20px;
        #{$self}__item-info-right{
          #{$self}__item-title{
            text-align: center;
            margin-bottom: 10px;
          }
          #{$self}__item-meta{
            justify-content: center;
          }
        }
        #{$self}__item-info-left{
          margin-right: 0;
        }
      }
    }
    &__footer{
      flex-wrap: wrap;
      justify-content: center;
      #{$self}__footer-seller{
        order: 1;
        margin-bottom: 5px;
      }
      #{$self}__footer-available{
        order: 1;
        margin-bottom: 5px;
      }
      #{$self}__footer-item{
        order: 1;
        margin-bottom: 5px;
      }
      #{$self}__item-price{
        width: 100%;
        justify-content: center;
      }
    }
  }
  .cart-footer{
    $self:&;
    padding: 15px 0;
    margin-bottom: 15px;
    &__info{
      flex-wrap: wrap;
      margin-bottom: 15px;
      order: 0;
    }
    #{$self}__back{
      order: 2;
      width: 100%;
    }
    #{$self}__continue{
      margin-right: 0;
      order: 1;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .cart-address{
    $self:&;
    padding: 15px 0 15px 0;
    margin-bottom: 15px;
    &__title{
      margin-bottom: 15px;
      font-size: 20px;
    }
    &__items{
      #{$self}__items-item{
        padding-bottom: 15px;
        margin-bottom: 15px;
        #{$self}__items-address{
          margin-bottom: 7px;
        }
      }
    }
  }
  .cart-method{
    $self:&;
    padding-top: 12px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &__title{
      margin-bottom: 47px;
    }
    &__items{
      margin-bottom: 20px;
    }
    &__coupon {
      #{$self}__coupon-input{
        flex-wrap: wrap;
        input{
          margin-bottom: 15px;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .cart-items{
    $self:&;
    &__footer{
      flex-wrap: wrap;
      justify-content: center;
      #{$self}__footer-seller{
        margin-left: 0 !important;
        width: 100%;
        justify-content: center;
      }
      #{$self}__footer-available{
        margin-left: 0 !important;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      #{$self}__footer-item{
        margin-left: 0 !important;
        width: 100%;
        justify-content: center;
        display: flex;
      }
    }
  }
}