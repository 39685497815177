.filters-summary{
  $self:&;
  margin-bottom: 31px;
  &__sorting{
    display: flex;
    align-items: center;
    padding-top: 33px;
    margin-bottom: 34px;
    #{$self}__sorting-title{
      color: #6b707f;
      font-size: 15px;
      font-weight: 700;
      margin-left: 20px;
    }
    #{$self}__sorting-items{
      display: flex;
      align-items: center;
      #{$self}__sorting-item{
        color: #454a5b;
        font-size: 15px;
        font-weight: 700;
        background-color: #f5f6f9;
        border-radius: 60px;
        height: 29px;
        display: flex;
        align-items: center;
        padding: 0 17px 0 17px;
        margin-left: 9px;
        transition: .3s;
        &:nth-last-of-type(1){
          margin-left: 0;
        }
        &:hover{
          background-color: #12b540;
          color: #ffffff;
        }
        &.active{
          background-color: #12b540;
          color: #ffffff;
        }
      }
    }
    #{$self}__sorting-responsive{
      display: none;
      z-index: 50;
    }
    #{$self}__sorting-count-product{
      display: flex;
      align-items: center;
      margin-right: auto;
      .numb{
        color: #454a5b;
        font-size: 18px;
        font-weight: 700;
        margin-left: 5px;
      }
      .title{
        color: #9da1af;
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
  &__apply{
    #{$self}__apply-header{
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 21px;
      &:before{
        content: '';
        position: absolute;
        top: 12px;
        right: 0;
        left: 0;
        border-bottom: 1px solid #dfe1ea;
        z-index: 10;
      }
      #{$self}__apply-title{
        display: inline-flex;
        align-items: center;
        color: #6b707f;
        font-size: 15px;
        font-weight: 700;
        position: relative;
        background-color: #fff;
        z-index: 20;
        padding-left: 38px;
        i{
          font-size: 17px;
          color: #12b540;
          margin-left: 12px;
        }
      }
    }
    #{$self}__apply-content{
      display: flex;
      align-items: center;
      #{$self}__apply-items{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        #{$self}__apply-item{
          background-color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          border: 1px solid #dce1ea;
          height: 32px;
          border-radius: 5px;
          padding: 0 11px 0 13px;
          margin-left: 12px;
          transition: .3s;
          i{
            font-size: 8px;
            color: #d84545;
            margin-left: 10px;
          }
          .filter-name{
            color: #454a5b;
            font-size: 13px;
            font-weight: 700;
          }
          &:nth-last-of-type(1){
            margin-left: 0;
          }
          &:hover{
            background-color: #dce1ea;
          }
        }
      }
      #{$self}__apply-delete-filters{
        margin-right: auto;
        cursor: pointer;
        border-radius: 5px;
        width: 44px;
        height: 32px;
        border: 1px solid #dce1ea;
        background-color: #f5f6f9;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          font-size: 15px;
          color: #83899c;
          transition: .3s;
        }
        &:hover{
          i{
            color: #d84545;
          }
        }
      }
    }
  }
}