.filter{
  $self:&;
  &__category{
    padding-top: 36px;
    padding-bottom: 28px;
    border-bottom: 1px solid #edeff2;
    padding-left: 30px;
    #{$self}__category-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;
      #{$self}__category-title{
        color: #1049dc;
        font-size: 15px;
        font-weight: 700;
      }
      i{
        font-size: 10px;
        color: #1049dc;
      }
    }
    #{$self}__category-search{
      display: flex;
      align-items: center;
      border-top: 1px solid  #edeff2;
      border-bottom: 1px solid  #edeff2;
      padding-top: 14px;
      padding-bottom: 18px;
      margin-bottom: 28px;
      input{
        color: #63697c;
        font-size: 14px;
        font-weight: 700;
        flex-grow: 1;
        &::placeholder{
          color: #63697c;
          font-size: 14px;
          font-weight: 700;
          opacity: 1;
        }
      }
      i{
        font-size: 15px;
        color: #868a99;
      }
    }
    #{$self}__category-content{
      h2{
        color: #383c48;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 28px;
      }
      #{$self}__category-items{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 10px;
        transition: .2s ease-in-out;
        &:before{
          content: '..................................................................................................................................................................................................................................';
          color: #a3a6b2;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          letter-spacing: 10.64px;
          top: 6px;
          bottom: 0;
          right: 16px;
          transform: rotate(-90deg);
          transform-origin: 50% 0;
          white-space: nowrap;
          width: 2px;
        }
        #{$self}__category-item{
          padding-right: 20px;
          color: #626673;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 15px;
          transition: .3s;
          &:hover{
            color: #12b540;
          }
        }
      }
      #{$self}__category-more{
        display: flex;
        align-items: center;
        cursor: pointer;
        i{
          font-size: 9px;
          color: #a0a5b1;
          margin-left: 15px;
        }
        span{
          color: #a0a5b1;
          font-size: 14px;
          font-weight: 700;
        }
        &.active{
          i{
            &:before{
              content: '';
              width: 9px;
              height: 2px;
              background-color: #a0a5b1;
              display: inline-block;
              border-radius: 60px;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
  }
  &__brand{
    padding-top: 20px;
    border-bottom: 1px solid #edeff2;
    padding-bottom: 19px;
    margin-bottom: 20px;
    padding-left: 30px;
    #{$self}__brand-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #edeff2;
      padding-bottom: 23px;
      cursor: pointer;
      #{$self}__brand-title{
        color: #1049dc;
        font-size: 15px;
        font-weight: 700;
      }
      i{
        font-size: 8px;
        color: #1049dc;
        transition: .3s;
        transform: rotateX(0);
      }
      &.active{
        i{
          transform: rotateX(180deg);
        }
      }
    }
    #{$self}__brand-search{
      display: flex;
      align-items: center;
      border-bottom: 1px solid #edeff2;
      padding-top: 16px;
      padding-bottom: 17px;
      margin-bottom: 32px;
      input{
        color: #63697c;
        font-size: 14px;
        font-weight: 700;
        flex-grow: 1;
        &::placeholder{
          color: #63697c;
          font-size: 14px;
          font-weight: 700;
          opacity: 1;
        }
      }
      i{
        font-size: 15px;
        color: #868a99;
      }
    }
    #{$self}__brand-content{
      height: 296px;
      .mCSB_container{
        margin-left: 25px !important;
      }
      .mCSB_scrollTools .mCSB_draggerContainer{
        width: 5px;
        background-color: #e2e4eb;
        border-radius: 60px;
        overflow: hidden;
        bottom: 16px !important;
      }
      .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        background-color: #1049dc;
        opacity: 1 !important;
        width: 5px;
        border-radius: 0;
      }
      .mCSB_scrollTools .mCSB_draggerRail{
        background-color: #e2e4eb;
        width: 5px;
        opacity: 1 !important;
      }
      #{$self}__brand-item{
        margin-bottom: 12px;
        input{
          display: none;
          &:checked{
           + #{$self}__brand-label{
              #{$self}__brand-check{
                background-color: #454a5b;
                &:before{
                  transform: scale(1);
                }
              }
            }
          }
        }
        #{$self}__brand-label{
          display: flex;
          align-items: center;
          cursor: pointer;
          #{$self}__brand-check{
            width: 13px;
            height: 13px;
            border: 1px solid #cacdd8;
            background-color: #ffffff;
            border-radius: 3px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s;
            &:before{
              content: '\e910';
              font-family: 'icomoon';
              font-size: 7px;
              color: #ffffff;
              transform: scale(0);
              transition: .3s;
            }
          }
          #{$self}__brand-name{
            color: #454a5b;
            font-size: 14px;
            font-weight: 700;
          }
          #{$self}__brand-name-en{
            color: #b1b4c0;
            font-size: 12px;
            font-weight: 700;
            margin-right: auto;
          }
        }
      }
    }
  }
  &__true-false{
    border-bottom: 1px solid #edeff2;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-left: 30px;
    label{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      #{$self}__title{
        color: #454a5b;
        font-size: 15px;
        font-weight: 700;
        display: flex;
        align-items: center;
        i{
          font-size: 13px;
          color: #ffffff;
          background-color: #ef473a;
          padding: 4px 7px 4px 7px;
          border-radius: 60px;
          margin-left: 8px;
        }
      }
      #{$self}__scroll{
        width: 57px;
        height: 32px;
        background-color: #e2e4eb;
        border-radius: 60px;
        position: relative;
        transition: .3s;
        i{
          position: absolute;
          width: 24px;
          height: 24px;
          left: 4px;
          top: 50%;
          transform: translate(0,-50%);
          box-shadow: 0 1px 1px rgba(127, 133, 156, 0.45);
          background-color: #ffffff;
          border-radius: 60px;
          color: #bec1cc;
          font-size: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .3s;
        }
      }
    }
    input{
      display: none;
      &:checked{
        + label{
          #{$self}__scroll{
            background-color: #12b540;
            i{
              left: calc(100% - (4px + 24px));
              color: #12b540;
            }
          }
        }
      }
    }
  }
  &__color{
    padding-left: 30px;
    #{$self}__color-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #edeff2;
      padding-bottom: 21px;
      margin-bottom: 32px;
      cursor: pointer;
      #{$self}__color-title{
        color: #1049dc;
        font-size: 15px;
        font-weight: 700;
      }
      i{
        font-size: 8px;
        color: #1049dc;
        transition: .3s;
        transform: rotateX(0);
      }
      &.active{
        i{
          transform: rotateX(180deg);
        }
      }
    }
    #{$self}__color-content{
      .mCSB_container{
        margin-left: 25px !important;
      }
      .mCSB_scrollTools .mCSB_draggerContainer{
        width: 5px;
        background-color: #e2e4eb;
        border-radius: 60px;
        overflow: hidden;
        bottom: 16px !important;
      }
      .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        background-color: #1049dc;
        opacity: 1 !important;
        width: 5px;
        border-radius: 0;
      }
      .mCSB_scrollTools .mCSB_draggerRail{
        background-color: #e2e4eb;
        width: 5px;
        opacity: 1 !important;
      }
      #{$self}__color-items{
        height: 292px;
        #{$self}__color-item{
          margin-bottom: 12px;
          input{
            display: none;
            &:checked{
              + #{$self}__color-label{
                  #{$self}__color-check{
                    background-color: #454a5b;
                    &:before{
                      transform: scale(1);
                    }
                  }
              }
            }
          }
          #{$self}__color-label{
            display: flex;
            align-items: center;
            cursor: pointer;
            #{$self}__color-check{
              width: 13px;
              height: 13px;
              border: 1px solid #cacdd8;
              background-color: #ffffff;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 18px;
              transition: .3s;
              &:before{
                content: '\e910';
                font-family: 'icomoon';
                font-size: 7px;
                color: #ffffff;
                transform: scale(0);
                transition: .3s;
              }
            }
            #{$self}__color-pallet{
              width: 7px;
              height: 16px;
              background-color: #40a6af;
              border-radius: 60px;
              margin-left: 15px;
            }
            #{$self}__color-name{
              color: #454a5b;
              font-size: 14px;
              font-weight: 700;
            }
          }
          &--white{
            #{$self}__color-pallet{
              border: 1px solid #cacdd8;
            }
          }

        }
      }
    }
  }
  &--cat-one{
    position: relative;
    z-index: 1000;
    background-color: #fff;
    &:before{
      content: '';
      left: 0;
      right: -10000px;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 100;
    }
    #{$self}__category{
      position: relative;
      z-index: 200;
      padding-left: 0;
      border-bottom: none;
      border-bottom: none;
    }
    #{$self}__category-content{
      border-bottom: 1px solid #edeff2;
      padding-bottom: 28px;
      margin-bottom: 27px;
      &:nth-last-of-type(1){
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
}

.cat-filter-sidebar{
  .close{
    display: none;
    position: absolute;
    top: 10px;
    left: 15px;
    transition: .3s;
    font-size: 13px;
    opacity: 1;
    cursor: pointer;
  }
}

.cat-filter-responsive{
  display: none;
}