.main-info{
  display: flex;
  height: 600px;
  overflow: hidden;
  background-image: url('../img/bg-main-info.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 107px;
}
.buyer{
  flex: 1 0 50%;
  position: relative;
  $self:&;
  &__animation{
    position: absolute;
    right: 174px;
    top: 102px;
    z-index: 10;
  }
  &__item{
    &--comment{
      position: absolute;
      top: 15px;
      right: 283px;
    }
    &--arrow{
      position: absolute;
      top: 118px;
      right: -28px;
    }
    &--info{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 5px;
      right: -165px;
      #{$self}__item-subject{
        color: #1049dc;
        font-size: 28px;
        font-weight: 400;
      }
      #{$self}__item-desc{
        color: #787d8a;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 8px;
        position: relative;
        top: -2px;
      }
      #{$self}__item-numb{
        align-self: flex-start;
        display: flex;
        align-items: center;

        .numb{
          color: #767b8c;
          font-size: 23px;
          font-weight: 700;
          margin-left: 11px;
        }
        .title{
          color: #767b8c;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  &__background{
    position: absolute;
    z-index: 9;
    right: -540px;
  }
}

.seller{
  flex: 1 0 50%;
  position: relative;
  $self:&;
  &__animation{
    position: absolute;
    left: 230px;
    top: 65px;
    z-index: 10;
  }
  &__item{
    &--comment{
      position: absolute;
      right: 47px;
      top: 122px;
    }
    &--arrow{
      top: 102px;
      position: absolute;
      left: 0;
    }
    &--info{
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 127px;
      left: -226px;
      #{$self}__item-subject{
        color: #12b540;
        font-size: 28px;
        font-weight: 400;
      }
      #{$self}__item-desc{
        color: #787d8a;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 14px;
      }
      #{$self}__item-numb{
        align-self: flex-start;
        display: flex;
        align-items: center;
        .numb{
          color: #767b8c;
          font-size: 23px;
          font-weight: 700;
          margin-left: 10px;
        }
        .title{
          color: #767b8c;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  &__background{
    position: absolute;
    z-index: 9;
    left: -565px;
  }
}

.store{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 493px;
  transform: translate(-50%,0);
  &__create{
    width: 217px;
    height: 63px;
    background-color: #12b540;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 14px 30px -11px rgb(18, 181, 64);
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 37px;
    position: relative;
    z-index: 10;
  }

  &__show{
    width: 197px;
    height: 63px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 15px 44px -17px rgba(0, 0, 0, 0.3);
    color: #1049dc;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    z-index: 9;
    background-color: #fff;
  }
}

