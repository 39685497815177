.mega-menu-site-responsive{
 position: fixed;
 transition: 0.5s ease-in-out;
 right: -100%;
 display: flex;
 flex-direction: column;
 /*padding: 10px;*/
 top: 0;
 /*border-radius: 5px 0 0 5px;*/
 z-index: 9999999999999999999;
 width: 100%;
 overflow: scroll;
 padding: 10px;
 height: 100vh;

 &__header{
  display: flex;
  align-items: center;
  padding: 30px 27px 0 27px;
  margin-bottom: 38px;
  i{
   color: #d8132e;
   font-size: 21px;
   &.add-cart{
    margin-right: 21px;
   }
  }
  .close{
   color: #000000;
   font-size: 15px;
   margin-right: auto;
   transition: .3s;
   cursor: pointer;
   &:hover{
    color: #5f5f5f;
   }
  }
 }

 &__search{
  padding: 0 30px;
  form{
   display: flex;
   align-items: center;
   justify-content: space-between;
   input{
    border: none;
    outline: none;
    padding-left: 50px;
    width: 100%;
    background-color: transparent;
    font-size: 13px;
    font-weight: 400;
    &::placeholder{
     color: #000000;
     opacity: 1;
    }
   }
   button{
    background-color: transparent;
    border: none;
    line-height: 12px;
    i{
     color: #000000;
     font-size: 17px;
     font-weight: 400;
    }
   }
  }
 }

 &__head{
  margin-bottom: 1px;
  background-color: rgba(255,255,255,.97);
  padding-bottom: 21px;
  flex: 1 0 auto;
  max-height: 213px;
 }

 &__body{
  background-color: rgba(255,255,255,.97);
  margin-bottom: 1px;
  overflow: auto;
  flex-grow: 1;
  .mCSB_scrollTools
  {
   .mCSB_dragger
   {
    .mCSB_dragger_bar{
     background-color: #d8132e !important;
     width: 6px;
     border-radius: 0;
    }
   }
  }

  .mCSB_scrollTools {
   .mCSB_draggerRail{
    width: 6px;
    border-radius: 0 !important;
   }
  }

  .mCSB_scrollTools {
   .mCSB_draggerContainer{
    left: -10px;
   }
  }


   ul{
   list-style: none;
   text-align: right;
   margin-bottom: 0;
   padding: 22px 30px 17px 30px;
   > li{
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,.1);
    a{
     cursor: pointer;
     display: flex;
     align-items: center;
     color: #000000;
     font-size: 13px;
     font-weight: 400;
     padding: 14px 0 12px 0;
     line-height: 16px;
     transition: .3s;
     i{
      color: #000000;
      font-size: 17px;
      transition: .3s;
      margin-left: 15px;
     }
    }
    &.menu-item-has-children:after{
     content: '+';
     color: #d8132e;
     border-radius: 100%;
     width: 18px;
     height: 18px;
     border: 2px solid #d8132e;
     display: inline-block;
     text-align: center;
     line-height: 20px;
     position: absolute;
     left: 10px;
     top: 13px;
     -webkit-transition: .3s;
     transition: .3s;
     font-size: 22px;
     font-weight: 400;
    }
    &.active{
     &:after{
      content: '-';
      color: #000000;
      border-color: #000000;
     }
     > a{
      color: #d9132e;
      i{
       color: #d9132e;
      }
     }
    }
    > ul{
     list-style: none;
     margin-top: 9px;
     padding-right: 0;
     > li{
      position: relative;
      list-style: none;
      padding-right: 0;
      border-bottom: none;

      //&:before{
      // content: '+';
      // color: #757579;
      // border-radius: 100%;
      // width: 15px;
      // height: 15px;
      // border: 1px solid #757579;
      // display: inline-block;
      // text-align: center;
      // line-height: 17px;
      // position: absolute;
      // left: 18px;
      // top: 15px;
      // -webkit-transform: translate(0, -52%);
      // transform: translate(0, -52%);
      // -webkit-transition: .3s;
      // transition: .3s;
      // font-size: 18px;
      // font-weight: 100;
      //}
      &.active{
       &:before{
        content: '-';
       }
      }
      > a{
       padding: 0;
       padding-bottom: 13px;
      }
     }
    }
   }
  }
 }

 &__footer{
  background-color: rgba(255,255,255,.97);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 35px;
  flex: 1 0 auto;
  max-height: 80px;
  a{
   margin-left: 20px;
   i{
    color: #828282;
    font-size: 20px;
    transition: .3s;
    cursor: pointer;
    &:hover{
     color: #12b540;
    }
   }
  }
 }

 .logo-response{
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
  i{
   font-size: 45px;
   color: #333333;
  }
 }


 &.active{
  right: 0;
 }

 &__close{
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  i{
   transition: .2s ease-in-out;
   font-size: 14px;
  }
  &:hover{
   i{
    color: #d9132e;
   }
  }
 }
}

.mask-shadow{
 position: fixed;
 width: 100%;
 height: 100%;
 background-color: rgba(255,255,255,1);
 transition: 0.5s ease-in-out;
 visibility: hidden;
 top: 0;
 opacity: 0;
 z-index: 9999999999999;
 filter: blur(5px);

 &.active{
  opacity: .8;
  visibility: visible;
 }
}
