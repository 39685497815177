.cat-one-products{
  $self:&;
  margin-bottom: 42px;
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 33px;
    #{$self}__header-title{
      color: #454a5b;
      font-size: 20px;
      font-weight: 700;
    }
    #{$self}__header-more{
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
      i{
        font-size: 4px;
        color: #454a5b;
        margin-top: 3px;
        margin-right: 9px;
      }
    }
  }
  &__content{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      width: 20px;
      left: -20px;
      box-shadow: 14px 0 14px -9px rgba(0, 0, 0, 0.4);
      z-index: 100;
    }
    #{$self}__item{
      #{$self}__item-wrapper{
        border: 1px solid #dce1ea;
        border-radius: 10px 10px 0 10px;
        padding-top: 50px;
        margin-bottom: 10px;
      }
      #{$self}__item-img{
        margin-bottom: 31px;
        figure{
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      #{$self}__item-title{
        border-bottom: 1px solid #dce1ea;
        padding: 0 16px;
        padding-bottom: 16px;
        h2{
          color: #4d5367;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          line-height: 27px;
        }
      }
      #{$self}__item-buying{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        padding-top: 14px;
        padding-bottom: 15px;
        #{$self}__item-buy{
          display: flex;
          align-items: center;
          .icon{
            position: relative;
            font-size: 18px;
            color: #afb4c5;
            margin-left: 9px;
            i{
              position: absolute;
              font-size: 18px;
              color: #ffffff;
              top: 0;
              left: 0;
            }
          }
          .text{
            font-size: 14px;
            color: #4d5367;
          }
        }
        #{$self}__item-price{
          display: flex;
          align-items: center;
          .price{
            color: #454a5b;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: -0.68px;
            text-decoration: none;
            margin-left: 2px;
          }
          .unit{
            color: #8c919f;
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
      #{$self}__item-footer{
        display: flex;
        align-items: center;
        #{$self}__item-logo{
          margin-left: 10px;
        }
        #{$self}__item-store-name{
          color: #767b8a;
          font-size: 14px;
          font-weight: 700;
          margin-left: 11px;
        }

        #{$self}__item-rate{
          display: flex;
          align-items: center;
          .numb{
            color: #4d5367;
            font-size: 17px;
            font-weight: 700;
            margin-left: 3px;
          }
          i{
            font-size: 11px;
            color: #12b540;
          }
        }
      }
    }
  }
}