.location{
  display: flex;
  margin-top: 70px;
  margin-bottom: 30px;
  $self:&;
  //align-items: center;
  &__map{
    border-radius: 0 15px 15px 0;
    overflow: hidden;
    margin-left: 27px;
  }
  &__items{
    position: relative;
    cursor: pointer;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 100px;
      right: 14px;
      border-left: 1px solid #e1e3ea;
      z-index: 10;
    }
    #{$self}__items-item{
      display: flex;
      margin-bottom: 30px;
      i{
        font-size: 25px;
        color: #454a5b;
        margin-left: 20px;
        padding-top: 4px;
        width: 30px;
        text-align: center;
        transition: .3s;
        &:before{
          position: relative;
          background-color: #fff;
          z-index: 20;
          padding-top: 15px;
          padding-bottom: 15px;
        }
        &.icon-mail{
          font-size: 19px;
        }
      }
      #{$self}__items-info{
        display: flex;
        flex-direction: column;
        #{$self}__items-info-title{
          color: #383c48;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        #{$self}__items-info-value{
          color: #454a5b;
          font-size: 15px;
          font-weight: 700;
          .phone{
            display: flex;
            align-items: first baseline;
            margin-left: 42px;
            &:nth-last-of-type(1){
              margin-left: 0;
            }
            &__numb{
              color: #454a5b;
              font-size: 25px;
              font-weight: 700;
              transition: .3s;
              margin-left: 7px;
            }
            &__pre{
              color: #454a5b;
              font-size: 20px;
              font-weight: 400;
            }
            &:hover{
              .phone__numb{
                color: #12b540;
              }
            }
          }
        }
        .phone-desc{
          color: #454a5b;
          font-size: 14px;
          font-weight: 400
        }
      }
      &--phone{
        #{$self}__items-info{
          #{$self}__items-info-value{
            display: flex;
            align-items: center;
            margin-bottom: 6px;
          }
        }
      }

      &--email{
        #{$self}__items-info{
          #{$self}__items-info-value{
            display: flex;
            align-items: center;
            a{
              color: #454a5b;
              font-size: 15px;
              font-weight: 700;
              margin-left: 28px;
              &:nth-last-of-type(1){
                margin-left: 0;
              }
            }
          }
        }
      }
      &:hover{
        i{
          color: #12b540;
        }
      }
    }
  }
}