.contact-us{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 69px;
  margin-bottom: 92px;
  $self:&;
  &__logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 92px;
    img{
      margin-bottom: 26px;
    }
    i{
      font-size: 24px;
      color: #12b540;
      margin-bottom: 10px;
    }
    span{
      color: #9a9eac;
      font-size: 16px;
      font-weight: 700;
    }
  }
  form{
    .inputs{
      display: flex;
      margin-bottom: 8px;
    }
    #{$self}__inputs{
      max-width: 430px;
      width: 430px;
      margin-left: 34px;
      #{$self}__inputs-item{
        margin-bottom: 25px;
        label{
          color: #454a5b;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 14px;
        }
        input{
          display: block;
          height: 53px;
          background-color: #f3f4f5;
          border-radius: 6px;
          width: 100%;
          padding-right: 20px;
        }
        &:nth-of-type(1){
          label{
            margin-bottom: 11px;
          }
        }
      }
    }
    #{$self}__textarea{
      max-width: 430px;
      width: 430px;
      label{
        color: #454a5b;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 11px;
      }
      textarea{
        display: block;
        background-color: #f3f4f5;
        resize: none;
        width: 100%;
        height: 385px;
        border-radius: 6px;
        padding: 16px 20px;
      }
    }
    #{$self}__btn{
      width: 181px;
      height: 63px;
      box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
      background-color: #12b540;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      padding: 0 23px 0 26px;
      transition: .3s;
      i{
        font-size: 17px;
        color: #ffffff;
        transition: .3s;
        position: relative;
        left: 0;
      }
      &:hover{
        box-shadow: 0 1px 8px rgba(101, 106, 120, 0.35);
        i{
          left: -10px;
        }
      }
    }
  }
}