.header-cart{
  box-shadow: 0 2px 2px rgba(75, 90, 105, 0.17);
  padding-bottom: 32px;
  $self:&;
  #{$self}__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__count{
    display: flex;
    align-items: center;
    #{$self}__count-numb{
      width: 36px;
      height: 26px;
      box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
      background-color: #ef473a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      margin-left: 18px;
    }
    #{$self}__count-title{
      color: #454a5b;
      font-size: 19px;
      font-weight: 700;
    }
  }
  &__breadcrumb{
    direction: ltr;
    display: flex;
    #{$self}__breadcrumb-item{
      color: #848b9f;
      font-size: 15px;
      font-weight: 700;
      margin-right: 20px;
      &:after{
        content: '\e92a';
        font-family: 'icomoon';
        font-size: 3px;
        color: #aab0c2;
        margin-left: 19px;
      }
    }
  }
}