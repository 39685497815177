.cat-wrapper{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 40px;
    width: 30px;
    right: -30px;
    box-shadow: 7px 0 20px -13px rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }
}
.cat-products{
  $self:&;
  margin-bottom: 28px;
  &__item{
    border: 1px solid #dce1ea;
    padding: 33px 14px 17px 16px;
    #{$self}__item-img{
      margin-bottom: 28px;
      figure{
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    #{$self}__item-title{
      margin-bottom: 20px;
      h2{
        color: #4d5367;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        line-height: 30px;
      }
    }
    #{$self}__item-buying{
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dce1ea;
      padding-bottom: 21px;
      margin-bottom: 11px;
      #{$self}__item-buy{
        display: flex;
        align-items: center;
        .icon{
          position: relative;
          font-size: 18px;
          color: #afb4c5;
          margin-left: 9px;
          i{
            position: absolute;
            font-size: 18px;
            color: #ffffff;
            top: 0;
            left: 0;
          }
        }
        .text{
          font-size: 14px;
          color: #4d5367;
        }
      }
      #{$self}__item-price{
        display: flex;
        align-items: center;
        margin-right: auto;
        .price{
          color: #454a5b;
          font-size: 17px;
          font-weight: 700;
          text-decoration: none;
          margin-left: 2px;
          letter-spacing: -0.68px;
        }
        .unit{
          color: #8c919f;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
    #{$self}__item-footer{
      display: flex;
      align-items: center;
      #{$self}__item-store{
        color: #878b9a;
        font-size: 13px;
        font-weight: 700;
      }
      #{$self}__item-rate{
        display: flex;
        align-items: center;
        margin-right: auto;
        .rate{
          color: #878b9a;
          font-size: 17px;
          font-weight: 700;
          margin-left: 3px;
        }
        i{
          font-size: 11px;
          color: #878b9a;
        }
      }
    }
    &--discount{
      position: relative;
      .offer{
        position: absolute;
        font-size: 14px;
        color: #454a5b;
        top: 14px;
        left: 14px;
      }
      #{$self}__item-buying{
        padding-bottom: 4px;
        #{$self}__item-price{
          flex-wrap: wrap;
          justify-content: flex-end;
          .discount{
            background-color: #ef473a;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 17px;
            border-radius: 0 60px 60px 60px;
            padding: 0 7px 0 6px;
            flex: 0 1 36%;
            &__numb{
              color: #ffffff;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: -0.72px;
              margin-left: 2px;
            }
            i{
              font-size: 10px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  [class*=col]:nth-of-type(4n){
    #{$self}__item{
      border-left: none;
    }
  }
  [class*=col]:nth-of-type(4n + 1){
    #{$self}__item{
      border-right: none;
    }
  }
}