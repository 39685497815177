.login{
  margin: 0 auto;
  max-width: 523px;
  width: 100%;
  padding-top: 102px;
  padding-bottom: 100px;
  $self:&;
  &__header{
    display: flex;
    justify-content: center;
    margin-bottom: 71px;
    a{
      display: flex;
      flex-direction: column;
      .logo{
        font-size: 69px;
        color: #12b540;
        margin-bottom: 10px;
      }
      .logo-text{
        font-size: 22px;
        color: #454a5b;
      }
    }
  }
  &__form{
    display: flex;
    flex-direction: column;
    background-color: #f7f8f9;
    padding: 63px 46px 50px 46px;
    border-radius: 15px;
    margin-bottom: 116px;
    box-shadow: -17px -17px 17px 0 rgba(255, 255, 255, 0.5), 25px 25px 17px -20px rgba(0, 0, 0, 0.03);
    #{$self}__form-label{
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    input{
      height: 54px;
      background-color: #eef0f2;
      width: 100%;
      color: #454a5b;
      font-size: 16px;
      font-weight: 400;
      border-radius: 8px;
      margin-bottom: 23px;
      padding-right: 20px;
    }
    #{$self}__form-btn{
      display: flex;
      align-items: center;
      margin-bottom: 37px;
      margin-top: 4px;
      button{
        height: 54px;
        background-color: #12b540;
        border-radius: 10px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        margin-left: 10px;
        padding: 0 20px 0 22px;
        i{
          font-size: 14px;
          color: #ffffff;
        }
      }
      #{$self}__form-register{
        width: 140px;
        height: 54px;
        box-shadow: 0 2px 3px rgba(69, 74, 91, 0.11);
        background-color: #fff;
        border-radius: 10px;
        color: #454a5b;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    #{$self}__form-remember{
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
  }
  &__back-home{
    color: #454a5b;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-size: 13px;
      color: #454a5b;
      margin-right: 14px;
    }
  }
}
.login-wrapper{
  background-color: #f7f8f9;
  background-image: url('../img/shop-login.svg');
  background-repeat: no-repeat;
  background-position: 102% center;
}