@media (max-width: 576px) {
  .top-sellers{
    $self:&;
    &--cat-one{
      margin-top: 25px;
      margin-bottom: 35px;
      #{$self}__header{
        flex-wrap: wrap;
        margin-bottom: 15px;
        h2{
          order: 0;
        }
        #{$self}__header-cat{
          width: 100%;
          margin-right: 0;
          order: 1;
          margin-top: 10px;
        }
        #{$self}__header-link{
          order: 0;
        }
      }
    }
  }
  .cat-one-products{
    margin-bottom: 25px;
    &__header{
      margin-bottom: 15px;
    }
  }
  .catone-slider{
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .cat-one-img{
    margin-bottom: 20px;
    &__item{
      margin-bottom: 10px;
    }
  }
  .cat-one-banner {
    margin-top: 30px;
    margin-bottom: 18px;
  }
  .cat-one-exclusive{
    padding: 15px 15px 5px 15px;
    margin-bottom: 20px;
    &__header{
      margin-bottom: 10px;
    }
    p{
      line-height: 30px;
    }
  }
}