.product{
  $self:&;
  &__nav{
    width: 47px;
    height: 110px;
    background-color: #12b540;
    border-radius: 60px;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 20;
    transition: .3s;
    cursor: pointer;
    &--next{
      left: -77px;
      .arrow{
        position: absolute;
        top: 50%;
        left: 59%;
        transform: translate(-50%,-50%);
        &__line{
          position: absolute;
          width: 16px;
          height: 2.5px;
          border-radius: 60px;
          background-color: #fff;
          left: -12px;
          transition: .3s;
        }
        &:after{
          content: '';
          position: absolute;
          width: 13px;
          border-radius: 60px;
          height: 2.5px;
          transform-origin: 0 50%;
          background-color: #fff;
          display: inline-block;
          transform: rotate(45deg);
          top: -1px;
          transition: .3s;
        }
        &:before{
          content: '';
          position: absolute;
          width: 13px;
          border-radius: 60px;
          height: 2.5px;
          transform-origin: 0 50%;
          background-color: #fff;
          display: inline-block;
          transform: rotate(-45deg);
          top: 1px;
          transition: .3s;
        }
      }
      &.swiper-button-disabled{
        background-color: #f3f5f9;
        .arrow{
          &__line{
            background-color: #bcc6d9;
            width: 6px;
            height: 6px;
            left: -8px;
          }
          &:after{
            transform: rotate(0);
            opacity: 0;
          }
          &:before{
            transform: rotate(0);
            opacity: 0;
          }
        }
      }
    }

    &--prev{
      right: -77px;
      .arrow{
        position: absolute;
        top: 50%;
        left: 41%;
        transform: translate(-50%,-50%) rotateY(180deg);
        display: inline-block;
        &__line{
          position: absolute;
          width: 16px;
          height: 2.5px;
          border-radius: 60px;
          background-color: #fff;
          left: -12px;
          transition: .3s;
        }
        &:after{
          content: '';
          position: absolute;
          width: 13px;
          border-radius: 60px;
          height: 2.5px;
          transform-origin: 0 50%;
          background-color: #fff;
          display: inline-block;
          transform: rotate(45deg);
          top: -1px;
          transition: .3s;
        }
        &:before{
          content: '';
          position: absolute;
          width: 13px;
          border-radius: 60px;
          height: 2.5px;
          transform-origin: 0 50%;
          background-color: #fff;
          display: inline-block;
          transform: rotate(-45deg);
          top: 1px;
          transition: .3s;
        }
      }
      &.swiper-button-disabled{
        background-color: #f3f5f9;
        .arrow{
          &__line{
            background-color: #bcc6d9;
            width: 6px;
            height: 6px;
            left: -8px;
          }
          &:after{
            transform: rotate(0);
            opacity: 0;
          }
          &:before{
            transform: rotate(0);
            opacity: 0;
          }
        }
      }
    }
  }
  .container{
    position: relative;
  }
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 49px;
    h2{
      color: #454a5b;
      font-size: 20px;
      font-weight: 700;
      margin-left: 30px;
    }
    #{$self}__header-cat{
      display: flex;
      align-items: center;
      #{$self}__header-cat-item{
        color: #787d90;
        font-size: 15px;
        font-weight: 700;
        height: 38px;
        box-shadow: none;
        display: flex;
        align-items: center;
        border-radius: 60px;
        padding: 0 28px 0 27px;
        transition: .3s;
        margin-left: 7px;
        &:nth-last-of-type(1){
          margin-left: 0;
        }
        &:hover{
          background-color: #12b540;
          box-shadow: 0 2px 2px rgba(139, 144, 159, 0.15);
          color: #ffffff;
        }
      }
    }
    #{$self}__header-more{
      display: flex;
      align-items: center;
      margin-right: auto;
      color: #14a13b;
      font-size: 15px;
      font-weight: 700;
      i{
        font-size: 4px;
        color: #14a13b;
        margin-top: 3px;
        margin-right: 19px;
      }
    }
  }
  &__content{
    padding: 40px;
    margin: -40px;

    .swiper-slide {
      transition: .1s;
      opacity: 0;
      &.swiper-slide-active{
        opacity: 1;
        + .swiper-slide{
          opacity: 1;
          + .swiper-slide{
            opacity: 1;
            + .swiper-slide{
              opacity: 1;
            }
          }
        }
      }
    }
    #{$self}__item{
      box-shadow: 0 10px 28px 0 rgba(0, 0, 0, 0.1);
      padding: 23px 24px 16px 25px;
      overflow: hidden;
      border-radius: 8px;
      #{$self}__item-unavailable{
        text-align: center;
        position: absolute;
        top: 72px;
        left: 26px;
        width: 82px;
        height: 21px;
        background-color: #b6bbcb;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;
        border-radius: 0 60px 60px 60px;
      }
      #{$self}__item-new{
        position: absolute;
        border-radius: 0 60px 60px 60px;
        top: 72px;
        left: 26px;
        width: 42px;
        height: 21px;
        background-color: #12b540;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;
        text-align: center;
      }
      #{$self}__item-header{
        border-bottom: 1px solid #e8eaf1;
        padding-bottom: 21px;
        h2{
          color: #4d5367;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
        }
      }
      #{$self}__item-img{
        position: relative;
        figure{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 207px;
          padding: 5px;
        }
        #{$self}__item-img-gallery{
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 13px;
          z-index: 100;
          .item{
            width: 33px;
            height: 33px;
            box-shadow: 0 5px 5px rgba(56, 60, 72, 0.13);
            background-color: #ffffff;
            border-radius: 5px;
            transition: .5s;
            margin-bottom: 10px;
            border: 1px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            visibility: hidden;
            opacity: 0;
            &:nth-of-type(1){
              transition-delay: .1s;
            }
            &:nth-of-type(2){
              transition-delay: .2s;
            }
            &:nth-of-type(3){
              transition-delay: .3s;
            }
            &:nth-of-type(4){
              transition-delay: .4s;
            }
            &:nth-of-type(5){
              transition-delay: .5s;
            }
            &:hover{
              box-shadow: none;
              border: 1px solid #e8eaf1;
            }
          }
        }
      }
      #{$self}__item-footer{
        #{$self}__item-footer-buy{
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e8eaf1;
          padding-bottom: 16px;
          margin-bottom: 10px;
          #{$self}__item-footer-buying{
            display: flex;
            align-items: center;
            i{
              color: #4d5367;
              &.pluse{
                font-size: 9px;
                margin-left: 5px;
              }
              &.buy-product{
                font-size: 15px;
              }
            }
          }
          #{$self}__item-footer-price{
            display: flex;
            align-items: center;
            margin-right: auto;
            position: relative;
            .discount{
              position: absolute;
              left: 0;
              top: -15px;
              width: 42px;
              height: 17px;
              background-color: #ef473a;
              border-radius: 60px 60px 60px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: -0.72px;
              i{
                font-size: 10px;
                color: #ffffff;
                margin-right: 2px;
              }
            }
            ins{
              text-decoration: none;
              color: #12b540;
              font-size: 20px;
              font-weight: 700;
              letter-spacing: -0.8px;
              margin-left: 3px;
            }
            .unit{
              color: #12b540;
              font-size: 14px;
              font-weight: 700;
            }
          }
        }
        #{$self}__item-footer-info{
          display: flex;
          align-items: center;
          #{$self}__item-footer-title{
            color: #878c9b;
            font-size: 14px;
            font-weight: 700;
            margin-left: 10px;
          }
          #{$self}__item-footer-logo{
            margin-left: 9px;
          }
          #{$self}__item-footer-name{
            color: #4d5367;
            font-size: 14px;
            font-weight: 700;
          }
          #{$self}__item-footer-rate{
            display: flex;
            align-items: baseline;
            margin-right: auto;
            i{
              font-size: 11px;
              color: #b2b5c3;
              position: relative;
              bottom: 1px;
            }
            .rate{
              color: #4d5367;
              font-size: 17px;
              font-weight: 700;
              margin-left: 3px;
            }
          }
        }
      }
      &--offer{
        #{$self}__item-footer{
          position: relative;
          #{$self}__item-footer-buy{
            border-bottom: none;
          }
          #{$self}__item-footer-info{
            #{$self}__item-footer-title{
              display: none;
            }
            #{$self}__item-footer-rate{
              display: none;
            }
          }
          #{$self}__item-footer-offer{
            position: absolute;
            left: 2px;
            bottom: 0;
            text-align: left;
            width: 90px;
            border-radius: 0 17px 0 0;
            height: 49px;
            top: 50px;
            overflow: hidden;
            padding-top: 6px;
            transform: skew(40deg);
            &:after{
              content: '';
              position: absolute;
              width: 50px;
              height: 50px;
              transform-origin: 0 50%;
              box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.8);
              right: -32px;
              border-radius: 60px;
              top: -54px;
              transform: skew(-30deg);
            }
            i{
              font-size: 13px;
              color: #ef473a;
              transform: skew(-40deg);
              display: inline-block;
              position: relative;
              left: 4px;
              top: 3px;
            }
          }
        }
      }
      &--unavailable{
        position: relative;
        #{$self}__item-footer{
          #{$self}__item-footer-buy{
              #{$self}__item-footer-price{
                filter: blur(3px);
                ins{
                  color: #b6bbcb;
                }

                .unit{
                  color: #b6bbcb;
                }
              }
          }
        }
      }
      &--discount{
        #{$self}__item-footer{
          #{$self}__item-footer-buy{
            #{$self}__item-footer-price{
              ins{
                color: #ef473a;
              }

              .unit{
                color: #ef473a;
              }
            }
          }
        }
      }

      &:hover{
        #{$self}__item-img{
          #{$self}__item-img-gallery{
            .item{
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &--other{
    #{$self}__item{
      box-shadow: 0 2px 2px rgba(77, 83, 103, 0.19);
      background-color: #fff;
    }
  }
}