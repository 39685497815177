.product-content{
  $self:&;
  margin-bottom: 67px;
  &__nav{
    height: 77px;
    background-color: #f5f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-bottom: 40px;
    #{$self}__nav-item{
      color: #8c91a0;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-left: 100px;
      cursor: pointer;
      transition: .3s;
      i{
        color: #8c91a0;
        font-size: 19px;
        margin-left: 14px;
        transition: .3s;
        &.icon-cube{
          font-size: 21px;
        }
        &.icon-user{
          margin-left: 17px;
        }
      }
      .count{
        margin-right: 8px;
      }
      &:nth-last-of-type(1){
        margin-left: 0;
      }
      &:hover{
        color: #383c48;
        i{
          color: #383c48;
        }
      }
      &.active{
        color: #383c48;
        i{
          color: #383c48;
        }
      }
    }
  }
  &__contents{
    #{$self}__contents-content{
      [data-target]{
        display: none;
        &:nth-of-type(1){
          display: block;
        }
      }
      h3{
        color: #383c48;
        font-size: 19px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        cursor: pointer;
        position: relative;
        &:before{
          content: '';
          width: 12px;
          height: 2px;
          background-color: #aeb9c5;
          margin-left: 18px;
          margin-top: 3px;
        }
        &:after{
          content: '';
          width: 2px;
          height: 0;
          background-color: #aeb9c5;
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(0,-40%);
          transition: .3s;
        }
        &.active{
          &:after{
            height: 12px;
          }
        }
      }
      figure{
        margin-bottom: 14px;
        margin-top: 14px;
      }
      p{
        color: #383c48;
        font-size: 14px;
        font-weight: 500;
        line-height: 41px;
        text-align: justify;
        margin-bottom: 33px;
      }
    }
  }
  .features{
    margin-bottom: 41px;
    h2{
      color: #383c48;
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    table{
      width: 100%;
      td{
        padding: 19px 0;
      }
    }
    &__key{
      color: #848a9e;
      font-size: 13px;
      font-weight: 500;
      max-width: 173px;
      width: 173px;
      //margin-left: 46px;
      border-bottom: 1px solid #dde0e9;
      border-left: 46px solid #fff;
    }
    &__value{
      color: #383c48;
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid #dde0e9;
    }
  }
}