@media (max-width: 1200px) {
  .not-found{
    background-image: none;
  }
}

@media (max-width: 576px) {
  .not-found{
    $self:&;
    padding: 0 15px;
    padding-bottom: 60px;
    padding-top: 20px;
    &__title{
      margin-bottom: 10px;
      text-align: center;
    }
    &__redirect{
      margin-bottom: 50px;
      text-align: center;
    }
    &__img{
      margin-bottom: 30px;
    }
    &__footer{
      #{$self}__footer-item{
        width: 90px;
        height: 85px;
        margin-left: 15px;
        i{
          margin-bottom: 10px;
        }
      }
    }
    &__ivahid{
      bottom: 10px;
      right: 38px;
      width: 100%;
    }
  }
}