@media (max-width: 992px) {
  .site-header.page {
    margin-bottom: 25px;
  }
  .header-page{
    padding-bottom: 25px;
    figure{
      display: none;
    }
  }
  .location{
   flex-direction: column;
   align-items: center;
    &__map{
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
  .contact-us{
    flex-wrap: wrap;

    $self:&;
    &__logo{
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
    form{
      .inputs{
        flex-wrap: wrap;
      }
      #{$self}__inputs{
        max-width: 100%;
        width: 100%;
        margin-left: 0;
      }
      #{$self}__textarea{
        max-width: 100%;
        width: 100%;

      }
    }
  }
}

@media (max-width: 576px) {
  .location{
    $self:&;
    margin-top: 15px;
    margin-bottom: 5px;
    &__items {
      &:before{
        display: none;
      }
      #{$self}__items-item{
        flex-wrap: wrap;
        margin-bottom: 15px;
        i{
          margin-right: -47px;
          margin-left: 5px;
        }
        #{$self}__items-info{
          #{$self}__items-info-title{
            margin-bottom: 5px;
          }
        }
      }

    }
  }
  .site-header.page {
    margin-bottom: 15px;
  }
  .header-page{
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .contact-us{
    margin-top: 15px;
    margin-bottom: 15px;
    $self:&;
    &__logo{
      margin-bottom: 10px;
    }
    form {
      #{$self}__inputs {
        #{$self}__inputs-item{
          margin-bottom: 10px;
        }
      }
    }
  }
  .page-content {
    margin-bottom: 10px;
  }
}