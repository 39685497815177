.cart-info{
  $self:&;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding: 38px 0 41px 0;
  margin-bottom: 32px;
  &__wrapper{
    display: flex;
    align-items: center;
    #{$self}__item{
      display: flex;
      align-items: center;
      margin-left: 70px;
      #{$self}__item-title{
        color: #999ca7;
        font-size: 16px;
        font-weight: 700;
        margin-left: 8px;
      }
      #{$self}__item-value{
        display: flex;
        align-items: center;
        ins{
          color: #454a5b;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.72px;
          text-decoration: none;
          margin-left: 3px;
        }
        .unit{
          color: #8c919f;
          font-size: 13px;
          font-weight: 700;
        }
      }
      &:nth-last-of-type(1){
        margin-left: 0;
      }
    }
    #{$self}__continue{
      color: #454a5b;
      font-size: 19px;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-right: auto;
      i{
        font-size: 17px;
        color: #12b540;
        margin-right: 27px;
      }
    }
  }
}