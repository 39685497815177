.pagination{
  $self:&;
  margin-right: -15px;
  &__items{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    #{$self}__items-item{
      color: #9499ab;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: -0.68px;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 19px;
      transition: .3s;
      margin-bottom: 15px;
      &:hover{
        color: #12b540;
      }
      &.active{
        background-color: #12b540;
        color: #fff;
      }
      &--next{
        i{
          font-size: 13px;
          color: #9499ab;
          transition: .3s;
        }
        &:hover{
          i{
            color: #12b540;
          }
        }
      }
    }
  }
}