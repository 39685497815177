.top-cat{
  $self:&;
  background-color: #f3f5f9;
  background-image: linear-gradient(to top, #ffffff 0%, #f3f5f9 19%, #f3f5f9 77%, #ffffff 100%);
  padding-top: 99px;
  padding-bottom: 99px;
  &__item{
    background-color: #f3f5f9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    padding: 25px 31px 25px 31px;
    position: relative;
    box-shadow: -7px -8px 20px -5px rgba(255, 255, 255, 0.9) , 4px 6px 20px -10px rgba(0, 0, 0, 0.15);
    #{$self}__item-meta{
      color: #515667;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 1px;
    }

    #{$self}__item-title{
      color: #12b540;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 17px;
    }

    #{$self}__item-link{
      width: 105px;
      height: 30px;
      box-shadow: inset 0 2px 4px rgba(77, 83, 103, 0.11);
      background-color: #12b540;
      color: #ffffff;
      font-size: 15px;
      font-weight: 700;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 13px 0 12px;
      i{
        font-size: 11px;
        color: #ffffff;
      }
    }

    #{$self}__item-image{
      position: absolute;
      &--first{
        top: -16px;
        left: 24px;
      }
      &--second{
        top: -7px;
        left: 28px;
      }
      &--third{
        top: -22px;
        left: 37px;
      }
    }

  }
}
