.not-found{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 50px;
  background-image: url("../img/bg-404.svg");
  background-repeat: no-repeat;
  background-position: left center;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    height: 150px;
    width: 400px;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    background-image: linear-gradient(to right , #fff,transparent);
  }
  $self:&;
  &__title{
    color: #454a5b;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 21px;
  }
  &__img{
    margin-bottom: 112px;
  }
  &__redirect{
    color: #454a5b;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 117px;
    span{
      color: #ea3333;
      font-size: 25px;
      font-weight: 700;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
  &__footer{
    display: flex;
    #{$self}__footer-item{
      width: 104px;
      height: 113px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      box-shadow: 0 10px 21px -10px rgba(0, 0, 0, 0.2);
      margin-left: 30px;
      transition: .3s;
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      i{
        font-size: 21px;
        color: #454a5b;
        margin-bottom: 28px;
      }
      &:nth-last-of-type(1){
        margin-left: 0;
      }
      &:hover{
        background-color: #f8f8f9;
        box-shadow: none;
      }
    }
  }
  &__ivahid{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 53px;
    right: 200px;
    #{$self}__ivahid-desc{
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      #{$self}__ivahid-small{
        color: #adb1c0;
        font-size: 11px;
        font-weight: 400;
      }
      #{$self}__ivahid-big{
        color: #adb1c0;
        font-size: 13px;
        font-weight: 400;
        margin-top: -1px;
      }
    }

  }
}