@media (max-width: 576px) {
  .login{
    $self:&;
    &__code{
      margin-bottom: 20px;
      #{$self}__code-inputs{
        flex-wrap: wrap;
        margin-bottom: 10px;
        input{
          width: 50%;
          max-width: initial;
          margin-bottom: 8px;
          margin-left: 4px;
          &:nth-last-of-type(1){
            margin-left: 4px !important;
          }
        }
      }
      #{$self}__code-footer{
        flex-wrap: wrap;
        #{$self}__code-btn{
          width: 100%;
          margin-left: 0;
          margin-bottom: 10px;
        }
        #{$self}__code-resend{
          width: 100%;
        }
      }
    }
  }
}