.login{
  $self:&;
  padding-top: 102px;
  padding-bottom: 100px;
  position: relative;
  &__code{
    max-width: 523px;
    width: 100%;
    background-color: #f7f8f9;
    box-shadow: -17px -17px 17px 0 rgba(255, 255, 255, 0.5), 25px 25px 17px -20px rgba(0, 0, 0, 0.03);
    padding: 51px 47px 43px 46px;
    border-radius: 15px;
    margin-bottom: 116px;
    #{$self}__code-second{
      width: 155px;
      height: 155px;
      box-shadow: inset 0 5px 6px rgba(69, 74, 91, 0.08);
      background-color: #f7f8f9;
      border-radius: 100%;
      margin: 0 auto;
      margin-bottom: 13px;
      position: relative;
      #{$self}__code-count-down{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #707f8e;
        font-size: 46px;
        font-weight: 700;
        letter-spacing: -2.1px;
      }
      svg {
        position: absolute;
        top: 60px;
        right: 57px;
        width: 40px;
        height: 40px;
        transform: rotateZ(-90deg) scale(2.95);
      }

      svg circle {
        stroke-dasharray: 113px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 2px;
        stroke: #12b540;
        fill: none;
        animation: countdown 60s linear forwards;
      }

      @keyframes countdown {
        from {
          stroke-dashoffset: 0px;
        }
        to {
          stroke-dashoffset: 113px;
        }
      }
    }
    #{$self}__code-phone{
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 25px;
      display: inline-block;
    }
    #{$self}__code-inputs{
      display: flex;
      align-items: center;
      margin-bottom: 27px;
      input{
        height: 54px;
        background-color: #eef0f2;
        border-radius: 6px;
        display: flex;
        text-align: center;
        justify-content: center;
        flex: 1;
        margin-left: 15px;
        max-width: 95px;
        &:nth-last-of-type(1){
          margin-left: 0;
        }
      }

    }
    #{$self}__code-footer{
      display: flex;
      align-items: center;
      #{$self}__code-btn{
        width: 280px;
        height: 54px;
        background-color: #12b540;
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 19px 0 22px;
        i{
          font-size: 14px;
          color: #ffffff;
        }
      }
      #{$self}__code-resend{
        width: 140px;
        height: 54px;
        box-shadow: 0 2px 3px rgba(69, 74, 91, 0.11);
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #454a5b;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  &__logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: -350px;
    top: 50%;
    transform: translate(0,-50%);
    i{
      &.logo{
        font-size: 76px;
        color: #12b540;
        margin-bottom: 17px;
      }
      &.text{
        font-size: 16px;
        color: #454a5b;
      }
    }
  }
}