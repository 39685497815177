@media (max-width: 768px) {
  .login-wrapper{
    background-image: none;
  }
}

@media (max-width: 576px) {
  .login-wrapper{
    padding: 0 15px;
  }
  .login{
    $self:&;
    padding-top: 40px;
    padding-bottom: 50px;
    &__form{
      padding: 20px 15px 20px 15px;
      margin-bottom: 20px;
      #{$self}__form-btn{
        flex-wrap: wrap;
        margin-bottom: 15px;
        button{
          width: 100%;
          margin-left: 0;
          margin-bottom: 10px;
        }
        #{$self}__form-register{
          width: 100%;
        }
      }
      #{$self}__form-label{
        font-size: 14px;
      }
      input{
        margin-bottom: 10px;
      }
    }
    &__header{
      margin-bottom: 15px;
    }
  }
}
