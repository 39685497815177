body{
  text-align: right;
  direction: rtl;
  font-family: iranyekan;
}

.pl20{
  padding-left: 20px;
}

.pr11{
  padding-right: 11px;
}

.p30{
  margin: 0 -15px;
  > [class*=col-]{
    padding: 0 15px;
  }
}

.pr30{
  padding-right: 30px;
}

.pl39{
  padding-left: 39px;
}

.wrapper{
  overflow: hidden;
}

.cart-wrapper{
  background-color: #f7f8fb;
  padding-top: 22px;
}