.site-header {
 padding-top: 45px;
 //overflow: hidden;
 margin-bottom: 73px;
 position: relative;
 $self: &;

 .response-head {
  //display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
  padding: 20px 0;
  display: none;

  &__menu {
   font-size: 20px;
   color: #949494;
  }
 }

 &__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
 }

 &__right {
  display: flex;
  align-items: center;

  #{$self}__logo {
   margin-left: 24px;
  }

  #{$self}__location {
   display: flex;
   align-items: center;
   border: 1px solid #e7e8f2;
   border-radius: 5px;
   padding: 0 15px 0 17px;
   height: 36px;
   cursor: pointer;

   i {
    font-size: 18px;
    color: #4d5367;
    margin-left: 13px;
   }

   span {
    color: #4d5367;
    font-size: 15px;
    font-weight: 700;
   }
  }
 }

 &__left {
  margin-right: auto;
  display: flex;
  align-items: center;

  #{$self}__search {
   display: flex;
   align-items: center;
   margin-left: 56px;
   border: 1px solid #e5e7f1;
   border-radius: 5px;
   padding: 10px;

   input {
    width: 274px;
    color: #4d5367;
    font-size: 15px;
    font-weight: 700;
    margin-left: 20px;

    &::placeholder {
     color: #4d5367;
     font-size: 15px;
     font-weight: 700;
     opacity: .8;
    }
   }

   #{$self}__search-btn {
    padding: 0;
    border: none;
    background-color: transparent;

    i {
     font-size: 17px;
     color: #5d6273;
     position: relative;
     top: 2px;
    }
   }
  }

  #{$self}__cart {
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 60px;
   width: 50px;
   height: 50px;
   margin-left: 55px;
   background-color: #0661f8;
   cursor: pointer;
   position: relative;

   i {
    font-size: 18px;
    color: #ffffff;
   }

   #{$self}__cart-items {
    top: 100%;
    width: 360px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
    box-shadow: 0 25px 25px 0 rgba(113, 124, 137, .25);
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: .3s;

    #{$self}__cart-item {
     .cart__wrapper {
      display: flex;
      align-items: center;
      padding: 15px;

      > a {
       flex: 0 1 auto;
       margin-left: 20px;

       img {
        width: 60px;
       }
      }

      .info {
       display: flex;
       flex-direction: column;

       &__title {
        font-size: 14px;
        font-weight: 400;
        color: #383c48;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        max-width: 240px;
       }

       &__meta {
        display: flex;
        align-items: center;

        .item {
         display: flex;
         align-items: center;
         font-size: 13px;
         color: #949494;
         margin-left: 20px;

         &:nth-last-of-type(1) {
          margin-left: 0;
         }
        }
       }

       .recycle-bin {
        cursor: pointer;
        display: flex;
        margin-right: auto;

        i {
         font-size: 17px;
         color: #838c95;
        }
       }
      }
     }

     .count-product {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      color: #838c95;
      font-size: 13px;
      background-color: #f5f7f8;
      margin-bottom: 15px;
      position: relative;

      &__counter {
       display: flex;
       align-items: center;
       margin-left: 15px;
       margin-right: auto;

       i {
        font-size: 18px;
        color: #838c95;
       }
      }

      &__counter-item {
       display: flex;
      }

      &__counter-numb {
       margin-left: 5px;
       margin-right: 5px;
       font-size: 16px;
      }
     }

     .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      margin-bottom: 20px;

      &__show {
       font-size: 13px;
       font-weight: 400;
       color: #949494;
      }

      &__complete {
       font-size: 15px;
       font-weight: 400;
       color: #0661f8;
      }
     }
    }

    &.active {
     opacity: 1;
     visibility: visible;
    }
   }
  }

  #{$self}__left-item {
   display: flex;
   align-items: center;
   cursor: pointer;

   span {
    color: #5d6273;
    font-size: 15px;
    font-weight: 700;
   }

   &--user-plus {
    margin-left: 15px;
    i {
     font-size: 21px;
     color: #12b540;
     margin-right: 15px;
    }
   }

   &--cat {
    cursor: pointer;

    span {
     transition: .3s;
    }

    i {
     font-size: 15px;
     color: #5d6273;
     margin-right: 20px;
     transition: .3s;
    }

    &.active {
     span {
      color: #0661f8;
     }

     i {
      color: #0661f8;
     }
    }
   }
  }
 }

 &__momentary-products {
  position: relative;

  &:before {
   content: '';
   position: absolute;
   top: 0;
   right: -60px;
   left: -60px;
   height: 40px;
   box-shadow: 0 -14px 26px -19px rgba(0, 0, 0, 0.4);
   pointer-events: none;
  }

  .swiper-container {
   padding-top: 17px;
   padding-bottom: 25px;

   &:before {
    content: '';
    position: absolute;
    right: -30px;
    top: 0;
    bottom: 0;
    width: 30px;
    box-shadow: -7px -5px 20px -11px rgba(0, 0, 0, 0.4);
   }

   &:after {
    content: '';
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0;
    width: 30px;
    box-shadow: 7px -5px 20px -11px rgba(0, 0, 0, 0.4);
   }
  }

  #{$self}__momentary-products-item {
   display: flex;
   align-items: center;

   &:after {
    content: '';
    display: inline-block;
    border-left: 1px solid #e5e7f1;
    height: 20px;
    position: relative;
    top: 3px;
   }

   .title {
    color: #383c48;
    font-size: 13px;
    font-weight: 700;
    margin-left: 27px;
   }

   .price {
    display: flex;
    align-items: center;
    margin-left: 19px;

    &__val {
     color: #4d5367;
     font-size: 15px;
     font-weight: 500;
     letter-spacing: -0.6px;
     text-decoration: none;
     margin-left: 3px;
    }

    &__unit {
     color: #676d80;
     font-size: 12px;
     font-weight: 500;
     letter-spacing: -0.48px;
    }
   }
  }

  #{$self}__momentary-products-title {
   padding-top: 21px;
   display: flex;
   justify-content: flex-end;
   height: 100%;

   i {
    font-size: 17px;
    color: #12b540;
    position: relative;
    top: 2px;
   }
  }
 }

 &--single {
  margin-bottom: 35px;
 }

 &--sec-header {
  border-bottom: 1px solid #eceff1;
  margin-bottom: 32px;

  #{$self}__momentary-products {
   border-top: 1px solid #eceff1;

   &:before {
    display: none;
   }

   .swiper-container {
    &:before {
     content: '';
     position: absolute;
     right: 0;
     top: 0;
     bottom: 0;
     width: 80px;
     box-shadow: none;
     background-image: linear-gradient(to left, #fff, transparent);
     z-index: 10;
     pointer-events: none;
    }

    &:after {
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     bottom: 0;
     width: 80px;
     box-shadow: none;
     background-image: linear-gradient(to right, #fff, transparent);
     z-index: 10;
     pointer-events: none;
    }
   }
  }
 }

 &.page {
  margin-bottom: 79px;
 }

 &.cat-filter {
  margin-bottom: 28px;
 }

 &__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer;

  ul {
   display: flex;

   li {
    margin-right: 30px;

    &:first-of-type {
     margin-right: 0;
    }
    &.active {
     span {
      color: #0661f8;
     }
     i {
      color: #0661f8;
     }
    }
   }
  }
 }
}

.mega-menu-site-responsive__body {
 ul {
  > .has-childe {
   padding-bottom: 13px;
  }
 }

 .has-childe {
  margin-top: 13px;

  > ul {
   padding-left: 0;
   padding-top: 0;
  }

  ul {
   display: none;
  }

  .has-childe {
   padding-right: 15px;

   > span {
    padding-bottom: 10px;
   }
  }

  > span {
   color: #000000;
   font-size: 13px;
   font-weight: 400;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;

   &:after {
    content: '\e90e';
    font-family: 'icomoon';
    font-size: 8px;
    color: #000000;
    transition: .3s;
    display: inline-block;
    transform: rotate(0);
   }
  }

  &.active {
   > span {
    &:after {
     transform: rotate(180deg);
    }
   }
  }
 }
}
