/*
██╗██╗   ██╗ █████╗ ██╗  ██╗██╗██████╗
██║██║   ██║██╔══██╗██║  ██║██║██╔══██╗
██║██║   ██║███████║███████║██║██║  ██║
██║╚██╗ ██╔╝██╔══██║██╔══██║██║██║  ██║
██║ ╚████╔╝ ██║  ██║██║  ██║██║██████╔╝
╚═╝  ╚═══╝  ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═════╝
*/
@import './fonts';
@import './reset';

/* libraries */
@import "../../../node_modules/swiper/css/swiper.min.css";
@import "../../../node_modules/selectize/dist/css/selectize.css";
@import "../../../node_modules/aos/dist/aos.css";
@import "../../../node_modules/remodal/dist/remodal.css";
@import "jquery.mCustomScrollbar.min.css";

/* header */
@import 'header.scss';

/* megamenu */
@import "megamenu";

/* index */
@import "index/main-info.scss";
@import "index/popular-cat.scss";
@import "index/new-product.scss";
@import "index/top-cat.scss";
@import "index/top-seller.scss";
@import "index/roadmap.scss";
@import "index/amazing-offers.scss";
@import "index/banners.scss";

/* single */
@import 'single/breadcrumb.scss';
@import 'single/seller-head.scss';
@import 'single/product-info.scss';
@import 'single/seller-info.scss';
@import 'single/product-content.scss';
@import "single/other-products.scss";
@import "single/comments.scss";

/* page */
@import "page/header.scss";
@import "page/page-content.scss";
@import "page/location.scss";
@import "page/contact-us.scss";

/* cart */
@import "cart/header.scss";
@import "cart/cart-info.scss";
@import "cart/cart-items.scss";
@import "cart/cart-footer.scss";
@import "cart/cart-address.scss";
@import "cart/cart-method.scss";
@import "cart/cart-success.scss";

/* cat-filter */
@import "cat-filter/header.scss";
@import "cat-filter/filter.scss";
@import "cat-filter/filters-summery.scss";
@import "cat-filter/cat-products.scss";
@import "cat-filter/pagination.scss";

/* cat-one */
@import "cat-one/slider.scss";
@import "cat-one/cat-one-img.scss";
@import "cat-one/cat-one-products.scss";
@import "cat-one/cat-one-banner.scss";
@import "cat-one/top-seller.scss";
@import "cat-one/cat-one-exclusive.scss";

/* login */
@import "login/login.scss";
@import "login/login-code.scss";

/* 404 */
@import "404/404.scss";

/* responsive */
@import "megamenu-responsive.scss";
@import "reponsive/general.scss";
@import "reponsive/index.scss";
@import "reponsive/single.scss";
@import "reponsive/cat-filter.scss";
@import "reponsive/cat-one.scss";
@import "reponsive/cart.scss";
@import "reponsive/404.scss";
@import "reponsive/login.scss";
@import "reponsive/page.scss";
@import "reponsive/login-code.scss";
@import "reponsive/footer.scss";
@import "reponsive/header.scss";



/* footer */
@import "footer.scss";

/* general */
@import "general.scss";