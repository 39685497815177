.cart-address{
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding: 34px 0 32px 0;
  margin-bottom: 32px;
  $self:&;
  &__title{
    color: #4d5367;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 42px;
  }
  &__items{
    #{$self}__items-item{
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #d5d8e1;
      padding-bottom: 31px;
      margin-bottom: 32px;
      > i{
        font-size: 25px;
        color: #454a5b;
        margin-left: 19px;
        transition: .3s;
      }
      #{$self}__items-address{
        color: #8d94aa;
        font-size: 15px;
        font-weight: 700;
      }
      #{$self}__items-change{
        margin-right: auto;
        display: flex;
        align-items: center;
        .title{
          color: #454a5b;
          font-size: 15px;
          font-weight: 700;
          margin-left: 10px;
        }
        i{
          font-size: 21px;
          color: #454a5b;
        }
      }
      &:hover{
        > i{
          color: #12b540;
        }
      }
    }
  }
  &__add{
    display: flex;
    align-items: center;
    i{
      font-size: 23px;
      color: #454a5b;
      margin-left: 20px;
    }
    #{$self}__add-title{
      color: #4d5367;
      font-size: 15px;
      font-weight: 700;
    }
  }
}