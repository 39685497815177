.header-page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid #e1e3ea;
  padding-bottom: 65px;
  margin-bottom: 32px;
  &__title{
    width: 88px;
    height: 88px;
    box-shadow: inset 0 5px 5px rgba(223, 226, 235, 0.75);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    margin-bottom: 13px;
    i{
      width: 58px;
      height: 58px;
      background-color: #12b540;
      font-size: 21px;
      color: #ffffff;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  h2{
    color: #383c48;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  figure{
    position: absolute;
    left: 17px;
  }
}