.cart-success{
  background-color: #fff;
  background-image: url('../img/cart-success-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding-top: 57px;
  padding-bottom: 64px;
  margin-bottom: 3px;
  $self:&;
  &__wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 290px;
    width: 100%;
    margin: 0 auto;
    > i{
      font-size: 53px;
      color: #12b540;
      margin-bottom: 27px;
    }
    #{$self}__greeting{
      color: #4d5367;
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 3px;
    }
    #{$self}__success{
      color: #454a5b;
      font-size: 27px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 53px;
    }
    #{$self}__meta{
      width: 100%;
      margin-bottom: 30px;
      #{$self}__meta-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .key{
          color: #454a5b;
          font-size: 17px;
          font-weight: 700;
        }
        .value{
          color: #454a5b;
          font-size: 17px;
          font-weight: 700;
        }
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
      }
    }
    #{$self}__link{
      width: 100%;
      box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
      background-color: #12b540;
      transition: .3s;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      font-size: 17px;
      font-weight: 700;
      border-radius: 6px;
      padding: 0 20px;
      margin-bottom: 12px;
      i{
        font-size: 15px;
        color: #ffffff;
      }
      &:hover{
        box-shadow: 0 1px 6px rgba(75, 90, 105, 0.17);
      }
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
    }
  }
}