.seller-head{
  height: 99px;
  border-radius: 60px 60px 0 60px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-right: 53px;
  $self:&;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #383c48;
    opacity: 0.5;
  }
  &__name{
    display: flex;
    flex-direction: column;
    z-index: 10;

    h2{
      color: #ffffff;
      font-size: 19px;
      font-weight: 400;
      display: flex;
      align-items: center;
      > i{
        position: relative;
        font-size: 19px;
        color: #12b540;
        margin-left: 6px;
        i{
          position: absolute;
          font-size: 7px;
          color: #ffffff;
          left: 4.5px;
          top: 6px;
        }
      }
    }
    #{$self}__name-title{
      opacity: 0.8;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
  &__info{
    display: flex;
    align-items: center;
    position: relative;
    margin-right: auto;
    margin-left: 17px;
    z-index: 10;
    #{$self}__info-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 30px;
      i{
        color: #ffffff;
        &.icon-transportation{
          font-size: 17px;
          margin-bottom: 15px;
        }
        &.icon-cube{
          font-size: 19px;
          margin-bottom: 14px;
        }
        &.icon-location{
          font-size: 21px;
          margin-bottom: 15px;
        }
      }
      span{
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
      }
      &:nth-last-of-type(1){
        margin-left: 0;
      }
    }
  }
  &__logo{
    position: relative;
    z-index: 10;
    background-image: url("../img/curve-seller-head.svg");
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-end;
    top: 1px;
    width: 250px;
    height: 81px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
  }
  &__link{
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translate(0,-50%);
    left: 41px;
    font-size: 23px;
    color: #ffffff;
  }
}