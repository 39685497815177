@charset "UTF-8";
/*
██╗██╗   ██╗ █████╗ ██╗  ██╗██╗██████╗
██║██║   ██║██╔══██╗██║  ██║██║██╔══██╗
██║██║   ██║███████║███████║██║██║  ██║
██║╚██╗ ██╔╝██╔══██║██╔══██║██║██║  ██║
██║ ╚████╔╝ ██║  ██║██║  ██║██║██████╔╝
╚═╝  ╚═══╝  ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═════╝
*/
/* lato-300 - latin */
/*@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin-300.eot'); !* IE9 Compat Modes *!
  src: local('Lato Light'), local('Lato-Light'),
  url('../fonts/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-300.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-300.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-300.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-300.svg#Lato') format('svg'); !* Legacy iOS *!
}

!* lato-regular - latin *!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-regular.eot'); !* IE9 Compat Modes *!
  src: local('Lato Regular'), local('Lato-Regular'),
  url('../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-regular.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-regular.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); !* Legacy iOS *!
}

!* lato-700 - latin *!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v16-latin-700.eot'); !* IE9 Compat Modes *!
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-700.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-700.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-700.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-700.svg#Lato') format('svg'); !* Legacy iOS *!
}

!* lato-900 - latin *!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v16-latin-900.eot'); !* IE9 Compat Modes *!
  src: local('Lato Black'), local('Lato-Black'),
  url('../fonts/lato-v16-latin-900.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
  url('../fonts/lato-v16-latin-900.woff2') format('woff2'), !* Super Modern Browsers *!
  url('../fonts/lato-v16-latin-900.woff') format('woff'), !* Modern Browsers *!
  url('../fonts/lato-v16-latin-900.ttf') format('truetype'), !* Safari, Android, iOS *!
  url('../fonts/lato-v16-latin-900.svg#Lato') format('svg'); !* Legacy iOS *!
}*/
@import url(../../../node_modules/swiper/css/swiper.min.css);
@import url(../../../node_modules/selectize/dist/css/selectize.css);
@import url(../../../node_modules/aos/dist/aos.css);
@import url(../../../node_modules/remodal/dist/remodal.css);
@import url(jquery.mCustomScrollbar.min.css);
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/eot/iranyekanwebbold(fanum).eot");
  src: url("../fonts/eot/iranyekanwebbold(fanum).eot?#iefix") format("embedded-opentype"), url("../fonts/woff2/iranyekanwebbold(fanum).woff2") format("woff2"), url("../fonts/woff/iranyekanwebbold(fanum).woff") format("woff"), url("../fonts/ttf/iranyekanwebbold(fanum).ttf") format("truetype"); }

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/eot/iranyekanweblight(fanum).eot");
  src: url("../fonts/eot/iranyekanweblight(fanum).eot?#iefix") format("embedded-opentype"), url("../fonts/woff2/iranyekanweblight(fanum).woff2") format("woff2"), url("../fonts/woff/iranyekanweblight(fanum).woff") format("woff"), url("../fonts/ttf/iranyekanweblight(fanum).ttf") format("truetype"); }

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/eot/iranyekanwebregular(fanum).eot");
  src: url("../fonts/eot/iranyekanwebregular(fanum).eot?#iefix") format("embedded-opentype"), url("../fonts/woff2/iranyekanwebregular(fanum).woff2") format("woff2"), url("../fonts/woff/iranyekanregular(fanum).woff") format("woff"), url("../fonts/ttf/iranyekanwebregular(fanum).ttf") format("truetype"); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons/icomoon.eot?rmm2sz");
  src: url("../fonts/icons/icomoon.eot?rmm2sz#iefix") format("embedded-opentype"), url("../fonts/icons/icomoon.ttf?rmm2sz") format("truetype"), url("../fonts/icons/icomoon.woff?rmm2sz") format("woff"), url("../fonts/icons/icomoon.svg?rmm2sz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-buy-text:before {
  content: "\e946"; }

.icon-arrow-double:before {
  content: "\e945"; }

.icon-minus-border:before {
  content: "\e944"; }

.icon-contact-us-icon:before {
  content: "\e943"; }

.icon-mobile:before {
  content: "\e942"; }

.icon-wix-shop:before {
  content: "\e941"; }

.icon-share:before {
  content: "\e940"; }

.icon-arrow-circle:before {
  content: "\e93f"; }

.icon-amazing-offers:before {
  content: "\e93c"; }

.icon-toman:before {
  content: "\e93d"; }

.icon-to:before {
  content: "\e93e"; }

.icon-close:before {
  content: "\e900"; }

.icon-home:before {
  content: "\e901"; }

.icon-report:before {
  content: "\e902"; }

.icon-filter:before {
  content: "\e903"; }

.icon-uniE904:before {
  content: "\e904"; }

.icon-uniE905:before {
  content: "\e905"; }

.icon-pen:before {
  content: "\e906"; }

.icon-recycle-bin:before {
  content: "\e907"; }

.icon-close1:before {
  content: "\e908"; }

.icon-heart:before {
  content: "\e909"; }

.icon-guard:before {
  content: "\e90a"; }

.icon-umbrella:before {
  content: "\e90b"; }

.icon-user:before {
  content: "\e90c"; }

.icon-comment:before {
  content: "\e90d"; }

.icon-drop-down:before {
  content: "\e90e"; }

.icon-more-vertical:before {
  content: "\e90f"; }

.icon-tick:before {
  content: "\e910"; }

.icon-polygon:before {
  content: "\e911"; }

.icon-page-customer:before {
  content: "\e912"; }

.icon-mail:before {
  content: "\e913"; }

.icon-reply:before {
  content: "\e914"; }

.icon-like:before {
  content: "\e915"; }

.icon-arrow-right:before {
  content: "\e916"; }

.icon-logo-text:before {
  content: "\e917"; }

.icon-pluse-border:before {
  content: "\e918"; }

.icon-gift:before {
  content: "\e919"; }

.icon-arrow-left:before {
  content: "\e91a"; }

.icon-ask:before {
  content: "\e91b"; }

.icon-card:before {
  content: "\e91c"; }

.icon-cart:before {
  content: "\e91d"; }

.icon-chief:before {
  content: "\e91e"; }

.icon-computer:before {
  content: "\e91f"; }

.icon-cube:before {
  content: "\e920"; }

.icon-facebook:before {
  content: "\e921"; }

.icon-game:before {
  content: "\e922"; }

.icon-gmail:before {
  content: "\e923"; }

.icon-instagram:before {
  content: "\e924"; }

.icon-linkdin:before {
  content: "\e925"; }

.icon-location:before {
  content: "\e926"; }

.icon-logo:before {
  content: "\e927"; }

.icon-logo-shop:before {
  content: "\e928"; }

.icon-menu:before {
  content: "\e929"; }

.icon-more:before {
  content: "\e92a"; }

.icon-mouse:before {
  content: "\e92b"; }

.icon-percent:before {
  content: "\e92c"; }

.icon-phone-pad:before {
  content: "\e92d"; }

.icon-photography:before {
  content: "\e92e"; }

.icon-pluse:before {
  content: "\e92f"; }

.icon-programming:before {
  content: "\e930"; }

.icon-rate:before {
  content: "\e931"; }

.icon-search:before {
  content: "\e932"; }

.icon-support:before {
  content: "\e933"; }

.icon-telegram:before {
  content: "\e934"; }

.icon-transportation:before {
  content: "\e935"; }

.icon-user-plus:before {
  content: "\e936"; }

.icon-whats-app:before {
  content: "\e937"; }

.icon-accessories:before {
  content: "\e938"; }

.icon-momentary-products:before {
  content: "\e939"; }

.icon-buy-product:before {
  content: "\e93a"; }

.icon-offer:before {
  content: "\e93b"; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-cl: 0;
  --breakpoint-xs: 450px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  direction: ltr; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: right;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

p {
  margin: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin: 0;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin: 0;
  padding: 0;
  list-style: none; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin: 0; }

blockquote {
  margin: 0; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0; }

a:focus {
  outline: 0; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto; }

svg {
  overflow: visible;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: right;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

form {
  margin: 0; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; }

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0; }

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

em, i {
  font-style: normal; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin: 0;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "— "; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 450px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 450px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 450px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 450px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important; }
  .m-xs-n1 {
    margin: -0.25rem !important; }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important; }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important; }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important; }
  .m-xs-n2 {
    margin: -0.5rem !important; }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important; }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important; }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important; }
  .m-xs-n3 {
    margin: -1rem !important; }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important; }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important; }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important; }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important; }
  .m-xs-n4 {
    margin: -1.5rem !important; }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important; }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important; }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important; }
  .m-xs-n5 {
    margin: -3rem !important; }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important; }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important; }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important; }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 450px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 450px) {
  .container {
    max-width: 420px; } }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

.container-fluid {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col-xl,
.col-xl-auto, .col-xl-24, .col-xl-23, .col-xl-22, .col-xl-21, .col-xl-20, .col-xl-19, .col-xl-18, .col-xl-17, .col-xl-16, .col-xl-15, .col-xl-14, .col-xl-13, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-24, .col-lg-23, .col-lg-22, .col-lg-21, .col-lg-20, .col-lg-19, .col-lg-18, .col-lg-17, .col-lg-16, .col-lg-15, .col-lg-14, .col-lg-13, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-24, .col-md-23, .col-md-22, .col-md-21, .col-md-20, .col-md-19, .col-md-18, .col-md-17, .col-md-16, .col-md-15, .col-md-14, .col-md-13, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-24, .col-sm-23, .col-sm-22, .col-sm-21, .col-sm-20, .col-sm-19, .col-sm-18, .col-sm-17, .col-sm-16, .col-sm-15, .col-sm-14, .col-sm-13, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs,
.col-xs-auto, .col-xs-24, .col-xs-23, .col-xs-22, .col-xs-21, .col-xs-20, .col-xs-19, .col-xs-18, .col-xs-17, .col-xs-16, .col-xs-15, .col-xs-14, .col-xs-13, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col,
.col-auto, .col-24, .col-23, .col-22, .col-21, .col-20, .col-19, .col-18, .col-17, .col-16, .col-15, .col-14, .col-13, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.1666666667%;
  max-width: 4.1666666667%; }

.col-2 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-5 {
  flex: 0 0 20.8333333333%;
  max-width: 20.8333333333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.1666666667%;
  max-width: 29.1666666667%; }

.col-8 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-11 {
  flex: 0 0 45.8333333333%;
  max-width: 45.8333333333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.1666666667%;
  max-width: 54.1666666667%; }

.col-14 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-17 {
  flex: 0 0 70.8333333333%;
  max-width: 70.8333333333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.1666666667%;
  max-width: 79.1666666667%; }

.col-20 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-23 {
  flex: 0 0 95.8333333333%;
  max-width: 95.8333333333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.1666666667%; }

.offset-2 {
  margin-left: 8.3333333333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.6666666667%; }

.offset-5 {
  margin-left: 20.8333333333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.1666666667%; }

.offset-8 {
  margin-left: 33.3333333333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.6666666667%; }

.offset-11 {
  margin-left: 45.8333333333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.1666666667%; }

.offset-14 {
  margin-left: 58.3333333333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.6666666667%; }

.offset-17 {
  margin-left: 70.8333333333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.1666666667%; }

.offset-20 {
  margin-left: 83.3333333333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.6666666667%; }

.offset-23 {
  margin-left: 95.8333333333%; }

@media (min-width: 450px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xs-1 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }
  .col-xs-2 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xs-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xs-4 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xs-5 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }
  .col-xs-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-7 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }
  .col-xs-8 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xs-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xs-10 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xs-11 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }
  .col-xs-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-13 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }
  .col-xs-14 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xs-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xs-16 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xs-17 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }
  .col-xs-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-19 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }
  .col-xs-20 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xs-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xs-22 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xs-23 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }
  .col-xs-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 25; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .order-xs-13 {
    order: 13; }
  .order-xs-14 {
    order: 14; }
  .order-xs-15 {
    order: 15; }
  .order-xs-16 {
    order: 16; }
  .order-xs-17 {
    order: 17; }
  .order-xs-18 {
    order: 18; }
  .order-xs-19 {
    order: 19; }
  .order-xs-20 {
    order: 20; }
  .order-xs-21 {
    order: 21; }
  .order-xs-22 {
    order: 22; }
  .order-xs-23 {
    order: 23; }
  .order-xs-24 {
    order: 24; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 4.1666666667%; }
  .offset-xs-2 {
    margin-left: 8.3333333333%; }
  .offset-xs-3 {
    margin-left: 12.5%; }
  .offset-xs-4 {
    margin-left: 16.6666666667%; }
  .offset-xs-5 {
    margin-left: 20.8333333333%; }
  .offset-xs-6 {
    margin-left: 25%; }
  .offset-xs-7 {
    margin-left: 29.1666666667%; }
  .offset-xs-8 {
    margin-left: 33.3333333333%; }
  .offset-xs-9 {
    margin-left: 37.5%; }
  .offset-xs-10 {
    margin-left: 41.6666666667%; }
  .offset-xs-11 {
    margin-left: 45.8333333333%; }
  .offset-xs-12 {
    margin-left: 50%; }
  .offset-xs-13 {
    margin-left: 54.1666666667%; }
  .offset-xs-14 {
    margin-left: 58.3333333333%; }
  .offset-xs-15 {
    margin-left: 62.5%; }
  .offset-xs-16 {
    margin-left: 66.6666666667%; }
  .offset-xs-17 {
    margin-left: 70.8333333333%; }
  .offset-xs-18 {
    margin-left: 75%; }
  .offset-xs-19 {
    margin-left: 79.1666666667%; }
  .offset-xs-20 {
    margin-left: 83.3333333333%; }
  .offset-xs-21 {
    margin-left: 87.5%; }
  .offset-xs-22 {
    margin-left: 91.6666666667%; }
  .offset-xs-23 {
    margin-left: 95.8333333333%; } }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }
  .col-sm-2 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-5 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }
  .col-sm-8 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-11 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }
  .col-sm-14 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-17 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }
  .col-sm-20 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-23 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.1666666667%; }
  .offset-sm-2 {
    margin-left: 8.3333333333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.6666666667%; }
  .offset-sm-5 {
    margin-left: 20.8333333333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.1666666667%; }
  .offset-sm-8 {
    margin-left: 33.3333333333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.6666666667%; }
  .offset-sm-11 {
    margin-left: 45.8333333333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.1666666667%; }
  .offset-sm-14 {
    margin-left: 58.3333333333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.6666666667%; }
  .offset-sm-17 {
    margin-left: 70.8333333333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.1666666667%; }
  .offset-sm-20 {
    margin-left: 83.3333333333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.6666666667%; }
  .offset-sm-23 {
    margin-left: 95.8333333333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }
  .col-md-2 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-5 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }
  .col-md-8 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-11 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }
  .col-md-14 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-17 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }
  .col-md-20 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-23 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.1666666667%; }
  .offset-md-2 {
    margin-left: 8.3333333333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.6666666667%; }
  .offset-md-5 {
    margin-left: 20.8333333333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.1666666667%; }
  .offset-md-8 {
    margin-left: 33.3333333333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.6666666667%; }
  .offset-md-11 {
    margin-left: 45.8333333333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.1666666667%; }
  .offset-md-14 {
    margin-left: 58.3333333333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.6666666667%; }
  .offset-md-17 {
    margin-left: 70.8333333333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.1666666667%; }
  .offset-md-20 {
    margin-left: 83.3333333333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.6666666667%; }
  .offset-md-23 {
    margin-left: 95.8333333333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }
  .col-lg-2 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-5 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }
  .col-lg-8 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-11 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }
  .col-lg-14 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-17 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }
  .col-lg-20 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-23 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.1666666667%; }
  .offset-lg-2 {
    margin-left: 8.3333333333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.6666666667%; }
  .offset-lg-5 {
    margin-left: 20.8333333333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.1666666667%; }
  .offset-lg-8 {
    margin-left: 33.3333333333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.6666666667%; }
  .offset-lg-11 {
    margin-left: 45.8333333333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.1666666667%; }
  .offset-lg-14 {
    margin-left: 58.3333333333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.6666666667%; }
  .offset-lg-17 {
    margin-left: 70.8333333333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.1666666667%; }
  .offset-lg-20 {
    margin-left: 83.3333333333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.6666666667%; }
  .offset-lg-23 {
    margin-left: 95.8333333333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }
  .col-xl-2 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-5 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }
  .col-xl-8 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-11 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }
  .col-xl-14 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-17 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }
  .col-xl-20 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-23 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.1666666667%; }
  .offset-xl-2 {
    margin-left: 8.3333333333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.6666666667%; }
  .offset-xl-5 {
    margin-left: 20.8333333333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.1666666667%; }
  .offset-xl-8 {
    margin-left: 33.3333333333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.6666666667%; }
  .offset-xl-11 {
    margin-left: 45.8333333333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.1666666667%; }
  .offset-xl-14 {
    margin-left: 58.3333333333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.6666666667%; }
  .offset-xl-17 {
    margin-left: 70.8333333333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.1666666667%; }
  .offset-xl-20 {
    margin-left: 83.3333333333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.6666666667%; }
  .offset-xl-23 {
    margin-left: 95.8333333333%; } }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td,
  .table th {
    background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*# sourceMappingURL=reset.css.map */
/* libraries */
/* header */
.site-header {
  padding-top: 45px;
  margin-bottom: 73px;
  position: relative; }
  .site-header .response-head {
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    padding: 20px 0;
    display: none; }
    .site-header .response-head__menu {
      font-size: 20px;
      color: #949494; }
  .site-header__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 29px; }
  .site-header__right {
    display: flex;
    align-items: center; }
    .site-header__right .site-header__logo {
      margin-left: 24px; }
    .site-header__right .site-header__location {
      display: flex;
      align-items: center;
      border: 1px solid #e7e8f2;
      border-radius: 5px;
      padding: 0 15px 0 17px;
      height: 36px;
      cursor: pointer; }
      .site-header__right .site-header__location i {
        font-size: 18px;
        color: #4d5367;
        margin-left: 13px; }
      .site-header__right .site-header__location span {
        color: #4d5367;
        font-size: 15px;
        font-weight: 700; }
  .site-header__left {
    margin-right: auto;
    display: flex;
    align-items: center; }
    .site-header__left .site-header__search {
      display: flex;
      align-items: center;
      margin-left: 56px;
      border: 1px solid #e5e7f1;
      border-radius: 5px;
      padding: 10px; }
      .site-header__left .site-header__search input {
        width: 274px;
        color: #4d5367;
        font-size: 15px;
        font-weight: 700;
        margin-left: 20px; }
        .site-header__left .site-header__search input::placeholder {
          color: #4d5367;
          font-size: 15px;
          font-weight: 700;
          opacity: .8; }
      .site-header__left .site-header__search .site-header__search-btn {
        padding: 0;
        border: none;
        background-color: transparent; }
        .site-header__left .site-header__search .site-header__search-btn i {
          font-size: 17px;
          color: #5d6273;
          position: relative;
          top: 2px; }
    .site-header__left .site-header__cart {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      width: 50px;
      height: 50px;
      margin-left: 55px;
      background-color: #0661f8;
      cursor: pointer;
      position: relative; }
      .site-header__left .site-header__cart i {
        font-size: 18px;
        color: #ffffff; }
      .site-header__left .site-header__cart .site-header__cart-items {
        top: 100%;
        width: 360px;
        border-radius: 10px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #fff;
        box-shadow: 0 25px 25px 0 rgba(113, 124, 137, 0.25);
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: .3s; }
        .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper {
          display: flex;
          align-items: center;
          padding: 15px; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper > a {
            flex: 0 1 auto;
            margin-left: 20px; }
            .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper > a img {
              width: 60px; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info {
            display: flex;
            flex-direction: column; }
            .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info__title {
              font-size: 14px;
              font-weight: 400;
              color: #383c48;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 10px;
              max-width: 240px; }
            .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info__meta {
              display: flex;
              align-items: center; }
              .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info__meta .item {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #949494;
                margin-left: 20px; }
                .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info__meta .item:nth-last-of-type(1) {
                  margin-left: 0; }
            .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info .recycle-bin {
              cursor: pointer;
              display: flex;
              margin-right: auto; }
              .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .cart__wrapper .info .recycle-bin i {
                font-size: 17px;
                color: #838c95; }
        .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .count-product {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          color: #838c95;
          font-size: 13px;
          background-color: #f5f7f8;
          margin-bottom: 15px;
          position: relative; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .count-product__counter {
            display: flex;
            align-items: center;
            margin-left: 15px;
            margin-right: auto; }
            .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .count-product__counter i {
              font-size: 18px;
              color: #838c95; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .count-product__counter-item {
            display: flex; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .count-product__counter-numb {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 16px; }
        .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          margin-bottom: 20px; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .footer__show {
            font-size: 13px;
            font-weight: 400;
            color: #949494; }
          .site-header__left .site-header__cart .site-header__cart-items .site-header__cart-item .footer__complete {
            font-size: 15px;
            font-weight: 400;
            color: #0661f8; }
        .site-header__left .site-header__cart .site-header__cart-items.active {
          opacity: 1;
          visibility: visible; }
    .site-header__left .site-header__left-item {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .site-header__left .site-header__left-item span {
        color: #5d6273;
        font-size: 15px;
        font-weight: 700; }
      .site-header__left .site-header__left-item--user-plus {
        margin-left: 15px; }
        .site-header__left .site-header__left-item--user-plus i {
          font-size: 21px;
          color: #12b540;
          margin-right: 15px; }
      .site-header__left .site-header__left-item--cat {
        cursor: pointer; }
        .site-header__left .site-header__left-item--cat span {
          transition: .3s; }
        .site-header__left .site-header__left-item--cat i {
          font-size: 15px;
          color: #5d6273;
          margin-right: 20px;
          transition: .3s; }
        .site-header__left .site-header__left-item--cat.active span {
          color: #0661f8; }
        .site-header__left .site-header__left-item--cat.active i {
          color: #0661f8; }
  .site-header__momentary-products {
    position: relative; }
    .site-header__momentary-products:before {
      content: '';
      position: absolute;
      top: 0;
      right: -60px;
      left: -60px;
      height: 40px;
      box-shadow: 0 -14px 26px -19px rgba(0, 0, 0, 0.4);
      pointer-events: none; }
    .site-header__momentary-products .swiper-container {
      padding-top: 17px;
      padding-bottom: 25px; }
      .site-header__momentary-products .swiper-container:before {
        content: '';
        position: absolute;
        right: -30px;
        top: 0;
        bottom: 0;
        width: 30px;
        box-shadow: -7px -5px 20px -11px rgba(0, 0, 0, 0.4); }
      .site-header__momentary-products .swiper-container:after {
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        bottom: 0;
        width: 30px;
        box-shadow: 7px -5px 20px -11px rgba(0, 0, 0, 0.4); }
    .site-header__momentary-products .site-header__momentary-products-item {
      display: flex;
      align-items: center; }
      .site-header__momentary-products .site-header__momentary-products-item:after {
        content: '';
        display: inline-block;
        border-left: 1px solid #e5e7f1;
        height: 20px;
        position: relative;
        top: 3px; }
      .site-header__momentary-products .site-header__momentary-products-item .title {
        color: #383c48;
        font-size: 13px;
        font-weight: 700;
        margin-left: 27px; }
      .site-header__momentary-products .site-header__momentary-products-item .price {
        display: flex;
        align-items: center;
        margin-left: 19px; }
        .site-header__momentary-products .site-header__momentary-products-item .price__val {
          color: #4d5367;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: -0.6px;
          text-decoration: none;
          margin-left: 3px; }
        .site-header__momentary-products .site-header__momentary-products-item .price__unit {
          color: #676d80;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.48px; }
    .site-header__momentary-products .site-header__momentary-products-title {
      padding-top: 21px;
      display: flex;
      justify-content: flex-end;
      height: 100%; }
      .site-header__momentary-products .site-header__momentary-products-title i {
        font-size: 17px;
        color: #12b540;
        position: relative;
        top: 2px; }
  .site-header--single {
    margin-bottom: 35px; }
  .site-header--sec-header {
    border-bottom: 1px solid #eceff1;
    margin-bottom: 32px; }
    .site-header--sec-header .site-header__momentary-products {
      border-top: 1px solid #eceff1; }
      .site-header--sec-header .site-header__momentary-products:before {
        display: none; }
      .site-header--sec-header .site-header__momentary-products .swiper-container:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 80px;
        box-shadow: none;
        background-image: linear-gradient(to left, #fff, transparent);
        z-index: 10;
        pointer-events: none; }
      .site-header--sec-header .site-header__momentary-products .swiper-container:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 80px;
        box-shadow: none;
        background-image: linear-gradient(to right, #fff, transparent);
        z-index: 10;
        pointer-events: none; }
  .site-header.page {
    margin-bottom: 79px; }
  .site-header.cat-filter {
    margin-bottom: 28px; }
  .site-header__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    cursor: pointer; }
    .site-header__navigation ul {
      display: flex; }
      .site-header__navigation ul li {
        margin-right: 30px; }
        .site-header__navigation ul li:first-of-type {
          margin-right: 0; }
        .site-header__navigation ul li.active span {
          color: #0661f8; }
        .site-header__navigation ul li.active i {
          color: #0661f8; }

.mega-menu-site-responsive__body ul > .has-childe {
  padding-bottom: 13px; }

.mega-menu-site-responsive__body .has-childe {
  margin-top: 13px; }
  .mega-menu-site-responsive__body .has-childe > ul {
    padding-left: 0;
    padding-top: 0; }
  .mega-menu-site-responsive__body .has-childe ul {
    display: none; }
  .mega-menu-site-responsive__body .has-childe .has-childe {
    padding-right: 15px; }
    .mega-menu-site-responsive__body .has-childe .has-childe > span {
      padding-bottom: 10px; }
  .mega-menu-site-responsive__body .has-childe > span {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .mega-menu-site-responsive__body .has-childe > span:after {
      content: '\e90e';
      font-family: 'icomoon';
      font-size: 8px;
      color: #000000;
      transition: .3s;
      display: inline-block;
      transform: rotate(0); }
  .mega-menu-site-responsive__body .has-childe.active > span:after {
    transform: rotate(180deg); }

/* megamenu */
.mega-menu-cat {
  position: absolute;
  right: 0;
  left: 0;
  top: 100px;
  background-color: #fff;
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  border-top: 1px solid #edf0f3;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.08); }
  .mega-menu-cat .container .tab-wrapper {
    display: flex; }
  .mega-menu-cat .container .tab-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;
    flex: 1 0 auto;
    border-left: 1px solid #edf0f3;
    min-width: 150px;
    height: 464px; }
    .mega-menu-cat .container .tab-title > li {
      width: 100%;
      padding: 10px 15px;
      border-bottom: 1px solid #e5e7f1;
      transition: .3s; }
      .mega-menu-cat .container .tab-title > li .mega-menu-cat__cat-item {
        color: #38424d;
        font-size: 13px;
        font-weight: 500;
        transition: .3s; }
      .mega-menu-cat .container .tab-title > li.active {
        background-color: #f7f7f7; }
        .mega-menu-cat .container .tab-title > li.active .mega-menu-cat__cat-item {
          color: #0661f8; }
        .mega-menu-cat .container .tab-title > li.active .mega-menu-cat__cat-wrapper {
          visibility: visible;
          opacity: 1; }
  .mega-menu-cat .container .mega-menu-cat__cat-wrapper {
    display: flex;
    align-items: center;
    transition: .3s;
    background-color: #fff;
    padding-top: 12px;
    padding-bottom: 33px;
    padding-right: 15px;
    padding-left: 15px;
    overflow: hidden;
    /*&.active{
        .container{
          opacity: 1;
          visibility: visible;
          transform: translate(0,0) rotateY(0);
          //transition: .3s;
        }
      }*/ }
    .mega-menu-cat .container .mega-menu-cat__cat-wrapper .container {
      display: flex;
      align-items: center; }
  .mega-menu-cat .container .mega-menu-cat__cat-sub {
    display: flex;
    align-items: center; }
    .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item {
      margin-left: 50px;
      flex: 1 0 auto;
      /*.title{
          color: #0661f8;
          font-size: 15px;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 26px;
        }*/ }
      .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul {
        height: 400px;
        column-count: 4;
        column-fill: auto; }
        .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul .title a {
          color: #38424d;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          transition: .3s; }
          .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul .title a:after {
            content: '\e90e';
            font-family: 'icomoon';
            font-size: 6px;
            color: #38424d;
            transform: rotate(90deg);
            margin-right: 5px; }
          .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul .title a:hover {
            color: #0661f8; }
            .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul .title a:hover:after {
              color: #0661f8; }
        .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul .title--lists ul li a {
          color: #38424d;
          font-size: 12px;
          font-weight: 400; }
          .mega-menu-cat .container .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item > ul .title--lists ul li a:after {
            display: none; }
  .mega-menu-cat .container .mega-menu-cat__cat-img {
    margin-right: auto; }
  .mega-menu-cat.active {
    top: 67%;
    visibility: visible;
    opacity: 1; }

/* index */
.main-info {
  display: flex;
  height: 600px;
  overflow: hidden;
  background-image: url("../img/bg-main-info.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 107px; }

.buyer {
  flex: 1 0 50%;
  position: relative; }
  .buyer__animation {
    position: absolute;
    right: 174px;
    top: 102px;
    z-index: 10; }
  .buyer__item--comment {
    position: absolute;
    top: 15px;
    right: 283px; }
  .buyer__item--arrow {
    position: absolute;
    top: 118px;
    right: -28px; }
  .buyer__item--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 5px;
    right: -165px; }
    .buyer__item--info .buyer__item-subject {
      color: #1049dc;
      font-size: 28px;
      font-weight: 400; }
    .buyer__item--info .buyer__item-desc {
      color: #787d8a;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 8px;
      position: relative;
      top: -2px; }
    .buyer__item--info .buyer__item-numb {
      align-self: flex-start;
      display: flex;
      align-items: center; }
      .buyer__item--info .buyer__item-numb .numb {
        color: #767b8c;
        font-size: 23px;
        font-weight: 700;
        margin-left: 11px; }
      .buyer__item--info .buyer__item-numb .title {
        color: #767b8c;
        font-size: 16px;
        font-weight: 700; }
  .buyer__background {
    position: absolute;
    z-index: 9;
    right: -540px; }

.seller {
  flex: 1 0 50%;
  position: relative; }
  .seller__animation {
    position: absolute;
    left: 230px;
    top: 65px;
    z-index: 10; }
  .seller__item--comment {
    position: absolute;
    right: 47px;
    top: 122px; }
  .seller__item--arrow {
    top: 102px;
    position: absolute;
    left: 0; }
  .seller__item--info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 127px;
    left: -226px; }
    .seller__item--info .seller__item-subject {
      color: #12b540;
      font-size: 28px;
      font-weight: 400; }
    .seller__item--info .seller__item-desc {
      color: #787d8a;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 14px; }
    .seller__item--info .seller__item-numb {
      align-self: flex-start;
      display: flex;
      align-items: center; }
      .seller__item--info .seller__item-numb .numb {
        color: #767b8c;
        font-size: 23px;
        font-weight: 700;
        margin-left: 10px; }
      .seller__item--info .seller__item-numb .title {
        color: #767b8c;
        font-size: 16px;
        font-weight: 700; }
  .seller__background {
    position: absolute;
    z-index: 9;
    left: -565px; }

.store {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 493px;
  transform: translate(-50%, 0); }
  .store__create {
    width: 217px;
    height: 63px;
    background-color: #12b540;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 14px 30px -11px #12b540;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 37px;
    position: relative;
    z-index: 10; }
  .store__show {
    width: 197px;
    height: 63px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 15px 44px -17px rgba(0, 0, 0, 0.3);
    color: #1049dc;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    z-index: 9;
    background-color: #fff; }

.popular-cat {
  overflow: hidden;
  padding-bottom: 20px;
  padding-right: 15px;
  margin-bottom: 49px; }
  .popular-cat__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 26px; }
    .popular-cat__header:after {
      content: '';
      position: absolute;
      top: 15px;
      right: 0;
      left: 0;
      border-bottom: 1px solid  #e3e6f1;
      z-index: 9; }
    .popular-cat__header .popular-cat__header-title {
      color: #454a5b;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      z-index: 10;
      background-color: #fff;
      padding-left: 25px; }
    .popular-cat__header .popular-cat__header-link {
      color: #14a13b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
      background-color: #fff;
      z-index: 10;
      padding-right: 52px; }
      .popular-cat__header .popular-cat__header-link i {
        font-size: 4px;
        color: #14a13b;
        margin-right: 19px;
        margin-top: 3px; }
  .popular-cat__content .popular-cat-swiper {
    padding: 30px;
    margin: -30px; }
  .popular-cat__content .popular-cat__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 223px;
    padding-bottom: 40px;
    padding-top: 48px;
    border-radius: 15px;
    box-shadow: 22px 15px 30px -20px rgba(0, 0, 0, 0.12);
    position: relative;
    transition: .3s; }
    .popular-cat__content .popular-cat__item > a {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
    .popular-cat__content .popular-cat__item i {
      color: #14a13b; }
      .popular-cat__content .popular-cat__item i.icon-computer {
        font-size: 49px; }
      .popular-cat__content .popular-cat__item i.icon-chief {
        font-size: 53px; }
      .popular-cat__content .popular-cat__item i.icon-accessories {
        font-size: 55px; }
      .popular-cat__content .popular-cat__item i.icon-photography {
        font-size: 45px; }
      .popular-cat__content .popular-cat__item i.icon-game {
        font-size: 45px; }
      .popular-cat__content .popular-cat__item i.icon-mouse {
        font-size: 55px; }
      .popular-cat__content .popular-cat__item i.icon-programming {
        font-size: 47px; }
    .popular-cat__content .popular-cat__item .popular-cat__item-title {
      margin-top: auto;
      color: #14a13b;
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 7px; }
    .popular-cat__content .popular-cat__item .popular-cat__item-count {
      color: #6e7488;
      font-size: 15px;
      font-weight: 700; }
    .popular-cat__content .popular-cat__item:hover {
      box-shadow: 10px 15px 30px -20px rgba(0, 0, 0, 0.12); }

.product__nav {
  width: 47px;
  height: 110px;
  background-color: #12b540;
  border-radius: 60px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 20;
  transition: .3s;
  cursor: pointer; }
  .product__nav--next {
    left: -77px; }
    .product__nav--next .arrow {
      position: absolute;
      top: 50%;
      left: 59%;
      transform: translate(-50%, -50%); }
      .product__nav--next .arrow__line {
        position: absolute;
        width: 16px;
        height: 2.5px;
        border-radius: 60px;
        background-color: #fff;
        left: -12px;
        transition: .3s; }
      .product__nav--next .arrow:after {
        content: '';
        position: absolute;
        width: 13px;
        border-radius: 60px;
        height: 2.5px;
        transform-origin: 0 50%;
        background-color: #fff;
        display: inline-block;
        transform: rotate(45deg);
        top: -1px;
        transition: .3s; }
      .product__nav--next .arrow:before {
        content: '';
        position: absolute;
        width: 13px;
        border-radius: 60px;
        height: 2.5px;
        transform-origin: 0 50%;
        background-color: #fff;
        display: inline-block;
        transform: rotate(-45deg);
        top: 1px;
        transition: .3s; }
    .product__nav--next.swiper-button-disabled {
      background-color: #f3f5f9; }
      .product__nav--next.swiper-button-disabled .arrow__line {
        background-color: #bcc6d9;
        width: 6px;
        height: 6px;
        left: -8px; }
      .product__nav--next.swiper-button-disabled .arrow:after {
        transform: rotate(0);
        opacity: 0; }
      .product__nav--next.swiper-button-disabled .arrow:before {
        transform: rotate(0);
        opacity: 0; }
  .product__nav--prev {
    right: -77px; }
    .product__nav--prev .arrow {
      position: absolute;
      top: 50%;
      left: 41%;
      transform: translate(-50%, -50%) rotateY(180deg);
      display: inline-block; }
      .product__nav--prev .arrow__line {
        position: absolute;
        width: 16px;
        height: 2.5px;
        border-radius: 60px;
        background-color: #fff;
        left: -12px;
        transition: .3s; }
      .product__nav--prev .arrow:after {
        content: '';
        position: absolute;
        width: 13px;
        border-radius: 60px;
        height: 2.5px;
        transform-origin: 0 50%;
        background-color: #fff;
        display: inline-block;
        transform: rotate(45deg);
        top: -1px;
        transition: .3s; }
      .product__nav--prev .arrow:before {
        content: '';
        position: absolute;
        width: 13px;
        border-radius: 60px;
        height: 2.5px;
        transform-origin: 0 50%;
        background-color: #fff;
        display: inline-block;
        transform: rotate(-45deg);
        top: 1px;
        transition: .3s; }
    .product__nav--prev.swiper-button-disabled {
      background-color: #f3f5f9; }
      .product__nav--prev.swiper-button-disabled .arrow__line {
        background-color: #bcc6d9;
        width: 6px;
        height: 6px;
        left: -8px; }
      .product__nav--prev.swiper-button-disabled .arrow:after {
        transform: rotate(0);
        opacity: 0; }
      .product__nav--prev.swiper-button-disabled .arrow:before {
        transform: rotate(0);
        opacity: 0; }

.product .container {
  position: relative; }

.product__header {
  display: flex;
  align-items: center;
  margin-bottom: 49px; }
  .product__header h2 {
    color: #454a5b;
    font-size: 20px;
    font-weight: 700;
    margin-left: 30px; }
  .product__header .product__header-cat {
    display: flex;
    align-items: center; }
    .product__header .product__header-cat .product__header-cat-item {
      color: #787d90;
      font-size: 15px;
      font-weight: 700;
      height: 38px;
      box-shadow: none;
      display: flex;
      align-items: center;
      border-radius: 60px;
      padding: 0 28px 0 27px;
      transition: .3s;
      margin-left: 7px; }
      .product__header .product__header-cat .product__header-cat-item:nth-last-of-type(1) {
        margin-left: 0; }
      .product__header .product__header-cat .product__header-cat-item:hover {
        background-color: #12b540;
        box-shadow: 0 2px 2px rgba(139, 144, 159, 0.15);
        color: #ffffff; }
  .product__header .product__header-more {
    display: flex;
    align-items: center;
    margin-right: auto;
    color: #14a13b;
    font-size: 15px;
    font-weight: 700; }
    .product__header .product__header-more i {
      font-size: 4px;
      color: #14a13b;
      margin-top: 3px;
      margin-right: 19px; }

.product__content {
  padding: 40px;
  margin: -40px; }
  .product__content .swiper-slide {
    transition: .1s;
    opacity: 0; }
    .product__content .swiper-slide.swiper-slide-active {
      opacity: 1; }
      .product__content .swiper-slide.swiper-slide-active + .swiper-slide {
        opacity: 1; }
        .product__content .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide {
          opacity: 1; }
          .product__content .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
            opacity: 1; }
  .product__content .product__item {
    box-shadow: 0 10px 28px 0 rgba(0, 0, 0, 0.1);
    padding: 23px 24px 16px 25px;
    overflow: hidden;
    border-radius: 8px; }
    .product__content .product__item .product__item-unavailable {
      text-align: center;
      position: absolute;
      top: 72px;
      left: 26px;
      width: 82px;
      height: 21px;
      background-color: #b6bbcb;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.24px;
      border-radius: 0 60px 60px 60px; }
    .product__content .product__item .product__item-new {
      position: absolute;
      border-radius: 0 60px 60px 60px;
      top: 72px;
      left: 26px;
      width: 42px;
      height: 21px;
      background-color: #12b540;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.24px;
      text-align: center; }
    .product__content .product__item .product__item-header {
      border-bottom: 1px solid #e8eaf1;
      padding-bottom: 21px; }
      .product__content .product__item .product__item-header h2 {
        color: #4d5367;
        font-size: 16px;
        font-weight: 700;
        text-align: center; }
    .product__content .product__item .product__item-img {
      position: relative; }
      .product__content .product__item .product__item-img figure {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 207px;
        padding: 5px; }
      .product__content .product__item .product__item-img .product__item-img-gallery {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 13px;
        z-index: 100; }
        .product__content .product__item .product__item-img .product__item-img-gallery .item {
          width: 33px;
          height: 33px;
          box-shadow: 0 5px 5px rgba(56, 60, 72, 0.13);
          background-color: #ffffff;
          border-radius: 5px;
          transition: .5s;
          margin-bottom: 10px;
          border: 1px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          visibility: hidden;
          opacity: 0; }
          .product__content .product__item .product__item-img .product__item-img-gallery .item:nth-of-type(1) {
            transition-delay: .1s; }
          .product__content .product__item .product__item-img .product__item-img-gallery .item:nth-of-type(2) {
            transition-delay: .2s; }
          .product__content .product__item .product__item-img .product__item-img-gallery .item:nth-of-type(3) {
            transition-delay: .3s; }
          .product__content .product__item .product__item-img .product__item-img-gallery .item:nth-of-type(4) {
            transition-delay: .4s; }
          .product__content .product__item .product__item-img .product__item-img-gallery .item:nth-of-type(5) {
            transition-delay: .5s; }
          .product__content .product__item .product__item-img .product__item-img-gallery .item:hover {
            box-shadow: none;
            border: 1px solid #e8eaf1; }
    .product__content .product__item .product__item-footer .product__item-footer-buy {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8eaf1;
      padding-bottom: 16px;
      margin-bottom: 10px; }
      .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-buying {
        display: flex;
        align-items: center; }
        .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-buying i {
          color: #4d5367; }
          .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-buying i.pluse {
            font-size: 9px;
            margin-left: 5px; }
          .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-buying i.buy-product {
            font-size: 15px; }
      .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-price {
        display: flex;
        align-items: center;
        margin-right: auto;
        position: relative; }
        .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-price .discount {
          position: absolute;
          left: 0;
          top: -15px;
          width: 42px;
          height: 17px;
          background-color: #ef473a;
          border-radius: 60px 60px 60px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.72px; }
          .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-price .discount i {
            font-size: 10px;
            color: #ffffff;
            margin-right: 2px; }
        .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-price ins {
          text-decoration: none;
          color: #12b540;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.8px;
          margin-left: 3px; }
        .product__content .product__item .product__item-footer .product__item-footer-buy .product__item-footer-price .unit {
          color: #12b540;
          font-size: 14px;
          font-weight: 700; }
    .product__content .product__item .product__item-footer .product__item-footer-info {
      display: flex;
      align-items: center; }
      .product__content .product__item .product__item-footer .product__item-footer-info .product__item-footer-title {
        color: #878c9b;
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px; }
      .product__content .product__item .product__item-footer .product__item-footer-info .product__item-footer-logo {
        margin-left: 9px; }
      .product__content .product__item .product__item-footer .product__item-footer-info .product__item-footer-name {
        color: #4d5367;
        font-size: 14px;
        font-weight: 700; }
      .product__content .product__item .product__item-footer .product__item-footer-info .product__item-footer-rate {
        display: flex;
        align-items: baseline;
        margin-right: auto; }
        .product__content .product__item .product__item-footer .product__item-footer-info .product__item-footer-rate i {
          font-size: 11px;
          color: #b2b5c3;
          position: relative;
          bottom: 1px; }
        .product__content .product__item .product__item-footer .product__item-footer-info .product__item-footer-rate .rate {
          color: #4d5367;
          font-size: 17px;
          font-weight: 700;
          margin-left: 3px; }
    .product__content .product__item--offer .product__item-footer {
      position: relative; }
      .product__content .product__item--offer .product__item-footer .product__item-footer-buy {
        border-bottom: none; }
      .product__content .product__item--offer .product__item-footer .product__item-footer-info .product__item-footer-title {
        display: none; }
      .product__content .product__item--offer .product__item-footer .product__item-footer-info .product__item-footer-rate {
        display: none; }
      .product__content .product__item--offer .product__item-footer .product__item-footer-offer {
        position: absolute;
        left: 2px;
        bottom: 0;
        text-align: left;
        width: 90px;
        border-radius: 0 17px 0 0;
        height: 49px;
        top: 50px;
        overflow: hidden;
        padding-top: 6px;
        transform: skew(40deg); }
        .product__content .product__item--offer .product__item-footer .product__item-footer-offer:after {
          content: '';
          position: absolute;
          width: 50px;
          height: 50px;
          transform-origin: 0 50%;
          box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.8);
          right: -32px;
          border-radius: 60px;
          top: -54px;
          transform: skew(-30deg); }
        .product__content .product__item--offer .product__item-footer .product__item-footer-offer i {
          font-size: 13px;
          color: #ef473a;
          transform: skew(-40deg);
          display: inline-block;
          position: relative;
          left: 4px;
          top: 3px; }
    .product__content .product__item--unavailable {
      position: relative; }
      .product__content .product__item--unavailable .product__item-footer .product__item-footer-buy .product__item-footer-price {
        filter: blur(3px); }
        .product__content .product__item--unavailable .product__item-footer .product__item-footer-buy .product__item-footer-price ins {
          color: #b6bbcb; }
        .product__content .product__item--unavailable .product__item-footer .product__item-footer-buy .product__item-footer-price .unit {
          color: #b6bbcb; }
    .product__content .product__item--discount .product__item-footer .product__item-footer-buy .product__item-footer-price ins {
      color: #ef473a; }
    .product__content .product__item--discount .product__item-footer .product__item-footer-buy .product__item-footer-price .unit {
      color: #ef473a; }
    .product__content .product__item:hover .product__item-img .product__item-img-gallery .item {
      visibility: visible;
      opacity: 1; }

.product--other .product__item {
  box-shadow: 0 2px 2px rgba(77, 83, 103, 0.19);
  background-color: #fff; }

.top-cat {
  background-color: #f3f5f9;
  background-image: linear-gradient(to top, #ffffff 0%, #f3f5f9 19%, #f3f5f9 77%, #ffffff 100%);
  padding-top: 99px;
  padding-bottom: 99px; }
  .top-cat__item {
    background-color: #f3f5f9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    padding: 25px 31px 25px 31px;
    position: relative;
    box-shadow: -7px -8px 20px -5px rgba(255, 255, 255, 0.9), 4px 6px 20px -10px rgba(0, 0, 0, 0.15); }
    .top-cat__item .top-cat__item-meta {
      color: #515667;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 1px; }
    .top-cat__item .top-cat__item-title {
      color: #12b540;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 17px; }
    .top-cat__item .top-cat__item-link {
      width: 105px;
      height: 30px;
      box-shadow: inset 0 2px 4px rgba(77, 83, 103, 0.11);
      background-color: #12b540;
      color: #ffffff;
      font-size: 15px;
      font-weight: 700;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 13px 0 12px; }
      .top-cat__item .top-cat__item-link i {
        font-size: 11px;
        color: #ffffff; }
    .top-cat__item .top-cat__item-image {
      position: absolute; }
      .top-cat__item .top-cat__item-image--first {
        top: -16px;
        left: 24px; }
      .top-cat__item .top-cat__item-image--second {
        top: -7px;
        left: 28px; }
      .top-cat__item .top-cat__item-image--third {
        top: -22px;
        left: 37px; }

.top-sellers {
  margin-top: 70px;
  margin-bottom: 76px; }
  .top-sellers__nav {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 60px;
    box-shadow: -2px 7px 16px -5px rgba(0, 0, 0, 0.2);
    z-index: 100;
    background-color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); }
    .top-sellers__nav i {
      font-size: 14px;
      color: #12b540;
      transition: .3s; }
    .top-sellers__nav--next {
      right: 10px; }
    .top-sellers__nav--prev {
      left: 10px; }
    .top-sellers__nav.swiper-button-disabled i {
      color: #b6bbcb; }
  .top-sellers__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 51px; }
    .top-sellers__header h2 {
      color: #454a5b;
      font-size: 20px;
      font-weight: 700; }
    .top-sellers__header .top-sellers__header-link {
      color: #14a13b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center; }
      .top-sellers__header .top-sellers__header-link i {
        font-size: 4px;
        color: #14a13b;
        margin-top: 3px;
        margin-right: 19px; }
  .top-sellers__content .top-sellers__content-swiper {
    padding: 30px;
    margin: -30px; }
  .top-sellers__content .top-sellers__item {
    position: relative;
    height: 188px;
    border-radius: 9px; }
    .top-sellers__content .top-sellers__item > a {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 10; }
    .top-sellers__content .top-sellers__item:before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: 72px;
      bottom: 0;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
      border-radius: 0 0 9px 9px; }
    .top-sellers__content .top-sellers__item .top-sellers__item-curve {
      height: 33px;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0; }
    .top-sellers__content .top-sellers__item .top-sellers__item-info {
      position: absolute;
      right: 0;
      left: 0;
      top: 72px;
      bottom: 0;
      background-color: #fff;
      border-radius: 0 0 9px 9px; }
      .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-logo {
        position: absolute;
        left: 49%;
        top: -23px;
        transform: translate(-50%, 0); }
      .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-title {
        color: #3c4153;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top: 44px;
        margin-bottom: 15px;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px; }
      .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 19px; }
        .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer .top-sellers__item-footer-location {
          display: flex;
          align-items: center; }
          .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer .top-sellers__item-footer-location i {
            font-size: 18px;
            color: #4d5367;
            margin-left: 7px; }
          .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer .top-sellers__item-footer-location .city {
            color: #4d5367;
            font-size: 14px;
            font-weight: 700; }
        .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer .top-sellers__item-footer-rate {
          display: flex;
          align-items: baseline; }
          .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer .top-sellers__item-footer-rate .numb {
            color: #6d7388;
            font-size: 19px;
            font-weight: 700;
            position: relative;
            top: .5px;
            margin-left: 3px; }
          .top-sellers__content .top-sellers__item .top-sellers__item-info .top-sellers__item-footer .top-sellers__item-footer-rate i {
            font-size: 13px;
            color: #1049dc; }
    .top-sellers__content .top-sellers__item--create-store {
      position: relative;
      box-shadow: 0 16px 16px -14px #60b277;
      transition: .3s; }
      .top-sellers__content .top-sellers__item--create-store .info {
        position: absolute;
        top: 49%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        display: flex;
        flex-direction: column;
        align-items: center; }
        .top-sellers__content .top-sellers__item--create-store .info i {
          font-size: 33px;
          color: #fff;
          margin-bottom: 10px; }
        .top-sellers__content .top-sellers__item--create-store .info__desc {
          color: #ffffff;
          font-size: 16px;
          font-weight: 700; }
      .top-sellers__content .top-sellers__item--create-store .link {
        position: absolute;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 15px;
        font-weight: 700;
        bottom: 16px;
        left: 16px;
        right: initial;
        top: initial; }
        .top-sellers__content .top-sellers__item--create-store .link i {
          font-size: 10px;
          color: #ffffff;
          margin-right: 6px;
          margin-top: 2px; }
      .top-sellers__content .top-sellers__item--create-store:hover {
        box-shadow: 0 8px 16px -14px #60b277; }
  .top-sellers--cat-one {
    margin-bottom: 57px; }
    .top-sellers--cat-one .top-sellers__header {
      margin-bottom: 36px;
      justify-content: flex-start; }
      .top-sellers--cat-one .top-sellers__header .top-sellers__header-cat {
        color: #646a7c;
        font-size: 15px;
        font-weight: 700;
        margin-right: 17px; }
      .top-sellers--cat-one .top-sellers__header .top-sellers__header-link {
        color: #454a5b;
        margin-right: auto; }
        .top-sellers--cat-one .top-sellers__header .top-sellers__header-link i {
          margin-right: 9px;
          color: #454a5b; }
    .top-sellers--cat-one .top-sellers__content {
      position: relative; }
      .top-sellers--cat-one .top-sellers__content:before {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        width: 20px;
        left: -20px;
        box-shadow: 14px 0 14px -9px rgba(0, 0, 0, 0.4);
        z-index: 100; }

.roadmap {
  margin-bottom: 115px; }
  .roadmap__header {
    display: flex;
    align-items: center;
    margin-bottom: 84px; }
    .roadmap__header i {
      font-size: 24px;
      color: #12b540;
      margin-left: 13px; }
    .roadmap__header .roadmap__header-title {
      color: #4d5367;
      font-size: 16px;
      font-weight: 700; }
  .roadmap__road {
    position: relative; }
    .roadmap__road .roadmap__road-item {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer; }
      .roadmap__road .roadmap__road-item > i {
        font-size: 11px;
        color: #1049dc;
        position: absolute;
        transition: .3s; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-icon {
        display: flex;
        align-items: center; }
        .roadmap__road .roadmap__road-item .roadmap__road-item-icon i {
          color: #4d5367;
          transition: .3s; }
          .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-user-plus {
            font-size: 21px;
            margin-left: 9px; }
          .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-cart {
            font-size: 23px;
            color: #4d5367;
            margin-left: 11px; }
          .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-transportation {
            font-size: 21px;
            margin-left: 9px; }
          .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-card {
            font-size: 19px;
            margin-left: 10px; }
        .roadmap__road .roadmap__road-item .roadmap__road-item-icon span {
          color: #4d5367;
          font-size: 17px;
          font-weight: 700;
          transition: .3s; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-desc {
        max-width: 120px;
        color: #6e7487;
        font-size: 13px;
        font-weight: 500;
        text-align: center; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-step {
        color: #1049dc;
        font-size: 71px;
        font-weight: 700;
        transition: .3s; }
      .roadmap__road .roadmap__road-item:hover > i {
        color: #14a13b; }
      .roadmap__road .roadmap__road-item:hover .roadmap__road-item-step {
        color: #14a13b; }
      .roadmap__road .roadmap__road-item:hover .roadmap__road-item-icon i, .roadmap__road .roadmap__road-item:hover .roadmap__road-item-icon span {
        color: #14a13b; }
      .roadmap__road .roadmap__road-item--step1 {
        top: -51px;
        right: 18%; }
        .roadmap__road .roadmap__road-item--step1 .roadmap__road-item-icon {
          margin-bottom: 16px; }
        .roadmap__road .roadmap__road-item--step1 .roadmap__road-item-desc {
          margin-bottom: 5px; }
      .roadmap__road .roadmap__road-item--step2 {
        right: 33.88%;
        top: -55px; }
        .roadmap__road .roadmap__road-item--step2 > i {
          right: -60%;
          top: 91px;
          transform: rotate(124deg); }
        .roadmap__road .roadmap__road-item--step2 .roadmap__road-item-step {
          margin-bottom: 9px; }
        .roadmap__road .roadmap__road-item--step2 .roadmap__road-item-icon {
          margin-bottom: 17px; }
      .roadmap__road .roadmap__road-item--step3 {
        right: 50.8%;
        top: -51px; }
        .roadmap__road .roadmap__road-item--step3 > i {
          right: -47px;
          top: 100px;
          transform: rotate(60deg); }
        .roadmap__road .roadmap__road-item--step3 .roadmap__road-item-icon {
          margin-bottom: 15px; }
        .roadmap__road .roadmap__road-item--step3 .roadmap__road-item-desc {
          margin-bottom: -4px; }
      .roadmap__road .roadmap__road-item--step4 {
        right: 69.9%;
        top: -49px; }
        .roadmap__road .roadmap__road-item--step4 > i {
          right: -60px;
          top: 76px;
          transform: rotate(115deg); }
        .roadmap__road .roadmap__road-item--step4 .roadmap__road-item-step {
          margin-bottom: 3px; }
        .roadmap__road .roadmap__road-item--step4 .roadmap__road-item-icon {
          margin-bottom: 15px; }

.amazing-offers {
  overflow: hidden;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 20px; }
  .amazing-offers__bg-image {
    position: absolute;
    top: 120px;
    left: 37px; }
  .amazing-offers__header {
    display: flex;
    align-items: center;
    margin-bottom: 37px; }
    .amazing-offers__header .amazing-offers__header-title {
      margin-left: 8px; }
      .amazing-offers__header .amazing-offers__header-title i {
        font-size: 31px;
        color: #ef473a; }
    .amazing-offers__header .amazing-offers__header-range {
      padding: 0 12px 0 9px;
      height: 21px;
      background-color: #ef473a;
      display: flex;
      align-items: center;
      border-radius: 60px;
      position: relative;
      top: -4px; }
      .amazing-offers__header .amazing-offers__header-range i {
        font-size: 12px;
        color: #ffffff; }
        .amazing-offers__header .amazing-offers__header-range i:nth-of-type(1) {
          margin-left: 7px; }
      .amazing-offers__header .amazing-offers__header-range .price {
        color: #ffffff;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -1.26px;
        margin-left: 6px; }
    .amazing-offers__header .amazing-offers__header-link {
      margin-right: auto;
      display: flex;
      align-items: center;
      color: #ef473a;
      font-size: 15px;
      font-weight: 700; }
      .amazing-offers__header .amazing-offers__header-link i {
        font-size: 4px;
        color: #ef473a;
        margin-top: 2px;
        margin-right: 19px; }
  .amazing-offers__content {
    position: relative;
    z-index: 100; }
    .amazing-offers__content .new-product__nav {
      width: 35px;
      height: 81px;
      background-color: rgba(69, 74, 91, 0.1); }
      .amazing-offers__content .new-product__nav .arrow:before {
        height: 2px;
        background-color: #454a5b; }
      .amazing-offers__content .new-product__nav .arrow:after {
        height: 2px;
        background-color: #454a5b; }
      .amazing-offers__content .new-product__nav .arrow__line {
        height: 2px;
        background-color: #454a5b; }
      .amazing-offers__content .new-product__nav.swiper-button-disabled .arrow__line {
        height: 6px; }
      .amazing-offers__content .new-product__nav.new-product__nav--next {
        left: 10px; }
      .amazing-offers__content .new-product__nav.new-product__nav--prev {
        right: 10px; }

.banners {
  background-image: linear-gradient(to top, #edf0f4 0, #fff 50%); }
  .banners .row {
    align-items: flex-end; }

/* single */
.breadcrumb {
  margin-bottom: 50px; }
  .breadcrumb__content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .breadcrumb__content .breadcrumb__items {
      display: flex;
      align-items: center; }
      .breadcrumb__content .breadcrumb__items .breadcrumb__items-item {
        color: #7b808e;
        font-size: 13px;
        font-weight: 400;
        margin-left: 15px; }
        .breadcrumb__content .breadcrumb__items .breadcrumb__items-item:after {
          content: '\e92a';
          font-family: 'icomoon';
          font-size: 4px;
          color: #c5c8d2;
          margin-right: 16px;
          pointer-events: none; }
        .breadcrumb__content .breadcrumb__items .breadcrumb__items-item:nth-last-of-type(1) {
          margin-left: 0; }
          .breadcrumb__content .breadcrumb__items .breadcrumb__items-item:nth-last-of-type(1):after {
            display: none; }
    .breadcrumb__content .breadcrumb__home {
      color: #383c48;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center; }
      .breadcrumb__content .breadcrumb__home i {
        font-size: 13px;
        color: #383c48;
        margin-right: 18px; }

.seller-head {
  height: 99px;
  border-radius: 60px 60px 0 60px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-right: 53px; }
  .seller-head:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #383c48;
    opacity: 0.5; }
  .seller-head__name {
    display: flex;
    flex-direction: column;
    z-index: 10; }
    .seller-head__name h2 {
      color: #ffffff;
      font-size: 19px;
      font-weight: 400;
      display: flex;
      align-items: center; }
      .seller-head__name h2 > i {
        position: relative;
        font-size: 19px;
        color: #12b540;
        margin-left: 6px; }
        .seller-head__name h2 > i i {
          position: absolute;
          font-size: 7px;
          color: #ffffff;
          left: 4.5px;
          top: 6px; }
    .seller-head__name .seller-head__name-title {
      opacity: 0.8;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px; }
  .seller-head__info {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: auto;
    margin-left: 17px;
    z-index: 10; }
    .seller-head__info .seller-head__info-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 30px; }
      .seller-head__info .seller-head__info-item i {
        color: #ffffff; }
        .seller-head__info .seller-head__info-item i.icon-transportation {
          font-size: 17px;
          margin-bottom: 15px; }
        .seller-head__info .seller-head__info-item i.icon-cube {
          font-size: 19px;
          margin-bottom: 14px; }
        .seller-head__info .seller-head__info-item i.icon-location {
          font-size: 21px;
          margin-bottom: 15px; }
      .seller-head__info .seller-head__info-item span {
        color: #ffffff;
        font-size: 12px;
        font-weight: 700; }
      .seller-head__info .seller-head__info-item:nth-last-of-type(1) {
        margin-left: 0; }
  .seller-head__logo {
    position: relative;
    z-index: 10;
    background-image: url("../img/curve-seller-head.svg");
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-end;
    top: 1px;
    width: 250px;
    height: 81px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px; }
  .seller-head__link {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translate(0, -50%);
    left: 41px;
    font-size: 23px;
    color: #ffffff; }

.product-info {
  margin-bottom: 24px; }
  .product-info__image {
    border-radius: 10px;
    position: relative;
    margin-bottom: 27px; }
    .product-info__image:after {
      content: '';
      position: absolute;
      top: 80px;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 10px;
      box-shadow: 0 0 40px -15px rgba(0, 0, 0, 0.25);
      z-index: 10; }
    .product-info__image:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 10px;
      background-color: #fff;
      z-index: 20; }
    .product-info__image .swiper-container {
      z-index: 100; }
    .product-info__image figure {
      height: 378px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      position: relative;
      z-index: 30; }
  .product-info__gallery {
    position: relative;
    padding: 15px;
    margin: -15px;
    direction: ltr; }
    .product-info__gallery .product-info__gallery-item {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid #e1e4ed;
      margin-right: 19px;
      transition: .3s;
      cursor: pointer; }
      .product-info__gallery .product-info__gallery-item figure {
        width: 100%;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .product-info__gallery .product-info__gallery-item:hover {
        box-shadow: 0 3px 8px rgba(77, 83, 103, 0.15);
        border-color: transparent; }
      .product-info__gallery .product-info__gallery-item:nth-of-type(1) {
        margin-right: 0; }
    .product-info__gallery .swiper-slide.swiper-slide-thumb-active .product-info__gallery-item {
      box-shadow: 0 3px 8px rgba(77, 83, 103, 0.15);
      border-color: transparent; }
    .product-info__gallery:before {
      content: '';
      position: absolute;
      right: -40px;
      width: 142px;
      top: 0;
      bottom: 0;
      pointer-events: none;
      background-image: linear-gradient(to left, #fff 18%, transparent);
      z-index: 20; }
  .product-info__information {
    margin-bottom: 20px; }
    .product-info__information .product-info__information-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 13px;
      margin-bottom: 43px; }
      .product-info__information .product-info__information-header .product-info__information-name a {
        color: #8c91a4;
        font-size: 14px;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 7px; }
      .product-info__information .product-info__information-header .product-info__information-name h2 {
        color: #383c48;
        font-size: 26px;
        font-weight: 700; }
      .product-info__information .product-info__information-header .product-info__information-share {
        display: flex;
        align-items: center;
        margin-bottom: 4px; }
        .product-info__information .product-info__information-header .product-info__information-share .share {
          cursor: pointer;
          position: relative; }
          .product-info__information .product-info__information-header .product-info__information-share .share__social {
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 13px 0 23px -13px rgba(0, 0, 0, 0.5);
            padding: 12px;
            position: absolute;
            left: 42px;
            top: 50%;
            transform: translate(0, -50%);
            opacity: 0;
            visibility: hidden;
            transition: .3s; }
            .product-info__information .product-info__information-header .product-info__information-share .share__social a {
              margin-left: 15px;
              display: flex;
              transition: .3s;
              transform: scale(0); }
              .product-info__information .product-info__information-header .product-info__information-share .share__social a:nth-of-type(1) {
                transition-delay: .075s; }
              .product-info__information .product-info__information-header .product-info__information-share .share__social a:nth-of-type(2) {
                transition-delay: .15s; }
              .product-info__information .product-info__information-header .product-info__information-share .share__social a:nth-of-type(3) {
                transition-delay: .225s; }
              .product-info__information .product-info__information-header .product-info__information-share .share__social a:nth-of-type(4) {
                transition-delay: .3s; }
              .product-info__information .product-info__information-header .product-info__information-share .share__social a:nth-last-of-type(1) {
                margin-left: 0; }
              .product-info__information .product-info__information-header .product-info__information-share .share__social a i {
                color: #383c48;
                font-size: 18px;
                margin-left: 0;
                transition: .3s; }
          .product-info__information .product-info__information-header .product-info__information-share .share.active .share__social {
            opacity: 1;
            visibility: visible; }
            .product-info__information .product-info__information-header .product-info__information-share .share.active .share__social a {
              transform: scale(1); }
        .product-info__information .product-info__information-header .product-info__information-share .heart {
          cursor: pointer; }
        .product-info__information .product-info__information-header .product-info__information-share i {
          font-size: 17px;
          color: #727888; }
          .product-info__information .product-info__information-header .product-info__information-share i:nth-of-type(1) {
            margin-left: 23px; }
    .product-info__information .product-info__information-perform {
      margin-bottom: 46px; }
      .product-info__information .product-info__information-perform .product-info__information-perform-title {
        position: relative;
        margin-bottom: 30px; }
        .product-info__information .product-info__information-perform .product-info__information-perform-title .title {
          color: #828797;
          font-size: 16px;
          font-weight: 400;
          display: inline-block;
          background-color: #fff;
          padding-left: 30px;
          position: relative;
          z-index: 20; }
        .product-info__information .product-info__information-perform .product-info__information-perform-title:before {
          content: '';
          right: 0;
          left: 0;
          top: 14px;
          position: absolute;
          border-bottom: 1px solid #eeeff3;
          z-index: 10; }
      .product-info__information .product-info__information-perform .product-info__information-perform-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap; }
        .product-info__information .product-info__information-perform .product-info__information-perform-content .item {
          display: flex;
          align-items: center; }
          .product-info__information .product-info__information-perform .product-info__information-perform-content .item > i {
            font-size: 8px;
            color: #ffffff;
            width: 20px;
            height: 20px;
            background-color: #12b540;
            border-radius: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 19px; }
          .product-info__information .product-info__information-perform .product-info__information-perform-content .item__rate {
            display: flex;
            align-items: center;
            margin-left: 17px; }
            .product-info__information .product-info__information-perform .product-info__information-perform-content .item__rate i {
              font-size: 13px;
              color: #12b540; }
          .product-info__information .product-info__information-perform .product-info__information-perform-content .item__rate-avg {
            color: #4d5367;
            font-size: 20px;
            font-weight: 700;
            margin-left: 3px; }
          .product-info__information .product-info__information-perform .product-info__information-perform-content .item__title {
            color: #4d505b;
            font-size: 14px;
            font-weight: 700; }
          .product-info__information .product-info__information-perform .product-info__information-perform-content .item__count {
            color: #c2c6d2;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center; }
            .product-info__information .product-info__information-perform .product-info__information-perform-content .item__count:before {
              content: '[';
              margin-left: 3px; }
            .product-info__information .product-info__information-perform .product-info__information-perform-content .item__count:after {
              content: ']';
              margin-right: 3px; }
          .product-info__information .product-info__information-perform .product-info__information-perform-content .item--rate .item__title {
            margin-left: 15px; }
    .product-info__information .product-info__information-properties {
      border-top: 1px solid #eeeff3;
      padding-top: 46px; }
      .product-info__information .product-info__information-properties .properties {
        position: relative; }
        .product-info__information .product-info__information-properties .properties__title {
          position: absolute;
          color: #828797;
          font-size: 16px;
          font-weight: 400;
          right: 0;
          top: -61px;
          background-color: #fff;
          padding-left: 20px; }
        .product-info__information .product-info__information-properties .properties__items {
          display: flex;
          flex-wrap: wrap; }
        .product-info__information .product-info__information-properties .properties__items-item {
          flex: 1 0 50%;
          display: flex;
          flex-direction: column;
          margin-bottom: 26px; }
          .product-info__information .product-info__information-properties .properties__items-item .title {
            color: #5e6370;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 6px; }
          .product-info__information .product-info__information-properties .properties__items-item .value {
            color: #383c48;
            font-size: 13px;
            font-weight: 500; }
      .product-info__information .product-info__information-properties .description__title {
        position: absolute;
        color: #828797;
        font-size: 16px;
        font-weight: 400;
        right: 15px;
        top: -61px;
        background-color: #fff;
        padding-left: 15px;
        padding-right: 15px; }
      .product-info__information .product-info__information-properties .description p {
        color: #4d505b;
        font-size: 13px;
        font-weight: 500;
        line-height: 41px;
        text-align: justify;
        margin-top: -13px; }
  .product-info__add-cart {
    height: 62px;
    box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
    padding: 0 30px 0 21px;
    background-color: #12b540;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 19px;
    transition: .3s; }
    .product-info__add-cart i {
      font-size: 22px;
      color: #ffffff;
      margin-left: 19px; }
    .product-info__add-cart .title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700; }
    .product-info__add-cart .title-responsive {
      display: none;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700; }
    .product-info__add-cart .price {
      display: flex;
      align-items: center;
      margin-right: auto; }
      .product-info__add-cart .price ins {
        text-decoration: none;
        color: #ffffff;
        font-size: 23px;
        font-weight: 700;
        margin-left: 6px; }
      .product-info__add-cart .price span {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700; }
    .product-info__add-cart:hover {
      box-shadow: 0 1px 8px rgba(101, 106, 120, 0.35); }
  .product-info__conversation {
    height: 62px;
    box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
    background-color: #f9fafb;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 30px 0 27px;
    transition: .3s; }
    .product-info__conversation i {
      font-size: 20px;
      color: #747b8f;
      margin-left: 18px; }
    .product-info__conversation .title {
      color: #656b7a;
      font-size: 15px;
      font-weight: 700; }
    .product-info__conversation .status {
      margin-right: auto;
      color: #a5abbe;
      font-size: 15px;
      font-weight: 700; }
    .product-info__conversation:hover {
      box-shadow: 0 1px 8px rgba(101, 106, 120, 0.35); }
  .product-info__meta {
    border-top: 1px solid #eceef3;
    border-bottom: 1px solid #eceef3;
    padding-bottom: 28px;
    padding-top: 32px;
    margin-top: 14px;
    margin-bottom: 26px; }
    .product-info__meta .product-info__meta-color {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative; }
      .product-info__meta .product-info__meta-color .title {
        position: absolute;
        color: #828797;
        font-size: 18px;
        font-weight: 400;
        background-color: #fff;
        padding-left: 20px;
        top: -48px;
        right: 0; }
      .product-info__meta .product-info__meta-color .item {
        height: 41px;
        margin-bottom: 8px;
        box-shadow: 0 1px 4px rgba(101, 106, 120, 0.4);
        padding: 0 16px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 21px;
        transition: .3s;
        display: none; }
        .product-info__meta .product-info__meta-color .item:nth-last-of-type(2) {
          margin-left: 18px; }
        .product-info__meta .product-info__meta-color .item:nth-last-of-type(1) {
          margin-left: 0; }
        .product-info__meta .product-info__meta-color .item:nth-of-type(1) {
          display: flex; }
        .product-info__meta .product-info__meta-color .item:nth-of-type(2) {
          display: flex; }
        .product-info__meta .product-info__meta-color .item:nth-of-type(3) {
          display: flex; }
        .product-info__meta .product-info__meta-color .item:nth-of-type(4) {
          display: flex; }
        .product-info__meta .product-info__meta-color .item i {
          font-size: 9px;
          color: #ffffff;
          opacity: 0.4;
          transition: .3s; }
        .product-info__meta .product-info__meta-color .item__name {
          color: #ffffff;
          font-size: 0;
          font-weight: 700;
          margin-bottom: 4px;
          opacity: 0;
          transition: .3s; }
        .product-info__meta .product-info__meta-color .item.active {
          padding: 0 20px; }
          .product-info__meta .product-info__meta-color .item.active i {
            font-size: 12px;
            opacity: 1;
            margin-left: 20px; }
          .product-info__meta .product-info__meta-color .item.active .item__name {
            font-size: 17px;
            opacity: 1; }
        .product-info__meta .product-info__meta-color .item--more {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 43px;
          height: 41px;
          border: 1px solid #dfe3e7;
          border-radius: 4px;
          box-shadow: none; }
          .product-info__meta .product-info__meta-color .item--more i {
            font-size: 19px;
            color: #383c48;
            opacity: 1; }
        .product-info__meta .product-info__meta-color .item:hover {
          box-shadow: 0 1px 10px rgba(101, 106, 120, 0.4); }
    .product-info__meta .product-info__meta-item {
      position: relative; }
      .product-info__meta .product-info__meta-item .title {
        position: absolute;
        background-color: #fff;
        color: #828797;
        font-size: 18px;
        font-weight: 400;
        padding-left: 35px;
        padding-right: 15px;
        top: -48px;
        right: -15px; }
      .product-info__meta .product-info__meta-item .selectize-control .selectize-input {
        box-shadow: none;
        border-radius: 5px;
        padding: 8px 15px;
        height: 41px;
        padding-top: 9px; }
        .product-info__meta .product-info__meta-item .selectize-control .selectize-input:after {
          content: '\e90e';
          margin-top: 0;
          font-family: 'icomoon';
          font-size: 9px;
          color: #383c48;
          width: initial;
          height: initial;
          border-style: none;
          border-width: 0;
          border-color: transparent;
          transform: translate(0, -50%) rotateX(0);
          transition: .3s; }
        .product-info__meta .product-info__meta-item .selectize-control .selectize-input .item {
          color: #383c48;
          font-size: 14px;
          font-weight: 700; }
        .product-info__meta .product-info__meta-item .selectize-control .selectize-input.dropdown-active:after {
          transform: translate(0, -50%) rotateX(180deg); }
      .product-info__meta .product-info__meta-item .selectize-control .selectize-dropdown .option {
        cursor: pointer; }
      .product-info__meta .product-info__meta-item--size {
        margin-bottom: 80px;
        position: relative; }
        .product-info__meta .product-info__meta-item--size .product-info__meta-entry {
          height: 62px;
          box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
          padding: 0 30px 0 21px;
          background-color: #12b540;
          border-radius: 4px;
          width: 100%;
          display: none;
          margin-bottom: 19px;
          transition: .3s;
          color: #ffffff;
          font-size: 16px;
          font-weight: 700; }
        .product-info__meta .product-info__meta-item--size .close {
          display: none; }
        .product-info__meta .product-info__meta-item--size:before {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: -48px;
          border-bottom: 1px solid #eceef3; }
        .product-info__meta .product-info__meta-item--size .title {
          position: absolute;
          background-color: #fff;
          color: #828797;
          font-size: 18px;
          font-weight: 400;
          padding-left: 35px;
          padding-right: 15px;
          bottom: -11px;
          top: initial;
          right: -15px; }
  .product-info__price {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eceef3;
    padding-bottom: 38px;
    flex-wrap: wrap; }
    .product-info__price .product-info__price-features .product-info__price-features-items {
      display: flex;
      align-items: center; }
      .product-info__price .product-info__price-features .product-info__price-features-items .item {
        display: flex;
        align-items: center;
        margin-left: 40px; }
        .product-info__price .product-info__price-features .product-info__price-features-items .item i {
          color: #888d9f;
          margin-left: 15px; }
          .product-info__price .product-info__price-features .product-info__price-features-items .item i.icon-transportation {
            font-size: 18px; }
          .product-info__price .product-info__price-features .product-info__price-features-items .item i.icon-card {
            font-size: 16px; }
          .product-info__price .product-info__price-features .product-info__price-features-items .item i.icon-cube {
            font-size: 21px; }
          .product-info__price .product-info__price-features .product-info__price-features-items .item i.icon-comment {
            font-size: 18px; }
        .product-info__price .product-info__price-features .product-info__price-features-items .item__title {
          color: #686e81;
          font-size: 14px;
          font-weight: 700; }
        .product-info__price .product-info__price-features .product-info__price-features-items .item:nth-last-of-type(1) {
          margin-left: 0; }
    .product-info__price .product-info__price-items {
      margin-right: auto; }
      .product-info__price .product-info__price-items .price {
        display: flex;
        align-items: baseline;
        margin-bottom: 2px; }
        .product-info__price .product-info__price-items .price ins {
          color: #383c48;
          font-size: 30px;
          font-weight: 700;
          text-decoration: none;
          margin-left: 6px; }
        .product-info__price .product-info__price-items .price__unit {
          color: #383c48;
          font-size: 14px;
          font-weight: 700;
          position: relative;
          top: -5px; }
      .product-info__price .product-info__price-items .discount {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .product-info__price .product-info__price-items .discount__percent {
          display: flex;
          align-items: center;
          height: 30px;
          background-color: #ef473a;
          border-radius: 0 60px 60px 60px;
          padding: 0 14px 0 12px;
          color: #ffffff;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: -0.94px;
          position: relative; }
          .product-info__price .product-info__price-items .discount__percent i {
            font-size: 11px;
            color: #ffffff;
            margin-right: 1px; }
          .product-info__price .product-info__price-items .discount__percent:after {
            content: '';
            position: absolute;
            top: -15px;
            height: 15px;
            right: 20px;
            left: 0;
            border-radius: 60px 60px 60px 0;
            background-color: #ef473a;
            z-index: 10; }
          .product-info__price .product-info__price-items .discount__percent:before {
            content: '';
            position: absolute;
            top: -16px;
            height: 16px;
            right: 19px;
            left: 0;
            border-radius: 0 60px 60px 60px;
            background-color: #fff;
            z-index: 20; }
        .product-info__price .product-info__price-items .discount__price {
          color: #9094a1;
          font-size: 17px;
          font-weight: 400;
          margin-left: 14px; }

.price-pieces {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 45px;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
  height: 104px; }
  .price-pieces:before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #eceef3;
    bottom: 13px; }
  .price-pieces .title {
    bottom: 3px !important; }
  .price-pieces__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px; }
    .price-pieces__item:nth-last-of-type(1) {
      margin-left: 0; }
    .price-pieces__item .price-pieces__item-piece {
      color: #4d505b;
      font-size: 14px;
      font-weight: 500; }
    .price-pieces__item .price-pieces__item-price {
      color: #383c48;
      font-size: 17px;
      font-weight: 700;
      display: flex;
      align-items: center; }
      .price-pieces__item .price-pieces__item-price ins {
        text-decoration: none;
        margin-left: 5px; }
      .price-pieces__item .price-pieces__item-price .unit {
        font-size: 13px; }
    .price-pieces__item.active .price-pieces__item-piece {
      color: #12b540; }
    .price-pieces__item.active .price-pieces__item-price {
      color: #12c841; }

.variations__tab {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .variations__tab .variations__tab-size {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 41px;
    border: 1px solid #dfe3e7;
    border-radius: 4px;
    box-shadow: none;
    font-size: 15px;
    font-weight: 600;
    color: #383c48;
    margin-left: 10px;
    cursor: pointer;
    transition: .3s; }
    .variations__tab .variations__tab-size:nth-last-of-type(1) {
      margin-left: 0; }
    .variations__tab .variations__tab-size:hover {
      color: #dfe3e7;
      background-color: #383c48;
      border-color: transparent; }
    .variations__tab .variations__tab-size.active {
      color: #dfe3e7;
      background-color: #383c48;
      border-color: transparent; }

.variations__tabc .variations__tabc-item .variations__tabc-color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eceef3; }
  .variations__tabc .variations__tabc-item .variations__tabc-color .color {
    height: 41px;
    width: 41px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(101, 106, 120, 0.4); }
  .variations__tabc .variations__tabc-item .variations__tabc-color .price {
    color: #383c48;
    font-weight: 700;
    display: flex;
    align-items: center; }
    .variations__tabc .variations__tabc-item .variations__tabc-color .price__numb {
      font-size: 20px;
      text-decoration: none;
      margin-left: 5px; }
    .variations__tabc .variations__tabc-item .variations__tabc-color .price__unit {
      font-size: 14px; }
  .variations__tabc .variations__tabc-item .variations__tabc-color .number {
    display: flex;
    align-items: center; }
    .variations__tabc .variations__tabc-item .variations__tabc-color .number input {
      width: 60px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      height: 25px;
      text-align: center;
      color: #818181; }
    .variations__tabc .variations__tabc-item .variations__tabc-color .number__item {
      cursor: pointer;
      height: 41px;
      width: 41px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .variations__tabc .variations__tabc-item .variations__tabc-color .number__item i {
        font-size: 22px;
        color: #39a92a; }

.variations-count {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #eceef3;
  margin-bottom: 25px;
  flex-wrap: wrap; }
  .variations-count__pieces {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px; }
    .variations-count__pieces .variations-count__pieces-price {
      display: flex;
      align-items: center;
      margin-left: 25px; }
      .variations-count__pieces .variations-count__pieces-price .price {
        text-decoration: none;
        color: #4d505b;
        font-size: 16px;
        font-weight: 500;
        margin-left: 5px; }
      .variations-count__pieces .variations-count__pieces-price .unit {
        color: #4d505b;
        font-size: 13px;
        font-weight: 500; }
    .variations-count__pieces .variations-count__pieces-count {
      display: flex;
      align-items: center;
      color: #4d505b;
      font-size: 14px;
      font-weight: 500; }
      .variations-count__pieces .variations-count__pieces-count .count {
        margin-left: 5px;
        font-size: 16px; }
  .variations-count__shipping {
    color: #4d505b;
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 15px; }
    .variations-count__shipping .variations-count__shipping-price {
      text-decoration: none; }
    .variations-count__shipping .variations-count__shipping-unit {
      font-size: 14px; }
  .variations-count__total {
    color: #4d505b;
    font-size: 16px;
    font-weight: 700; }
    .variations-count__total .variations-count__total-price {
      color: #4d505b;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none; }
    .variations-count__total .variations-count__total-unit {
      color: #4d505b;
      font-size: 14px;
      font-weight: 500; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.seller-info {
  margin-bottom: 50px;
  margin-top: -660px; }
  .seller-info__title h2 {
    opacity: 0.8;
    color: #5f6578;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 19px; }
  .seller-info__content {
    padding-bottom: 38px;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat; }
    .seller-info__content:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #383c48;
      opacity: 0.8;
      z-index: 20; }
    .seller-info__content .seller-info__content-logo {
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 51px;
      margin-bottom: 23px; }
      .seller-info__content .seller-info__content-logo figure {
        position: relative; }
        .seller-info__content .seller-info__content-logo figure > i {
          font-size: 19px;
          color: #12b540;
          right: -4px;
          top: 43px;
          position: absolute; }
          .seller-info__content .seller-info__content-logo figure > i i {
            font-size: 7px;
            color: #ffffff;
            position: absolute;
            top: 50%;
            transform: translate(50%, -50%);
            right: 50%; }
    .seller-info__content .seller-info__content-info {
      position: relative;
      z-index: 20;
      margin-bottom: 28px; }
      .seller-info__content .seller-info__content-info .seller-info__content-title {
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 11px; }
      .seller-info__content .seller-info__content-info .seller-info__content-url {
        opacity: 0.5;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.48px;
        display: block; }
    .seller-info__content .seller-info__content-features {
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 29px; }
      .seller-info__content .seller-info__content-features .item {
        width: 36px;
        height: 36px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        cursor: pointer;
        position: relative; }
        .seller-info__content .seller-info__content-features .item:nth-last-of-type(1) {
          margin-left: 0; }
        .seller-info__content .seller-info__content-features .item__tooltip {
          position: absolute;
          top: -24px;
          transition: .3s;
          opacity: 0;
          visibility: hidden;
          transform: scale(0.9) translate(-50%, 0);
          width: 109px;
          height: 25px;
          background-color: #ffffff;
          color: #383c48;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 50%;
          border-radius: 60px; }
          .seller-info__content .seller-info__content-features .item__tooltip:before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 2px;
            position: absolute;
            bottom: -3.5px;
            left: 50%;
            transform: translate(-50%, 0) rotate(45deg);
            background-color: #fff; }
        .seller-info__content .seller-info__content-features .item:hover .item__tooltip {
          transform: scale(1) translate(-50%, 0);
          opacity: 1;
          visibility: visible; }
        .seller-info__content .seller-info__content-features .item i {
          font-size: 19px;
          color: #ffffff; }
          .seller-info__content .seller-info__content-features .item i.icon-comment {
            font-size: 17px; }
          .seller-info__content .seller-info__content-features .item i.icon-heart {
            font-size: 18px; }
        .seller-info__content .seller-info__content-features .item--umbrella {
          background-color: #39a92a; }
        .seller-info__content .seller-info__content-features .item--guard {
          background-color: #d48912; }
        .seller-info__content .seller-info__content-features .item--comment {
          background-color: #3482da; }
        .seller-info__content .seller-info__content-features .item--heart {
          background-color: #2ebac7; }
        .seller-info__content .seller-info__content-features .item--gift {
          background-color: #fb4b63; }
        .seller-info__content .seller-info__content-features .item--more {
          color: #ffffff;
          font-size: 22px;
          font-weight: 400;
          letter-spacing: -1.32px;
          background-color: #12b540; }
    .seller-info__content .seller-info__content-desc {
      position: relative;
      z-index: 20;
      padding: 0 30px;
      margin-bottom: 27px; }
      .seller-info__content .seller-info__content-desc p {
        color: #ffffff;
        font-size: 13px;
        font-weight: 700;
        line-height: 27px;
        text-align: justify; }
    .seller-info__content .seller-info__content-statistics {
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 45px; }
      .seller-info__content .seller-info__content-statistics .items {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 24px; }
        .seller-info__content .seller-info__content-statistics .items .item {
          width: 77px;
          height: 77px;
          background: url("../img/bg-seller-info.png");
          clip-path: circle(40%);
          position: relative;
          margin-bottom: 6px; }
          .seller-info__content .seller-info__content-statistics .items .item__inside {
            background-color: #383c48;
            opacity: 0.6;
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            clip-path: circle(47%); }
          .seller-info__content .seller-info__content-statistics .items .item__rate {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: 24px;
            font-weight: 700; }
        .seller-info__content .seller-info__content-statistics .items__title {
          color: #ffffff;
          font-size: 13px;
          font-weight: 700; }
        .seller-info__content .seller-info__content-statistics .items:nth-last-of-type(1) {
          margin-left: 0; }
    .seller-info__content .seller-info__content-meta {
      position: relative;
      z-index: 20;
      padding: 0 28px;
      margin-bottom: 48px; }
      .seller-info__content .seller-info__content-meta .item {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px; }
        .seller-info__content .seller-info__content-meta .item__title {
          opacity: 0.7;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          order: 0;
          margin-left: 15px; }
        .seller-info__content .seller-info__content-meta .item__value {
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          order: 2;
          margin-right: 13px; }
          .seller-info__content .seller-info__content-meta .item__value--rate {
            display: flex;
            align-items: center; }
            .seller-info__content .seller-info__content-meta .item__value--rate .count {
              opacity: 0.6;
              color: #ffffff;
              font-size: 14px;
              font-weight: 700;
              display: flex;
              align-items: center;
              margin-left: 11px; }
              .seller-info__content .seller-info__content-meta .item__value--rate .count:before {
                content: '[';
                margin-left: 3px; }
              .seller-info__content .seller-info__content-meta .item__value--rate .count:after {
                content: ']';
                margin-right: 3px; }
            .seller-info__content .seller-info__content-meta .item__value--rate .rate {
              color: #ffffff;
              font-size: 20px;
              font-weight: 700;
              margin-left: 3px; }
            .seller-info__content .seller-info__content-meta .item__value--rate i {
              font-size: 13px;
              color: #12b540; }
        .seller-info__content .seller-info__content-meta .item:before {
          content: '';
          border-bottom: 1px solid #ffffff;
          opacity: .3;
          order: 1;
          flex-grow: 1;
          position: relative;
          top: 1px; }
        .seller-info__content .seller-info__content-meta .item--rate {
          margin-bottom: 15px; }
        .seller-info__content .seller-info__content-meta .item--membership {
          margin-bottom: 15px; }
    .seller-info__content .seller-info__content-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 20;
      padding: 0 28px; }
      .seller-info__content .seller-info__content-footer .item {
        display: flex;
        align-items: center; }
        .seller-info__content .seller-info__content-footer .item i {
          color: #ffffff;
          background-color: #12b540; }
          .seller-info__content .seller-info__content-footer .item i.icon-page-customer {
            font-size: 16px; }
          .seller-info__content .seller-info__content-footer .item i.icon-pluse-border {
            font-size: 22px; }
        .seller-info__content .seller-info__content-footer .item span {
          color: #ffffff;
          font-weight: 700;
          font-size: 14px; }
        .seller-info__content .seller-info__content-footer .item--follow i {
          border-radius: 60px;
          margin-right: 14px; }
        .seller-info__content .seller-info__content-footer .item--page i {
          padding: 3px;
          border-radius: 4px;
          margin-left: 12px; }

.progress-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50%; }

.product-content {
  margin-bottom: 67px; }
  .product-content__nav {
    height: 77px;
    background-color: #f5f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-bottom: 40px; }
    .product-content__nav .product-content__nav-item {
      color: #8c91a0;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-left: 100px;
      cursor: pointer;
      transition: .3s; }
      .product-content__nav .product-content__nav-item i {
        color: #8c91a0;
        font-size: 19px;
        margin-left: 14px;
        transition: .3s; }
        .product-content__nav .product-content__nav-item i.icon-cube {
          font-size: 21px; }
        .product-content__nav .product-content__nav-item i.icon-user {
          margin-left: 17px; }
      .product-content__nav .product-content__nav-item .count {
        margin-right: 8px; }
      .product-content__nav .product-content__nav-item:nth-last-of-type(1) {
        margin-left: 0; }
      .product-content__nav .product-content__nav-item:hover {
        color: #383c48; }
        .product-content__nav .product-content__nav-item:hover i {
          color: #383c48; }
      .product-content__nav .product-content__nav-item.active {
        color: #383c48; }
        .product-content__nav .product-content__nav-item.active i {
          color: #383c48; }
  .product-content__contents .product-content__contents-content [data-target] {
    display: none; }
    .product-content__contents .product-content__contents-content [data-target]:nth-of-type(1) {
      display: block; }
  .product-content__contents .product-content__contents-content h3 {
    color: #383c48;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    cursor: pointer;
    position: relative; }
    .product-content__contents .product-content__contents-content h3:before {
      content: '';
      width: 12px;
      height: 2px;
      background-color: #aeb9c5;
      margin-left: 18px;
      margin-top: 3px; }
    .product-content__contents .product-content__contents-content h3:after {
      content: '';
      width: 2px;
      height: 0;
      background-color: #aeb9c5;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translate(0, -40%);
      transition: .3s; }
    .product-content__contents .product-content__contents-content h3.active:after {
      height: 12px; }
  .product-content__contents .product-content__contents-content figure {
    margin-bottom: 14px;
    margin-top: 14px; }
  .product-content__contents .product-content__contents-content p {
    color: #383c48;
    font-size: 14px;
    font-weight: 500;
    line-height: 41px;
    text-align: justify;
    margin-bottom: 33px; }
  .product-content .features {
    margin-bottom: 41px; }
    .product-content .features h2 {
      color: #383c48;
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 12px; }
    .product-content .features table {
      width: 100%; }
      .product-content .features table td {
        padding: 19px 0; }
    .product-content .features__key {
      color: #848a9e;
      font-size: 13px;
      font-weight: 500;
      max-width: 173px;
      width: 173px;
      border-bottom: 1px solid #dde0e9;
      border-left: 46px solid #fff; }
    .product-content .features__value {
      color: #383c48;
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid #dde0e9; }

.other-products {
  background-color: #f5f7f9;
  border-radius: 0 15px 15px 0;
  margin-left: -64vh;
  overflow: hidden;
  padding-bottom: 33px;
  position: relative;
  margin-bottom: 44px; }
  .other-products:before {
    content: '';
    top: 40px;
    bottom: 0;
    width: 40px;
    right: -40px;
    position: absolute;
    box-shadow: -24px 0px 30px -18px rgba(0, 0, 0, 0.42);
    z-index: 100;
    pointer-events: none; }
  .other-products .next {
    position: absolute;
    right: 41px;
    top: 50%;
    transform: translate(0, -50%);
    width: 46px;
    height: 41px;
    background-color: #12b540;
    border-radius: 60px 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 110;
    padding-left: 4px;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: .3s; }
    .other-products .next i {
      font-size: 17px;
      color: #ffffff; }
    .other-products .next.swiper-button-disabled {
      opacity: 0;
      visibility: hidden; }
  .other-products .prev {
    position: absolute;
    left: calc(52vh - 45px);
    top: 50%;
    transform: translate(0, -50%);
    width: 46px;
    height: 41px;
    background-color: #12b540;
    border-radius: 0 60px 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 110;
    padding-right: 4px;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: .3s; }
    .other-products .prev i {
      font-size: 17px;
      color: #ffffff; }
    .other-products .prev.swiper-button-disabled {
      opacity: 0;
      visibility: hidden; }
  .other-products__header {
    display: flex;
    align-items: center;
    padding-right: 33px;
    padding-top: 28px;
    margin-bottom: 35px;
    justify-content: space-between; }
    .other-products__header > h2 {
      color: #383c48;
      font-size: 15px;
      font-weight: 700; }
    .other-products__header .other-products__header-info {
      display: flex;
      align-items: center; }
      .other-products__header .other-products__header-info .other-products__header-logo {
        margin-left: 23px; }
      .other-products__header .other-products__header-info .other-products__header-title h2 {
        color: #383c48;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 4px; }
      .other-products__header .other-products__header-info .other-products__header-title span {
        color: #737889;
        font-size: 13px;
        font-weight: 700; }
    .other-products__header .other-products__header-link {
      color: #6f7484;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      position: relative;
      right: -64vh; }
      .other-products__header .other-products__header-link i {
        font-size: 3px;
        color: #828695;
        margin-right: 13px; }
  .other-products--other-sellers .other-products__header {
    margin-bottom: 44px;
    padding-top: 43px; }

.comments {
  margin-bottom: 53px; }
  .comments__list {
    margin-bottom: 47px; }
    .comments__list .comments__list-item {
      border-bottom: 1px solid #e1e5ea;
      padding-bottom: 32px;
      margin-bottom: 31px; }
      .comments__list .comments__list-item .comments__list-header {
        display: flex;
        align-items: center;
        margin-bottom: 22px; }
        .comments__list .comments__list-item .comments__list-header .comments__list-avatar {
          border-radius: 60px;
          margin-left: 16px; }
        .comments__list .comments__list-item .comments__list-header .comments__list-name {
          color: #383c48;
          font-size: 17px;
          font-weight: 700;
          margin-left: 18px; }
        .comments__list .comments__list-item .comments__list-header .comments__list-rule {
          height: 22px;
          display: flex;
          align-items: center;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          border-radius: 8px 0 8px 0;
          padding: 0 10px 0 8px; }
          .comments__list .comments__list-item .comments__list-header .comments__list-rule--buying {
            background-color: #12b540; }
          .comments__list .comments__list-item .comments__list-header .comments__list-rule--seller {
            background-color: #3a67dc; }
      .comments__list .comments__list-item .comments__list-text {
        color: #383c48;
        font-size: 13px;
        font-weight: 500;
        line-height: 35px;
        margin-bottom: 18px;
        text-align: justify; }
      .comments__list .comments__list-item .comments__list-footer {
        display: flex;
        align-items: center; }
        .comments__list .comments__list-item .comments__list-footer .comments__list-like {
          display: flex;
          align-items: center;
          margin-left: 47px; }
          .comments__list .comments__list-item .comments__list-footer .comments__list-like .like {
            width: 42px;
            height: 41px;
            box-shadow: 0 1px 1px rgba(50, 59, 82, 0.37);
            background-color: #f5f7f9;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 24px;
            transition: .3s; }
            .comments__list .comments__list-item .comments__list-footer .comments__list-like .like i {
              font-size: 19px;
              color: #383c48; }
            .comments__list .comments__list-item .comments__list-footer .comments__list-like .like:hover {
              box-shadow: 0 1px 8px rgba(50, 59, 82, 0.37); }
          .comments__list .comments__list-item .comments__list-footer .comments__list-like .count {
            color: #383c48;
            font-size: 19px;
            font-weight: 700;
            margin-left: 7px; }
          .comments__list .comments__list-item .comments__list-footer .comments__list-like .title {
            color: #383c48;
            font-size: 13px;
            font-weight: 700; }
        .comments__list .comments__list-item .comments__list-footer .comments__list-reply {
          display: flex;
          align-items: center; }
          .comments__list .comments__list-item .comments__list-footer .comments__list-reply .reply {
            width: 42px;
            height: 41px;
            box-shadow: 0 1px 1px rgba(50, 59, 82, 0.37);
            background-color: #f5f7f9;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 24px;
            transition: .3s; }
            .comments__list .comments__list-item .comments__list-footer .comments__list-reply .reply i {
              font-size: 11px;
              color: #383c48; }
            .comments__list .comments__list-item .comments__list-footer .comments__list-reply .reply:hover {
              box-shadow: 0 1px 8px rgba(50, 59, 82, 0.37); }
          .comments__list .comments__list-item .comments__list-footer .comments__list-reply .title {
            color: #383c48;
            font-size: 14px;
            font-weight: 700; }
        .comments__list .comments__list-item .comments__list-footer .comments__list-date {
          color: #777c89;
          font-size: 12px;
          font-weight: 400;
          margin-right: auto; }
      .comments__list .comments__list-item--reply {
        background-color: #f5f7f9;
        border-radius: 0 0 0 20px;
        padding: 32px 40px;
        padding-bottom: 32px !important;
        margin-top: 33px;
        margin-bottom: 0;
        position: relative;
        border-bottom: none; }
        .comments__list .comments__list-item--reply:before {
          content: '\e914';
          font-family: 'icomoon';
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 13px;
          color: #b4bacc; }
        .comments__list .comments__list-item--reply .comments__list-footer .comments__list-like .like {
          background-color: #ffffff; }
        .comments__list .comments__list-item--reply .comments__list-footer .comments__list-reply .reply {
          background-color: #ffffff; }
      .comments__list .comments__list-item.has-childe {
        border-bottom: none;
        padding-bottom: 0; }
      .comments__list .comments__list-item:nth-last-of-type(1) {
        border-bottom: none;
        padding-bottom: 0; }
  .comments__form {
    background-color: #f5f7f9;
    border-radius: 14px;
    overflow: hidden; }
    .comments__form textarea {
      background-color: #f5f7f9;
      width: 100%;
      resize: none;
      height: 181px;
      padding-top: 33px;
      padding-right: 33px;
      padding-left: 30px; }
      .comments__form textarea::placeholder {
        color: #787e90;
        font-size: 17px;
        font-weight: 400;
        opacity: 1; }
    .comments__form .comments__form-btn {
      display: flex;
      align-items: center;
      padding: 0 23px 25px 23px; }
      .comments__form .comments__form-btn .item {
        display: flex;
        align-items: center;
        width: 281px;
        background-color: #fff;
        height: 49px;
        box-shadow: 0 2px 2px rgba(102, 119, 136, 0.13);
        padding: 0 18px;
        border-radius: 5px;
        margin-left: 9px; }
        .comments__form .comments__form-btn .item i {
          font-size: 17px;
          color: #777c8c;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #eef1f4;
          padding-left: 17px;
          margin-left: 19px; }
        .comments__form .comments__form-btn .item input {
          color: #828796;
          font-size: 15px;
          font-weight: 400; }
          .comments__form .comments__form-btn .item input::placeholder {
            color: #828796;
            font-size: 15px;
            font-weight: 400;
            opacity: 1; }
      .comments__form .comments__form-btn button {
        height: 49px;
        box-shadow: 0 2px 2px rgba(102, 119, 136, 0.13);
        background-color: #12b540;
        width: 141px;
        color: #ffffff;
        font-size: 17px;
        font-weight: 700;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 20px; }
        .comments__form .comments__form-btn button i {
          font-size: 13px;
          color: #ffffff; }

/* page */
.header-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid #e1e3ea;
  padding-bottom: 65px;
  margin-bottom: 32px; }
  .header-page__title {
    width: 88px;
    height: 88px;
    box-shadow: inset 0 5px 5px rgba(223, 226, 235, 0.75);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    margin-bottom: 13px; }
    .header-page__title i {
      width: 58px;
      height: 58px;
      background-color: #12b540;
      font-size: 21px;
      color: #ffffff;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .header-page h2 {
    color: #383c48;
    font-size: 20px;
    font-weight: 700;
    text-align: center; }
  .header-page figure {
    position: absolute;
    left: 17px; }

.page-content {
  margin-bottom: 62px; }
  .page-content p {
    color: #383c48;
    font-size: 15px;
    font-weight: 700;
    line-height: 41px;
    text-align: justify; }

.location {
  display: flex;
  margin-top: 70px;
  margin-bottom: 30px; }
  .location__map {
    border-radius: 0 15px 15px 0;
    overflow: hidden;
    margin-left: 27px; }
  .location__items {
    position: relative;
    cursor: pointer; }
    .location__items:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 100px;
      right: 14px;
      border-left: 1px solid #e1e3ea;
      z-index: 10; }
    .location__items .location__items-item {
      display: flex;
      margin-bottom: 30px; }
      .location__items .location__items-item i {
        font-size: 25px;
        color: #454a5b;
        margin-left: 20px;
        padding-top: 4px;
        width: 30px;
        text-align: center;
        transition: .3s; }
        .location__items .location__items-item i:before {
          position: relative;
          background-color: #fff;
          z-index: 20;
          padding-top: 15px;
          padding-bottom: 15px; }
        .location__items .location__items-item i.icon-mail {
          font-size: 19px; }
      .location__items .location__items-item .location__items-info {
        display: flex;
        flex-direction: column; }
        .location__items .location__items-item .location__items-info .location__items-info-title {
          color: #383c48;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px; }
        .location__items .location__items-item .location__items-info .location__items-info-value {
          color: #454a5b;
          font-size: 15px;
          font-weight: 700; }
          .location__items .location__items-item .location__items-info .location__items-info-value .phone {
            display: flex;
            align-items: first baseline;
            margin-left: 42px; }
            .location__items .location__items-item .location__items-info .location__items-info-value .phone:nth-last-of-type(1) {
              margin-left: 0; }
            .location__items .location__items-item .location__items-info .location__items-info-value .phone__numb {
              color: #454a5b;
              font-size: 25px;
              font-weight: 700;
              transition: .3s;
              margin-left: 7px; }
            .location__items .location__items-item .location__items-info .location__items-info-value .phone__pre {
              color: #454a5b;
              font-size: 20px;
              font-weight: 400; }
            .location__items .location__items-item .location__items-info .location__items-info-value .phone:hover .phone__numb {
              color: #12b540; }
        .location__items .location__items-item .location__items-info .phone-desc {
          color: #454a5b;
          font-size: 14px;
          font-weight: 400; }
      .location__items .location__items-item--phone .location__items-info .location__items-info-value {
        display: flex;
        align-items: center;
        margin-bottom: 6px; }
      .location__items .location__items-item--email .location__items-info .location__items-info-value {
        display: flex;
        align-items: center; }
        .location__items .location__items-item--email .location__items-info .location__items-info-value a {
          color: #454a5b;
          font-size: 15px;
          font-weight: 700;
          margin-left: 28px; }
          .location__items .location__items-item--email .location__items-info .location__items-info-value a:nth-last-of-type(1) {
            margin-left: 0; }
      .location__items .location__items-item:hover i {
        color: #12b540; }

.contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 69px;
  margin-bottom: 92px; }
  .contact-us__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 92px; }
    .contact-us__logo img {
      margin-bottom: 26px; }
    .contact-us__logo i {
      font-size: 24px;
      color: #12b540;
      margin-bottom: 10px; }
    .contact-us__logo span {
      color: #9a9eac;
      font-size: 16px;
      font-weight: 700; }
  .contact-us form .inputs {
    display: flex;
    margin-bottom: 8px; }
  .contact-us form .contact-us__inputs {
    max-width: 430px;
    width: 430px;
    margin-left: 34px; }
    .contact-us form .contact-us__inputs .contact-us__inputs-item {
      margin-bottom: 25px; }
      .contact-us form .contact-us__inputs .contact-us__inputs-item label {
        color: #454a5b;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 14px; }
      .contact-us form .contact-us__inputs .contact-us__inputs-item input {
        display: block;
        height: 53px;
        background-color: #f3f4f5;
        border-radius: 6px;
        width: 100%;
        padding-right: 20px; }
      .contact-us form .contact-us__inputs .contact-us__inputs-item:nth-of-type(1) label {
        margin-bottom: 11px; }
  .contact-us form .contact-us__textarea {
    max-width: 430px;
    width: 430px; }
    .contact-us form .contact-us__textarea label {
      color: #454a5b;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 11px; }
    .contact-us form .contact-us__textarea textarea {
      display: block;
      background-color: #f3f4f5;
      resize: none;
      width: 100%;
      height: 385px;
      border-radius: 6px;
      padding: 16px 20px; }
  .contact-us form .contact-us__btn {
    width: 181px;
    height: 63px;
    box-shadow: 0 1px 2px rgba(101, 106, 120, 0.35);
    background-color: #12b540;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    padding: 0 23px 0 26px;
    transition: .3s; }
    .contact-us form .contact-us__btn i {
      font-size: 17px;
      color: #ffffff;
      transition: .3s;
      position: relative;
      left: 0; }
    .contact-us form .contact-us__btn:hover {
      box-shadow: 0 1px 8px rgba(101, 106, 120, 0.35); }
      .contact-us form .contact-us__btn:hover i {
        left: -10px; }

/* cart */
.header-cart {
  box-shadow: 0 2px 2px rgba(75, 90, 105, 0.17);
  padding-bottom: 32px; }
  .header-cart .header-cart__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header-cart__count {
    display: flex;
    align-items: center; }
    .header-cart__count .header-cart__count-numb {
      width: 36px;
      height: 26px;
      box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
      background-color: #ef473a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      margin-left: 18px; }
    .header-cart__count .header-cart__count-title {
      color: #454a5b;
      font-size: 19px;
      font-weight: 700; }
  .header-cart__breadcrumb {
    direction: ltr;
    display: flex; }
    .header-cart__breadcrumb .header-cart__breadcrumb-item {
      color: #848b9f;
      font-size: 15px;
      font-weight: 700;
      margin-right: 20px; }
      .header-cart__breadcrumb .header-cart__breadcrumb-item:after {
        content: '\e92a';
        font-family: 'icomoon';
        font-size: 3px;
        color: #aab0c2;
        margin-left: 19px; }

.cart-info {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding: 38px 0 41px 0;
  margin-bottom: 32px; }
  .cart-info__wrapper {
    display: flex;
    align-items: center; }
    .cart-info__wrapper .cart-info__item {
      display: flex;
      align-items: center;
      margin-left: 70px; }
      .cart-info__wrapper .cart-info__item .cart-info__item-title {
        color: #999ca7;
        font-size: 16px;
        font-weight: 700;
        margin-left: 8px; }
      .cart-info__wrapper .cart-info__item .cart-info__item-value {
        display: flex;
        align-items: center; }
        .cart-info__wrapper .cart-info__item .cart-info__item-value ins {
          color: #454a5b;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.72px;
          text-decoration: none;
          margin-left: 3px; }
        .cart-info__wrapper .cart-info__item .cart-info__item-value .unit {
          color: #8c919f;
          font-size: 13px;
          font-weight: 700; }
      .cart-info__wrapper .cart-info__item:nth-last-of-type(1) {
        margin-left: 0; }
    .cart-info__wrapper .cart-info__continue {
      color: #454a5b;
      font-size: 19px;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-right: auto; }
      .cart-info__wrapper .cart-info__continue i {
        font-size: 17px;
        color: #12b540;
        margin-right: 27px; }

.cart-items {
  margin-bottom: 30px; }
  .cart-items__item {
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
    padding: 32px 0 32px 0; }
    .cart-items__item .wrapper {
      display: flex;
      align-items: center; }
    .cart-items__item .cart-items__item-information {
      flex-grow: 1; }
    .cart-items__item .cart-items__item-info {
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #d5d8e1;
      padding-bottom: 28px; }
      .cart-items__item .cart-items__item-info .cart-items__item-img {
        margin-left: 23px; }
      .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-title {
        color: #4d5367;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 52px; }
      .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-meta {
        display: flex;
        align-items: center; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-meta span {
          color: #787d8b;
          font-size: 14px;
          font-weight: 700;
          margin-left: 40px; }
          .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-meta span:nth-last-of-type(1) {
            margin-left: 0; }
      .cart-items__item .cart-items__item-info .cart-items__item-info-left {
        display: flex;
        align-items: center;
        margin-right: auto;
        position: relative;
        top: 9px; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-left .cart-items__item-add {
          cursor: pointer; }
          .cart-items__item .cart-items__item-info .cart-items__item-info-left .cart-items__item-add i {
            font-size: 23px;
            color: #454a5b;
            transition: .3s; }
          .cart-items__item .cart-items__item-info .cart-items__item-info-left .cart-items__item-add:hover i {
            color: #12a53b; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-left .count {
          color: #454a5b;
          font-size: 20px;
          font-weight: 700;
          margin-right: 27px;
          margin-left: 27px; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-left .cart-items__item-minus {
          cursor: pointer; }
          .cart-items__item .cart-items__item-info .cart-items__item-info-left .cart-items__item-minus i {
            font-size: 23px;
            color: #454a5b;
            transition: .3s; }
          .cart-items__item .cart-items__item-info .cart-items__item-info-left .cart-items__item-minus:hover i {
            color: #12a53b; }
    .cart-items__item .cart-items__footer {
      display: flex;
      align-items: center;
      padding-top: 10px; }
      .cart-items__item .cart-items__footer > div {
        margin-left: 40px; }
      .cart-items__item .cart-items__footer .cart-items__footer-seller {
        display: flex;
        align-items: center; }
        .cart-items__item .cart-items__footer .cart-items__footer-seller .logo {
          margin-left: 8px; }
        .cart-items__item .cart-items__footer .cart-items__footer-seller .title {
          color: #787d8b;
          font-size: 14px;
          font-weight: 700; }
      .cart-items__item .cart-items__footer .cart-items__footer-available {
        color: #787d8b;
        font-size: 14px;
        font-weight: 700; }
      .cart-items__item .cart-items__footer .cart-items__footer-item {
        color: #787d8b;
        font-size: 14px;
        font-weight: 700; }
      .cart-items__item .cart-items__footer .cart-items__item-price {
        display: flex;
        align-items: center;
        margin-right: auto;
        margin-left: 0; }
        .cart-items__item .cart-items__footer .cart-items__item-price .price {
          color: #454a5b;
          font-size: 27px;
          font-weight: 700;
          letter-spacing: -1.08px;
          margin-left: 6px; }
        .cart-items__item .cart-items__footer .cart-items__item-price .unit {
          color: #454a5b;
          font-size: 15px;
          font-weight: 700; }

.cart-footer {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding: 28px 0;
  margin-bottom: 32px; }
  .cart-footer__wrapper {
    display: flex;
    align-items: center; }
  .cart-footer__back {
    width: 152px;
    height: 49px;
    box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
    background-color: #f7f8fb;
    border-radius: 5px;
    color: #777c8d;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 0 29px; }
    .cart-footer__back i {
      font-size: 17px;
      color: #777c8d; }
  .cart-footer__continue {
    width: 202px;
    height: 49px;
    box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
    background-color: #12b540;
    color: #ffffff;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0 25px 0 20px;
    margin-right: 67px; }
    .cart-footer__continue i {
      font-size: 17px;
      color: #ffffff; }
  .cart-footer__info {
    display: flex;
    align-items: center;
    margin-right: auto; }
    .cart-footer__info .cart-footer__info-item {
      display: flex;
      align-items: center;
      margin-left: 70px; }
      .cart-footer__info .cart-footer__info-item .cart-footer__info-title {
        color: #999ca7;
        font-size: 16px;
        font-weight: 700;
        margin-left: 8px; }
      .cart-footer__info .cart-footer__info-item .cart-footer__info-value {
        display: flex;
        align-items: center;
        color: #454a5b;
        font-size: 16px; }
        .cart-footer__info .cart-footer__info-item .cart-footer__info-value ins {
          color: #454a5b;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.72px;
          text-decoration: none;
          margin-left: 3px; }
        .cart-footer__info .cart-footer__info-item .cart-footer__info-value .unit {
          color: #8c919f;
          font-size: 13px;
          font-weight: 700; }
      .cart-footer__info .cart-footer__info-item:nth-last-of-type(1) {
        margin-left: 0; }

.cart-address {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding: 34px 0 32px 0;
  margin-bottom: 32px; }
  .cart-address__title {
    color: #4d5367;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 42px; }
  .cart-address__items .cart-address__items-item {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #d5d8e1;
    padding-bottom: 31px;
    margin-bottom: 32px; }
    .cart-address__items .cart-address__items-item > i {
      font-size: 25px;
      color: #454a5b;
      margin-left: 19px;
      transition: .3s; }
    .cart-address__items .cart-address__items-item .cart-address__items-address {
      color: #8d94aa;
      font-size: 15px;
      font-weight: 700; }
    .cart-address__items .cart-address__items-item .cart-address__items-change {
      margin-right: auto;
      display: flex;
      align-items: center; }
      .cart-address__items .cart-address__items-item .cart-address__items-change .title {
        color: #454a5b;
        font-size: 15px;
        font-weight: 700;
        margin-left: 10px; }
      .cart-address__items .cart-address__items-item .cart-address__items-change i {
        font-size: 21px;
        color: #454a5b; }
    .cart-address__items .cart-address__items-item:hover > i {
      color: #12b540; }
  .cart-address__add {
    display: flex;
    align-items: center; }
    .cart-address__add i {
      font-size: 23px;
      color: #454a5b;
      margin-left: 20px; }
    .cart-address__add .cart-address__add-title {
      color: #4d5367;
      font-size: 15px;
      font-weight: 700; }

.cart-method {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding-top: 33px;
  padding-bottom: 51px;
  margin-bottom: 31px; }
  .cart-method__nav {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    border-radius: 60px;
    display: none;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 6px 15px -4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 20;
    cursor: pointer; }
    .cart-method__nav i {
      font-size: 13px; }
    .cart-method__nav--next {
      right: 0; }
    .cart-method__nav--prev {
      left: 0; }
  .cart-method__title {
    color: #4d5367;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 66px; }
  .cart-method__items {
    margin-bottom: 47px;
    padding-bottom: 30px;
    margin-top: -30px; }
    .cart-method__items .cart-method__items-item {
      height: 278px; }
      .cart-method__items .cart-method__items-item input {
        display: none; }
        .cart-method__items .cart-method__items-item input:checked + .cart-method__items-label {
          background-color: #f7f8fb; }
          .cart-method__items .cart-method__items-item input:checked + .cart-method__items-label .cart-method__items-discount:after {
            background-color: #f7f8fb; }
        .cart-method__items .cart-method__items-item input:checked ~ .cart-method__items-discount:after {
          background-color: #f7f8fb; }
      .cart-method__items .cart-method__items-item .cart-method__items-label {
        position: relative;
        border: 1px solid #d4d7e2;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 50px;
        padding-bottom: 41px;
        cursor: pointer;
        background-color: #fff;
        transition: .3s;
        border-radius: 8px; }
        .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-discount {
          display: flex;
          align-items: center;
          position: absolute;
          top: 19px;
          right: -4px;
          background-color: #ef473a;
          height: 25px;
          border-radius: 60px 0 60px 60px;
          padding: 0 6px 0 8px; }
          .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-discount .numb {
            color: #ffffff;
            font-size: 18px;
            font-weight: 400;
            margin-left: 1px;
            letter-spacing: -0.94px; }
          .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-discount i {
            font-size: 8px;
            color: #ffffff; }
          .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-discount:after {
            content: '';
            position: absolute;
            right: 0px;
            left: 0;
            top: -17px;
            height: 19px;
            border-radius: 60px;
            background-color: #fff;
            transition: .3s;
            z-index: 20; }
          .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-discount:before {
            content: '';
            right: 0;
            left: 0;
            height: 14px;
            background-color: #a22016;
            border-radius: 60px 60px 10px 60px;
            position: absolute;
            top: -13px;
            z-index: 10;
            transform: skew(15deg) rotateY(31deg);
            transform-origin: 100% 50%; }
        .cart-method__items .cart-method__items-item .cart-method__items-label > i {
          font-size: 18px;
          color: #454a5b;
          margin-bottom: 36px; }
        .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-title {
          color: #4d5367;
          font-size: 27px;
          font-weight: 700;
          margin-bottom: 7px; }
        .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-desc {
          color: #4d5367;
          font-size: 15px;
          font-weight: 700; }
        .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__show-info {
          color: #4d5367;
          font-size: 15px;
          font-weight: 700;
          display: flex;
          align-items: center;
          margin-top: auto; }
          .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__show-info i {
            font-size: 8px;
            color: #454a5b;
            margin-right: 17px; }
        .cart-method__items .cart-method__items-item .cart-method__items-label .cart-method__items-port {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;
          width: 100%;
          padding: 0 33px; }
      .cart-method__items .cart-method__items-item .cart-method__items-discount-desc {
        color: #8a8c93;
        font-size: 13px;
        font-weight: 700;
        margin-top: 8px;
        display: flex;
        align-items: center; }
        .cart-method__items .cart-method__items-item .cart-method__items-discount-desc span {
          color: #ef473a;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.94px;
          display: flex;
          align-items: center;
          margin-left: 10px; }
          .cart-method__items .cart-method__items-item .cart-method__items-discount-desc span i {
            font-size: 8px;
            color: #ef473a;
            margin-right: 1px; }
      .cart-method__items .cart-method__items-item--online .cart-method__items-label {
        padding-bottom: 35px; }
      .cart-method__items .cart-method__items-item--location .cart-method__items-label {
        padding-top: 0;
        padding-bottom: 0; }
        .cart-method__items .cart-method__items-item--location .cart-method__items-label > i {
          margin-bottom: 46px; }
  .cart-method__coupon {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .cart-method__coupon .cart-method__coupon-title {
      color: #4d5367;
      font-size: 22px;
      font-weight: 700; }
    .cart-method__coupon .cart-method__coupon-input {
      display: flex;
      align-items: center; }
      .cart-method__coupon .cart-method__coupon-input input {
        color: #979baa;
        font-size: 15px;
        font-weight: 700;
        width: 373px;
        height: 57px;
        border: 1px solid #d4d7e2;
        background-color: #ffffff;
        border-radius: 6px;
        padding-right: 23px;
        margin-left: 18px; }
        .cart-method__coupon .cart-method__coupon-input input::placeholder {
          color: #979baa;
          font-size: 15px;
          font-weight: 700;
          opacity: 1; }
      .cart-method__coupon .cart-method__coupon-input button {
        width: 140px;
        height: 57px;
        background-color: #a8abb5;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        border-radius: 6px; }

.cart-success {
  background-color: #fff;
  background-image: url("../img/cart-success-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  padding-top: 57px;
  padding-bottom: 64px;
  margin-bottom: 3px; }
  .cart-success__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 290px;
    width: 100%;
    margin: 0 auto; }
    .cart-success__wrapper > i {
      font-size: 53px;
      color: #12b540;
      margin-bottom: 27px; }
    .cart-success__wrapper .cart-success__greeting {
      color: #4d5367;
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 3px; }
    .cart-success__wrapper .cart-success__success {
      color: #454a5b;
      font-size: 27px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 53px; }
    .cart-success__wrapper .cart-success__meta {
      width: 100%;
      margin-bottom: 30px; }
      .cart-success__wrapper .cart-success__meta .cart-success__meta-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px; }
        .cart-success__wrapper .cart-success__meta .cart-success__meta-item .key {
          color: #454a5b;
          font-size: 17px;
          font-weight: 700; }
        .cart-success__wrapper .cart-success__meta .cart-success__meta-item .value {
          color: #454a5b;
          font-size: 17px;
          font-weight: 700; }
        .cart-success__wrapper .cart-success__meta .cart-success__meta-item:nth-last-of-type(1) {
          margin-bottom: 0; }
    .cart-success__wrapper .cart-success__link {
      width: 100%;
      box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
      background-color: #12b540;
      transition: .3s;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      font-size: 17px;
      font-weight: 700;
      border-radius: 6px;
      padding: 0 20px;
      margin-bottom: 12px; }
      .cart-success__wrapper .cart-success__link i {
        font-size: 15px;
        color: #ffffff; }
      .cart-success__wrapper .cart-success__link:hover {
        box-shadow: 0 1px 6px rgba(75, 90, 105, 0.17); }
      .cart-success__wrapper .cart-success__link:nth-last-of-type(1) {
        margin-bottom: 0; }

/* cat-filter */
.cat-filter-header {
  border-bottom: 1px solid #eceff1;
  padding-bottom: 27px; }
  .cat-filter-header__wrapper {
    display: flex;
    align-items: center; }
    .cat-filter-header__wrapper .cat-filter-header__breadcrumb {
      display: flex;
      align-items: center; }
      .cat-filter-header__wrapper .cat-filter-header__breadcrumb a {
        color: #5c616d;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-left: 20px; }
        .cat-filter-header__wrapper .cat-filter-header__breadcrumb a:after {
          content: '\e91a';
          font-family: 'icomoon';
          font-size: 9px;
          color: #a4abb3;
          margin-right: 20px; }
      .cat-filter-header__wrapper .cat-filter-header__breadcrumb span {
        color: #959bad;
        font-size: 13px;
        font-weight: 500; }
    .cat-filter-header__wrapper .cat-filter-header__amazing-offers {
      margin-right: auto;
      color: #383c48;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-content: center; }
      .cat-filter-header__wrapper .cat-filter-header__amazing-offers i {
        font-size: 13px;
        color: #ffffff;
        width: 30px;
        height: 23px;
        background-color: #ef473a;
        border-radius: 60px 60px 60px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px; }

.filter__category {
  padding-top: 36px;
  padding-bottom: 28px;
  border-bottom: 1px solid #edeff2;
  padding-left: 30px; }
  .filter__category .filter__category-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px; }
    .filter__category .filter__category-head .filter__category-title {
      color: #1049dc;
      font-size: 15px;
      font-weight: 700; }
    .filter__category .filter__category-head i {
      font-size: 10px;
      color: #1049dc; }
  .filter__category .filter__category-search {
    display: flex;
    align-items: center;
    border-top: 1px solid  #edeff2;
    border-bottom: 1px solid  #edeff2;
    padding-top: 14px;
    padding-bottom: 18px;
    margin-bottom: 28px; }
    .filter__category .filter__category-search input {
      color: #63697c;
      font-size: 14px;
      font-weight: 700;
      flex-grow: 1; }
      .filter__category .filter__category-search input::placeholder {
        color: #63697c;
        font-size: 14px;
        font-weight: 700;
        opacity: 1; }
    .filter__category .filter__category-search i {
      font-size: 15px;
      color: #868a99; }
  .filter__category .filter__category-content h2 {
    color: #383c48;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 28px; }
  .filter__category .filter__category-content .filter__category-items {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
    transition: .2s ease-in-out; }
    .filter__category .filter__category-content .filter__category-items:before {
      content: '..................................................................................................................................................................................................................................';
      color: #a3a6b2;
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      letter-spacing: 10.64px;
      top: 6px;
      bottom: 0;
      right: 16px;
      transform: rotate(-90deg);
      transform-origin: 50% 0;
      white-space: nowrap;
      width: 2px; }
    .filter__category .filter__category-content .filter__category-items .filter__category-item {
      padding-right: 20px;
      color: #626673;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 15px;
      transition: .3s; }
      .filter__category .filter__category-content .filter__category-items .filter__category-item:hover {
        color: #12b540; }
  .filter__category .filter__category-content .filter__category-more {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .filter__category .filter__category-content .filter__category-more i {
      font-size: 9px;
      color: #a0a5b1;
      margin-left: 15px; }
    .filter__category .filter__category-content .filter__category-more span {
      color: #a0a5b1;
      font-size: 14px;
      font-weight: 700; }
    .filter__category .filter__category-content .filter__category-more.active i:before {
      content: '';
      width: 9px;
      height: 2px;
      background-color: #a0a5b1;
      display: inline-block;
      border-radius: 60px;
      position: relative;
      top: -1px; }

.filter__brand {
  padding-top: 20px;
  border-bottom: 1px solid #edeff2;
  padding-bottom: 19px;
  margin-bottom: 20px;
  padding-left: 30px; }
  .filter__brand .filter__brand-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #edeff2;
    padding-bottom: 23px;
    cursor: pointer; }
    .filter__brand .filter__brand-head .filter__brand-title {
      color: #1049dc;
      font-size: 15px;
      font-weight: 700; }
    .filter__brand .filter__brand-head i {
      font-size: 8px;
      color: #1049dc;
      transition: .3s;
      transform: rotateX(0); }
    .filter__brand .filter__brand-head.active i {
      transform: rotateX(180deg); }
  .filter__brand .filter__brand-search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edeff2;
    padding-top: 16px;
    padding-bottom: 17px;
    margin-bottom: 32px; }
    .filter__brand .filter__brand-search input {
      color: #63697c;
      font-size: 14px;
      font-weight: 700;
      flex-grow: 1; }
      .filter__brand .filter__brand-search input::placeholder {
        color: #63697c;
        font-size: 14px;
        font-weight: 700;
        opacity: 1; }
    .filter__brand .filter__brand-search i {
      font-size: 15px;
      color: #868a99; }
  .filter__brand .filter__brand-content {
    height: 296px; }
    .filter__brand .filter__brand-content .mCSB_container {
      margin-left: 25px !important; }
    .filter__brand .filter__brand-content .mCSB_scrollTools .mCSB_draggerContainer {
      width: 5px;
      background-color: #e2e4eb;
      border-radius: 60px;
      overflow: hidden;
      bottom: 16px !important; }
    .filter__brand .filter__brand-content .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #1049dc;
      opacity: 1 !important;
      width: 5px;
      border-radius: 0; }
    .filter__brand .filter__brand-content .mCSB_scrollTools .mCSB_draggerRail {
      background-color: #e2e4eb;
      width: 5px;
      opacity: 1 !important; }
    .filter__brand .filter__brand-content .filter__brand-item {
      margin-bottom: 12px; }
      .filter__brand .filter__brand-content .filter__brand-item input {
        display: none; }
        .filter__brand .filter__brand-content .filter__brand-item input:checked + .filter__brand-label .filter__brand-check {
          background-color: #454a5b; }
          .filter__brand .filter__brand-content .filter__brand-item input:checked + .filter__brand-label .filter__brand-check:before {
            transform: scale(1); }
      .filter__brand .filter__brand-content .filter__brand-item .filter__brand-label {
        display: flex;
        align-items: center;
        cursor: pointer; }
        .filter__brand .filter__brand-content .filter__brand-item .filter__brand-label .filter__brand-check {
          width: 13px;
          height: 13px;
          border: 1px solid #cacdd8;
          background-color: #ffffff;
          border-radius: 3px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .3s; }
          .filter__brand .filter__brand-content .filter__brand-item .filter__brand-label .filter__brand-check:before {
            content: '\e910';
            font-family: 'icomoon';
            font-size: 7px;
            color: #ffffff;
            transform: scale(0);
            transition: .3s; }
        .filter__brand .filter__brand-content .filter__brand-item .filter__brand-label .filter__brand-name {
          color: #454a5b;
          font-size: 14px;
          font-weight: 700; }
        .filter__brand .filter__brand-content .filter__brand-item .filter__brand-label .filter__brand-name-en {
          color: #b1b4c0;
          font-size: 12px;
          font-weight: 700;
          margin-right: auto; }

.filter__true-false {
  border-bottom: 1px solid #edeff2;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-left: 30px; }
  .filter__true-false label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .filter__true-false label .filter__title {
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center; }
      .filter__true-false label .filter__title i {
        font-size: 13px;
        color: #ffffff;
        background-color: #ef473a;
        padding: 4px 7px 4px 7px;
        border-radius: 60px;
        margin-left: 8px; }
    .filter__true-false label .filter__scroll {
      width: 57px;
      height: 32px;
      background-color: #e2e4eb;
      border-radius: 60px;
      position: relative;
      transition: .3s; }
      .filter__true-false label .filter__scroll i {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 4px;
        top: 50%;
        transform: translate(0, -50%);
        box-shadow: 0 1px 1px rgba(127, 133, 156, 0.45);
        background-color: #ffffff;
        border-radius: 60px;
        color: #bec1cc;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s; }
  .filter__true-false input {
    display: none; }
    .filter__true-false input:checked + label .filter__scroll {
      background-color: #12b540; }
      .filter__true-false input:checked + label .filter__scroll i {
        left: calc(100% - (4px + 24px));
        color: #12b540; }

.filter__color {
  padding-left: 30px; }
  .filter__color .filter__color-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #edeff2;
    padding-bottom: 21px;
    margin-bottom: 32px;
    cursor: pointer; }
    .filter__color .filter__color-head .filter__color-title {
      color: #1049dc;
      font-size: 15px;
      font-weight: 700; }
    .filter__color .filter__color-head i {
      font-size: 8px;
      color: #1049dc;
      transition: .3s;
      transform: rotateX(0); }
    .filter__color .filter__color-head.active i {
      transform: rotateX(180deg); }
  .filter__color .filter__color-content .mCSB_container {
    margin-left: 25px !important; }
  .filter__color .filter__color-content .mCSB_scrollTools .mCSB_draggerContainer {
    width: 5px;
    background-color: #e2e4eb;
    border-radius: 60px;
    overflow: hidden;
    bottom: 16px !important; }
  .filter__color .filter__color-content .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #1049dc;
    opacity: 1 !important;
    width: 5px;
    border-radius: 0; }
  .filter__color .filter__color-content .mCSB_scrollTools .mCSB_draggerRail {
    background-color: #e2e4eb;
    width: 5px;
    opacity: 1 !important; }
  .filter__color .filter__color-content .filter__color-items {
    height: 292px; }
    .filter__color .filter__color-content .filter__color-items .filter__color-item {
      margin-bottom: 12px; }
      .filter__color .filter__color-content .filter__color-items .filter__color-item input {
        display: none; }
        .filter__color .filter__color-content .filter__color-items .filter__color-item input:checked + .filter__color-label .filter__color-check {
          background-color: #454a5b; }
          .filter__color .filter__color-content .filter__color-items .filter__color-item input:checked + .filter__color-label .filter__color-check:before {
            transform: scale(1); }
      .filter__color .filter__color-content .filter__color-items .filter__color-item .filter__color-label {
        display: flex;
        align-items: center;
        cursor: pointer; }
        .filter__color .filter__color-content .filter__color-items .filter__color-item .filter__color-label .filter__color-check {
          width: 13px;
          height: 13px;
          border: 1px solid #cacdd8;
          background-color: #ffffff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 18px;
          transition: .3s; }
          .filter__color .filter__color-content .filter__color-items .filter__color-item .filter__color-label .filter__color-check:before {
            content: '\e910';
            font-family: 'icomoon';
            font-size: 7px;
            color: #ffffff;
            transform: scale(0);
            transition: .3s; }
        .filter__color .filter__color-content .filter__color-items .filter__color-item .filter__color-label .filter__color-pallet {
          width: 7px;
          height: 16px;
          background-color: #40a6af;
          border-radius: 60px;
          margin-left: 15px; }
        .filter__color .filter__color-content .filter__color-items .filter__color-item .filter__color-label .filter__color-name {
          color: #454a5b;
          font-size: 14px;
          font-weight: 700; }
      .filter__color .filter__color-content .filter__color-items .filter__color-item--white .filter__color-pallet {
        border: 1px solid #cacdd8; }

.filter--cat-one {
  position: relative;
  z-index: 1000;
  background-color: #fff; }
  .filter--cat-one:before {
    content: '';
    left: 0;
    right: -10000px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100; }
  .filter--cat-one .filter__category {
    position: relative;
    z-index: 200;
    padding-left: 0;
    border-bottom: none;
    border-bottom: none; }
  .filter--cat-one .filter__category-content {
    border-bottom: 1px solid #edeff2;
    padding-bottom: 28px;
    margin-bottom: 27px; }
    .filter--cat-one .filter__category-content:nth-last-of-type(1) {
      border-bottom: none;
      margin-bottom: 0; }

.cat-filter-sidebar .close {
  display: none;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: .3s;
  font-size: 13px;
  opacity: 1;
  cursor: pointer; }

.cat-filter-responsive {
  display: none; }

.filters-summary {
  margin-bottom: 31px; }
  .filters-summary__sorting {
    display: flex;
    align-items: center;
    padding-top: 33px;
    margin-bottom: 34px; }
    .filters-summary__sorting .filters-summary__sorting-title {
      color: #6b707f;
      font-size: 15px;
      font-weight: 700;
      margin-left: 20px; }
    .filters-summary__sorting .filters-summary__sorting-items {
      display: flex;
      align-items: center; }
      .filters-summary__sorting .filters-summary__sorting-items .filters-summary__sorting-item {
        color: #454a5b;
        font-size: 15px;
        font-weight: 700;
        background-color: #f5f6f9;
        border-radius: 60px;
        height: 29px;
        display: flex;
        align-items: center;
        padding: 0 17px 0 17px;
        margin-left: 9px;
        transition: .3s; }
        .filters-summary__sorting .filters-summary__sorting-items .filters-summary__sorting-item:nth-last-of-type(1) {
          margin-left: 0; }
        .filters-summary__sorting .filters-summary__sorting-items .filters-summary__sorting-item:hover {
          background-color: #12b540;
          color: #ffffff; }
        .filters-summary__sorting .filters-summary__sorting-items .filters-summary__sorting-item.active {
          background-color: #12b540;
          color: #ffffff; }
    .filters-summary__sorting .filters-summary__sorting-responsive {
      display: none;
      z-index: 50; }
    .filters-summary__sorting .filters-summary__sorting-count-product {
      display: flex;
      align-items: center;
      margin-right: auto; }
      .filters-summary__sorting .filters-summary__sorting-count-product .numb {
        color: #454a5b;
        font-size: 18px;
        font-weight: 700;
        margin-left: 5px; }
      .filters-summary__sorting .filters-summary__sorting-count-product .title {
        color: #9da1af;
        font-size: 15px;
        font-weight: 700; }
  .filters-summary__apply .filters-summary__apply-header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 21px; }
    .filters-summary__apply .filters-summary__apply-header:before {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      left: 0;
      border-bottom: 1px solid #dfe1ea;
      z-index: 10; }
    .filters-summary__apply .filters-summary__apply-header .filters-summary__apply-title {
      display: inline-flex;
      align-items: center;
      color: #6b707f;
      font-size: 15px;
      font-weight: 700;
      position: relative;
      background-color: #fff;
      z-index: 20;
      padding-left: 38px; }
      .filters-summary__apply .filters-summary__apply-header .filters-summary__apply-title i {
        font-size: 17px;
        color: #12b540;
        margin-left: 12px; }
  .filters-summary__apply .filters-summary__apply-content {
    display: flex;
    align-items: center; }
    .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items .filters-summary__apply-item {
        background-color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 1px solid #dce1ea;
        height: 32px;
        border-radius: 5px;
        padding: 0 11px 0 13px;
        margin-left: 12px;
        transition: .3s; }
        .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items .filters-summary__apply-item i {
          font-size: 8px;
          color: #d84545;
          margin-left: 10px; }
        .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items .filters-summary__apply-item .filter-name {
          color: #454a5b;
          font-size: 13px;
          font-weight: 700; }
        .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items .filters-summary__apply-item:nth-last-of-type(1) {
          margin-left: 0; }
        .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items .filters-summary__apply-item:hover {
          background-color: #dce1ea; }
    .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-delete-filters {
      margin-right: auto;
      cursor: pointer;
      border-radius: 5px;
      width: 44px;
      height: 32px;
      border: 1px solid #dce1ea;
      background-color: #f5f6f9;
      display: flex;
      align-items: center;
      justify-content: center; }
      .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-delete-filters i {
        font-size: 15px;
        color: #83899c;
        transition: .3s; }
      .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-delete-filters:hover i {
        color: #d84545; }

.cat-wrapper {
  position: relative; }
  .cat-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 40px;
    width: 30px;
    right: -30px;
    box-shadow: 7px 0 20px -13px rgba(0, 0, 0, 0.4);
    pointer-events: none; }

.cat-products {
  margin-bottom: 28px; }
  .cat-products__item {
    border: 1px solid #dce1ea;
    padding: 33px 14px 17px 16px; }
    .cat-products__item .cat-products__item-img {
      margin-bottom: 28px; }
      .cat-products__item .cat-products__item-img figure {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .cat-products__item .cat-products__item-title {
      margin-bottom: 20px; }
      .cat-products__item .cat-products__item-title h2 {
        color: #4d5367;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        line-height: 30px; }
    .cat-products__item .cat-products__item-buying {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dce1ea;
      padding-bottom: 21px;
      margin-bottom: 11px; }
      .cat-products__item .cat-products__item-buying .cat-products__item-buy {
        display: flex;
        align-items: center; }
        .cat-products__item .cat-products__item-buying .cat-products__item-buy .icon {
          position: relative;
          font-size: 18px;
          color: #afb4c5;
          margin-left: 9px; }
          .cat-products__item .cat-products__item-buying .cat-products__item-buy .icon i {
            position: absolute;
            font-size: 18px;
            color: #ffffff;
            top: 0;
            left: 0; }
        .cat-products__item .cat-products__item-buying .cat-products__item-buy .text {
          font-size: 14px;
          color: #4d5367; }
      .cat-products__item .cat-products__item-buying .cat-products__item-price {
        display: flex;
        align-items: center;
        margin-right: auto; }
        .cat-products__item .cat-products__item-buying .cat-products__item-price .price {
          color: #454a5b;
          font-size: 17px;
          font-weight: 700;
          text-decoration: none;
          margin-left: 2px;
          letter-spacing: -0.68px; }
        .cat-products__item .cat-products__item-buying .cat-products__item-price .unit {
          color: #8c919f;
          font-size: 13px;
          font-weight: 700; }
    .cat-products__item .cat-products__item-footer {
      display: flex;
      align-items: center; }
      .cat-products__item .cat-products__item-footer .cat-products__item-store {
        color: #878b9a;
        font-size: 13px;
        font-weight: 700; }
      .cat-products__item .cat-products__item-footer .cat-products__item-rate {
        display: flex;
        align-items: center;
        margin-right: auto; }
        .cat-products__item .cat-products__item-footer .cat-products__item-rate .rate {
          color: #878b9a;
          font-size: 17px;
          font-weight: 700;
          margin-left: 3px; }
        .cat-products__item .cat-products__item-footer .cat-products__item-rate i {
          font-size: 11px;
          color: #878b9a; }
    .cat-products__item--discount {
      position: relative; }
      .cat-products__item--discount .offer {
        position: absolute;
        font-size: 14px;
        color: #454a5b;
        top: 14px;
        left: 14px; }
      .cat-products__item--discount .cat-products__item-buying {
        padding-bottom: 4px; }
        .cat-products__item--discount .cat-products__item-buying .cat-products__item-price {
          flex-wrap: wrap;
          justify-content: flex-end; }
          .cat-products__item--discount .cat-products__item-buying .cat-products__item-price .discount {
            background-color: #ef473a;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 17px;
            border-radius: 0 60px 60px 60px;
            padding: 0 7px 0 6px;
            flex: 0 1 36%; }
            .cat-products__item--discount .cat-products__item-buying .cat-products__item-price .discount__numb {
              color: #ffffff;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: -0.72px;
              margin-left: 2px; }
            .cat-products__item--discount .cat-products__item-buying .cat-products__item-price .discount i {
              font-size: 10px;
              color: #ffffff; }
  .cat-products [class*=col]:nth-of-type(4n) .cat-products__item {
    border-left: none; }
  .cat-products [class*=col]:nth-of-type(4n + 1) .cat-products__item {
    border-right: none; }

.pagination {
  margin-right: -15px; }
  .pagination__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .pagination__items .pagination__items-item {
      color: #9499ab;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: -0.68px;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 19px;
      transition: .3s;
      margin-bottom: 15px; }
      .pagination__items .pagination__items-item:hover {
        color: #12b540; }
      .pagination__items .pagination__items-item.active {
        background-color: #12b540;
        color: #fff; }
      .pagination__items .pagination__items-item--next i {
        font-size: 13px;
        color: #9499ab;
        transition: .3s; }
      .pagination__items .pagination__items-item--next:hover i {
        color: #12b540; }

/* cat-one */
.catone-slider {
  margin-top: 37px;
  margin-bottom: 30px; }
  .catone-slider .cat-one__slider {
    overflow: visible; }
    .catone-slider .cat-one__slider .swiper-slide {
      opacity: .3;
      transition: .3s; }
      .catone-slider .cat-one__slider .swiper-slide.swiper-slide-active {
        opacity: 1; }
  .catone-slider__image {
    border-radius: 10px;
    overflow: hidden; }

.cat-one-img {
  margin-bottom: 50px; }
  .cat-one-img__item {
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden; }
  .cat-one-img__big {
    border-radius: 15px;
    overflow: hidden; }

.cat-one-products {
  margin-bottom: 42px; }
  .cat-one-products__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 33px; }
    .cat-one-products__header .cat-one-products__header-title {
      color: #454a5b;
      font-size: 20px;
      font-weight: 700; }
    .cat-one-products__header .cat-one-products__header-more {
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      align-items: center; }
      .cat-one-products__header .cat-one-products__header-more i {
        font-size: 4px;
        color: #454a5b;
        margin-top: 3px;
        margin-right: 9px; }
  .cat-one-products__content {
    position: relative; }
    .cat-one-products__content:before {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      width: 20px;
      left: -20px;
      box-shadow: 14px 0 14px -9px rgba(0, 0, 0, 0.4);
      z-index: 100; }
    .cat-one-products__content .cat-one-products__item .cat-one-products__item-wrapper {
      border: 1px solid #dce1ea;
      border-radius: 10px 10px 0 10px;
      padding-top: 50px;
      margin-bottom: 10px; }
    .cat-one-products__content .cat-one-products__item .cat-one-products__item-img {
      margin-bottom: 31px; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-img figure {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .cat-one-products__content .cat-one-products__item .cat-one-products__item-title {
      border-bottom: 1px solid #dce1ea;
      padding: 0 16px;
      padding-bottom: 16px; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-title h2 {
        color: #4d5367;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        line-height: 27px; }
    .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      padding-top: 14px;
      padding-bottom: 15px; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-buy {
        display: flex;
        align-items: center; }
        .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-buy .icon {
          position: relative;
          font-size: 18px;
          color: #afb4c5;
          margin-left: 9px; }
          .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-buy .icon i {
            position: absolute;
            font-size: 18px;
            color: #ffffff;
            top: 0;
            left: 0; }
        .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-buy .text {
          font-size: 14px;
          color: #4d5367; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-price {
        display: flex;
        align-items: center; }
        .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-price .price {
          color: #454a5b;
          font-size: 17px;
          font-weight: 700;
          letter-spacing: -0.68px;
          text-decoration: none;
          margin-left: 2px; }
        .cat-one-products__content .cat-one-products__item .cat-one-products__item-buying .cat-one-products__item-price .unit {
          color: #8c919f;
          font-size: 13px;
          font-weight: 700; }
    .cat-one-products__content .cat-one-products__item .cat-one-products__item-footer {
      display: flex;
      align-items: center; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-footer .cat-one-products__item-logo {
        margin-left: 10px; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-footer .cat-one-products__item-store-name {
        color: #767b8a;
        font-size: 14px;
        font-weight: 700;
        margin-left: 11px; }
      .cat-one-products__content .cat-one-products__item .cat-one-products__item-footer .cat-one-products__item-rate {
        display: flex;
        align-items: center; }
        .cat-one-products__content .cat-one-products__item .cat-one-products__item-footer .cat-one-products__item-rate .numb {
          color: #4d5367;
          font-size: 17px;
          font-weight: 700;
          margin-left: 3px; }
        .cat-one-products__content .cat-one-products__item .cat-one-products__item-footer .cat-one-products__item-rate i {
          font-size: 11px;
          color: #12b540; }

.cat-one-banner {
  margin-top: 61px;
  margin-bottom: 45px; }

.top-sellers__content-swiper--cat-one {
  padding-bottom: 30px;
  margin-bottom: -30px;
  padding-top: 30px;
  margin-top: -30px;
  padding-right: 30px;
  margin-right: -30px; }

.cat-one-exclusive {
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  background-color: #f3f5f9;
  padding: 38px 33px 35px 33px;
  border-radius: 10px;
  margin-bottom: 53px; }
  .cat-one-exclusive__header {
    margin-bottom: 18px; }
    .cat-one-exclusive__header h2 {
      color: #848c9c;
      font-size: 17px;
      font-weight: 700; }
  .cat-one-exclusive p {
    color: #656e80;
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
    line-height: 32px; }

/* login */
.login {
  margin: 0 auto;
  max-width: 523px;
  width: 100%;
  padding-top: 102px;
  padding-bottom: 100px; }
  .login__header {
    display: flex;
    justify-content: center;
    margin-bottom: 71px; }
    .login__header a {
      display: flex;
      flex-direction: column; }
      .login__header a .logo {
        font-size: 69px;
        color: #12b540;
        margin-bottom: 10px; }
      .login__header a .logo-text {
        font-size: 22px;
        color: #454a5b; }
  .login__form {
    display: flex;
    flex-direction: column;
    background-color: #f7f8f9;
    padding: 63px 46px 50px 46px;
    border-radius: 15px;
    margin-bottom: 116px;
    box-shadow: -17px -17px 17px 0 rgba(255, 255, 255, 0.5), 25px 25px 17px -20px rgba(0, 0, 0, 0.03); }
    .login__form .login__form-label {
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 15px; }
    .login__form input {
      height: 54px;
      background-color: #eef0f2;
      width: 100%;
      color: #454a5b;
      font-size: 16px;
      font-weight: 400;
      border-radius: 8px;
      margin-bottom: 23px;
      padding-right: 20px; }
    .login__form .login__form-btn {
      display: flex;
      align-items: center;
      margin-bottom: 37px;
      margin-top: 4px; }
      .login__form .login__form-btn button {
        height: 54px;
        background-color: #12b540;
        border-radius: 10px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        margin-left: 10px;
        padding: 0 20px 0 22px; }
        .login__form .login__form-btn button i {
          font-size: 14px;
          color: #ffffff; }
      .login__form .login__form-btn .login__form-register {
        width: 140px;
        height: 54px;
        box-shadow: 0 2px 3px rgba(69, 74, 91, 0.11);
        background-color: #fff;
        border-radius: 10px;
        color: #454a5b;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center; }
    .login__form .login__form-remember {
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      text-align: left; }
  .login__back-home {
    color: #454a5b;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center; }
    .login__back-home i {
      font-size: 13px;
      color: #454a5b;
      margin-right: 14px; }

.login-wrapper {
  background-color: #f7f8f9;
  background-image: url("../img/shop-login.svg");
  background-repeat: no-repeat;
  background-position: 102% center; }

.login {
  padding-top: 102px;
  padding-bottom: 100px;
  position: relative; }
  .login__code {
    max-width: 523px;
    width: 100%;
    background-color: #f7f8f9;
    box-shadow: -17px -17px 17px 0 rgba(255, 255, 255, 0.5), 25px 25px 17px -20px rgba(0, 0, 0, 0.03);
    padding: 51px 47px 43px 46px;
    border-radius: 15px;
    margin-bottom: 116px; }
    .login__code .login__code-second {
      width: 155px;
      height: 155px;
      box-shadow: inset 0 5px 6px rgba(69, 74, 91, 0.08);
      background-color: #f7f8f9;
      border-radius: 100%;
      margin: 0 auto;
      margin-bottom: 13px;
      position: relative; }
      .login__code .login__code-second .login__code-count-down {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #707f8e;
        font-size: 46px;
        font-weight: 700;
        letter-spacing: -2.1px; }
      .login__code .login__code-second svg {
        position: absolute;
        top: 60px;
        right: 57px;
        width: 40px;
        height: 40px;
        transform: rotateZ(-90deg) scale(2.95); }
      .login__code .login__code-second svg circle {
        stroke-dasharray: 113px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 2px;
        stroke: #12b540;
        fill: none;
        animation: countdown 60s linear forwards; }

@keyframes countdown {
  from {
    stroke-dashoffset: 0px; }
  to {
    stroke-dashoffset: 113px; } }
    .login__code .login__code-phone {
      color: #454a5b;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 25px;
      display: inline-block; }
    .login__code .login__code-inputs {
      display: flex;
      align-items: center;
      margin-bottom: 27px; }
      .login__code .login__code-inputs input {
        height: 54px;
        background-color: #eef0f2;
        border-radius: 6px;
        display: flex;
        text-align: center;
        justify-content: center;
        flex: 1;
        margin-left: 15px;
        max-width: 95px; }
        .login__code .login__code-inputs input:nth-last-of-type(1) {
          margin-left: 0; }
    .login__code .login__code-footer {
      display: flex;
      align-items: center; }
      .login__code .login__code-footer .login__code-btn {
        width: 280px;
        height: 54px;
        background-color: #12b540;
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 19px 0 22px; }
        .login__code .login__code-footer .login__code-btn i {
          font-size: 14px;
          color: #ffffff; }
      .login__code .login__code-footer .login__code-resend {
        width: 140px;
        height: 54px;
        box-shadow: 0 2px 3px rgba(69, 74, 91, 0.11);
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #454a5b;
        font-size: 16px;
        font-weight: 700; }
  .login__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: -350px;
    top: 50%;
    transform: translate(0, -50%); }
    .login__logo i.logo {
      font-size: 76px;
      color: #12b540;
      margin-bottom: 17px; }
    .login__logo i.text {
      font-size: 16px;
      color: #454a5b; }

/* 404 */
.not-found {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 50px;
  background-image: url("../img/bg-404.svg");
  background-repeat: no-repeat;
  background-position: left center;
  position: relative; }
  .not-found:before {
    content: '';
    position: absolute;
    height: 150px;
    width: 400px;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-image: linear-gradient(to right, #fff, transparent); }
  .not-found__title {
    color: #454a5b;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 21px; }
  .not-found__img {
    margin-bottom: 112px; }
  .not-found__redirect {
    color: #454a5b;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 117px; }
    .not-found__redirect span {
      color: #ea3333;
      font-size: 25px;
      font-weight: 700;
      margin-right: 3px;
      margin-left: 3px; }
  .not-found__footer {
    display: flex; }
    .not-found__footer .not-found__footer-item {
      width: 104px;
      height: 113px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      box-shadow: 0 10px 21px -10px rgba(0, 0, 0, 0.2);
      margin-left: 30px;
      transition: .3s;
      color: #454a5b;
      font-size: 15px;
      font-weight: 700; }
      .not-found__footer .not-found__footer-item i {
        font-size: 21px;
        color: #454a5b;
        margin-bottom: 28px; }
      .not-found__footer .not-found__footer-item:nth-last-of-type(1) {
        margin-left: 0; }
      .not-found__footer .not-found__footer-item:hover {
        background-color: #f8f8f9;
        box-shadow: none; }
  .not-found__ivahid {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 53px;
    right: 200px; }
    .not-found__ivahid .not-found__ivahid-desc {
      display: flex;
      flex-direction: column;
      margin-right: 20px; }
      .not-found__ivahid .not-found__ivahid-desc .not-found__ivahid-small {
        color: #adb1c0;
        font-size: 11px;
        font-weight: 400; }
      .not-found__ivahid .not-found__ivahid-desc .not-found__ivahid-big {
        color: #adb1c0;
        font-size: 13px;
        font-weight: 400;
        margin-top: -1px; }

/* responsive */
.mega-menu-site-responsive {
  position: fixed;
  transition: 0.5s ease-in-out;
  right: -100%;
  display: flex;
  flex-direction: column;
  /*padding: 10px;*/
  top: 0;
  /*border-radius: 5px 0 0 5px;*/
  z-index: 9999999999999999999;
  width: 100%;
  overflow: scroll;
  padding: 10px;
  height: 100vh; }
  .mega-menu-site-responsive__header {
    display: flex;
    align-items: center;
    padding: 30px 27px 0 27px;
    margin-bottom: 38px; }
    .mega-menu-site-responsive__header i {
      color: #d8132e;
      font-size: 21px; }
      .mega-menu-site-responsive__header i.add-cart {
        margin-right: 21px; }
    .mega-menu-site-responsive__header .close {
      color: #000000;
      font-size: 15px;
      margin-right: auto;
      transition: .3s;
      cursor: pointer; }
      .mega-menu-site-responsive__header .close:hover {
        color: #5f5f5f; }
  .mega-menu-site-responsive__search {
    padding: 0 30px; }
    .mega-menu-site-responsive__search form {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .mega-menu-site-responsive__search form input {
        border: none;
        outline: none;
        padding-left: 50px;
        width: 100%;
        background-color: transparent;
        font-size: 13px;
        font-weight: 400; }
        .mega-menu-site-responsive__search form input::placeholder {
          color: #000000;
          opacity: 1; }
      .mega-menu-site-responsive__search form button {
        background-color: transparent;
        border: none;
        line-height: 12px; }
        .mega-menu-site-responsive__search form button i {
          color: #000000;
          font-size: 17px;
          font-weight: 400; }
  .mega-menu-site-responsive__head {
    margin-bottom: 1px;
    background-color: rgba(255, 255, 255, 0.97);
    padding-bottom: 21px;
    flex: 1 0 auto;
    max-height: 213px; }
  .mega-menu-site-responsive__body {
    background-color: rgba(255, 255, 255, 0.97);
    margin-bottom: 1px;
    overflow: auto;
    flex-grow: 1; }
    .mega-menu-site-responsive__body .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #d8132e !important;
      width: 6px;
      border-radius: 0; }
    .mega-menu-site-responsive__body .mCSB_scrollTools .mCSB_draggerRail {
      width: 6px;
      border-radius: 0 !important; }
    .mega-menu-site-responsive__body .mCSB_scrollTools .mCSB_draggerContainer {
      left: -10px; }
    .mega-menu-site-responsive__body ul {
      list-style: none;
      text-align: right;
      margin-bottom: 0;
      padding: 22px 30px 17px 30px; }
      .mega-menu-site-responsive__body ul > li {
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
        .mega-menu-site-responsive__body ul > li a {
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #000000;
          font-size: 13px;
          font-weight: 400;
          padding: 14px 0 12px 0;
          line-height: 16px;
          transition: .3s; }
          .mega-menu-site-responsive__body ul > li a i {
            color: #000000;
            font-size: 17px;
            transition: .3s;
            margin-left: 15px; }
        .mega-menu-site-responsive__body ul > li.menu-item-has-children:after {
          content: '+';
          color: #d8132e;
          border-radius: 100%;
          width: 18px;
          height: 18px;
          border: 2px solid #d8132e;
          display: inline-block;
          text-align: center;
          line-height: 20px;
          position: absolute;
          left: 10px;
          top: 13px;
          -webkit-transition: .3s;
          transition: .3s;
          font-size: 22px;
          font-weight: 400; }
        .mega-menu-site-responsive__body ul > li.active:after {
          content: '-';
          color: #000000;
          border-color: #000000; }
        .mega-menu-site-responsive__body ul > li.active > a {
          color: #d9132e; }
          .mega-menu-site-responsive__body ul > li.active > a i {
            color: #d9132e; }
        .mega-menu-site-responsive__body ul > li > ul {
          list-style: none;
          margin-top: 9px;
          padding-right: 0; }
          .mega-menu-site-responsive__body ul > li > ul > li {
            position: relative;
            list-style: none;
            padding-right: 0;
            border-bottom: none; }
            .mega-menu-site-responsive__body ul > li > ul > li.active:before {
              content: '-'; }
            .mega-menu-site-responsive__body ul > li > ul > li > a {
              padding: 0;
              padding-bottom: 13px; }
  .mega-menu-site-responsive__footer {
    background-color: rgba(255, 255, 255, 0.97);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 35px;
    flex: 1 0 auto;
    max-height: 80px; }
    .mega-menu-site-responsive__footer a {
      margin-left: 20px; }
      .mega-menu-site-responsive__footer a i {
        color: #828282;
        font-size: 20px;
        transition: .3s;
        cursor: pointer; }
        .mega-menu-site-responsive__footer a i:hover {
          color: #12b540; }
  .mega-menu-site-responsive .logo-response {
    display: flex;
    justify-content: center;
    margin-bottom: 34px; }
    .mega-menu-site-responsive .logo-response i {
      font-size: 45px;
      color: #333333; }
  .mega-menu-site-responsive.active {
    right: 0; }
  .mega-menu-site-responsive__close {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer; }
    .mega-menu-site-responsive__close i {
      transition: .2s ease-in-out;
      font-size: 14px; }
    .mega-menu-site-responsive__close:hover i {
      color: #d9132e; }

.mask-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: 0.5s ease-in-out;
  visibility: hidden;
  top: 0;
  opacity: 0;
  z-index: 9999999999999;
  filter: blur(5px); }
  .mask-shadow.active {
    opacity: .8;
    visibility: visible; }

@media (max-width: 576px) {
  .container {
    padding: 0 15px; } }

body.overflow-none {
  overflow: hidden; }

@media (max-width: 1700px) {
  .amazing-offers__bg-image {
    display: none; } }

@media (max-width: 1450px) {
  .buyer {
    transform: scale(0.8); }
  .seller {
    transform: scale(0.8); } }

@media (max-width: 1350px) {
  .buyer__animation {
    right: 90px; }
  .buyer__item--info {
    right: -140px; }
  .seller__animation {
    left: 160px; } }

@media (max-width: 1200px) {
  .main-info {
    margin-top: -130px; }
  .buyer {
    transform: scale(0.7); }
    .buyer__animation {
      right: 40px; }
    .buyer__background {
      display: none; }
    .buyer__item .buyer__item--info {
      display: none; }
  .seller {
    transform: scale(0.7); }
    .seller__animation {
      left: 120px; }
    .seller__background {
      display: none; }
    .seller__item .seller__item--info {
      display: none; }
  .store {
    top: 340px; }
  .product__header h2 {
    margin-left: 15px; }
  .product__header .product__header-cat .product__header-cat-item {
    padding: 0 15px 0 15px; }
  .product__content .swiper-slide {
    transition: .1s;
    opacity: 0; }
    .product__content .swiper-slide.swiper-slide-active {
      opacity: 1; }
      .product__content .swiper-slide.swiper-slide-active + .swiper-slide {
        opacity: 1; }
        .product__content .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide {
          opacity: 1; }
          .product__content .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
            opacity: 0; }
  .top-cat {
    padding-top: 70px; }
    .top-cat__item .top-cat__item-image--first {
      left: 0; }
    .top-cat__item .top-cat__item-image--second {
      left: 0; }
    .top-cat__item .top-cat__item-image--third {
      left: 0; }
  .top-sellers__nav {
    display: flex; }
  .roadmap__road .roadmap__road-item--step1 {
    top: -62px;
    right: 17%; }
  .roadmap__road .roadmap__road-item--step2 {
    right: 32.5%;
    top: -55px; }
    .roadmap__road .roadmap__road-item--step2 > i {
      right: -38%;
      top: 84px; }
  .roadmap__road .roadmap__road-item--step3 {
    right: 49.5%;
    top: -73px; }
    .roadmap__road .roadmap__road-item--step3 > i {
      right: -24px;
      top: 109px; }
  .roadmap__road .roadmap__road-item--step4 {
    right: 67.9%;
    top: -56px; }
    .roadmap__road .roadmap__road-item--step4 > i {
      right: -27px;
      top: 76px; } }

@media (max-width: 992px) {
  .store {
    transform: scale(0.8);
    left: 35%; }
  .seller__animation {
    left: -60px; }
  .seller__item--info {
    top: initial;
    left: -30px; }
  .seller__item--arrow {
    display: none; }
  .buyer__animation {
    right: -80px; }
  .buyer__item--info {
    top: initial;
    right: -10px; }
  .buyer__item--arrow {
    display: none; }
  .product__header {
    margin-bottom: 30px; }
    .product__header .product__header-cat .product__header-cat-item {
      height: 25px;
      font-size: 13px;
      padding: 0 7px 0 7px; }
    .product__header .product__header-more {
      font-size: 13px; }
      .product__header .product__header-more i {
        font-size: 2px; }
    .product__header h2 {
      font-size: 15px; }
  .product__content .swiper-slide {
    transition: .1s;
    opacity: 0; }
    .product__content .swiper-slide.swiper-slide-active {
      opacity: 1; }
      .product__content .swiper-slide.swiper-slide-active + .swiper-slide {
        opacity: 1; }
        .product__content .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide {
          opacity: 0; }
          .product__content .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
            opacity: 0; }
  .top-cat {
    padding-bottom: 5px; }
    .top-cat__item {
      margin-bottom: 30px; }
  .top-sellers__content .top-sellers__content-swiper {
    padding-bottom: 30px;
    margin-bottom: -30px;
    padding-right: 30px;
    margin-right: -30px;
    padding-top: 30px;
    margin-top: -30px;
    padding-left: 0;
    margin-left: 0; }
  .roadmap__road .roadmap__road-item .roadmap__road-item-step {
    font-size: 50px; }
  .roadmap__road .roadmap__road-item--step1 {
    top: -62px;
    right: 16%; }
  .roadmap__road .roadmap__road-item--step2 {
    right: 31.5%;
    top: -55px; }
    .roadmap__road .roadmap__road-item--step2 > i {
      right: -25%;
      top: 77px; }
  .roadmap__road .roadmap__road-item--step3 {
    right: 48.5%;
    top: -73px; }
    .roadmap__road .roadmap__road-item--step3 > i {
      right: -13px;
      top: 99px; }
  .roadmap__road .roadmap__road-item--step4 {
    right: 66.9%;
    top: -56px; }
    .roadmap__road .roadmap__road-item--step4 > i {
      right: -27px;
      top: 76px; } }

@media (max-width: 768px) {
  .site-header__navigation ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .site-header__navigation ul li {
      margin-right: 15px;
      margin-bottom: 15px; }
  .seller {
    display: none; }
  .buyer {
    display: none; }
  .store {
    transform: scale(1) translate(-50%, 0);
    top: 20px;
    left: 50%; }
  .main-info {
    height: 210px;
    background-position: center 0;
    background-size: 74%;
    position: relative;
    margin-top: -50px; }
  .product__header {
    flex-wrap: wrap; }
    .product__header .product__header-cat {
      margin-top: 15px;
      width: 100%;
      order: 1; }
    .product__header .product__header-more {
      order: 0; }
  .amazing-offers__header {
    margin-bottom: 15px; }
    .amazing-offers__header .amazing-offers__header-title i {
      font-size: 25px; }
    .amazing-offers__header .amazing-offers__header-range {
      position: relative;
      top: 0; }
  .banners__banner {
    margin-bottom: 20px; }
  .roadmap__road .roadmap__road-item > i {
    font-size: 8px; }
  .roadmap__road .roadmap__road-item .roadmap__road-item-desc {
    display: none; }
  .roadmap__road .roadmap__road-item--step1 {
    top: -34px;
    right: 16.3%; }
  .roadmap__road .roadmap__road-item--step2 {
    right: 31.5%;
    top: -45px; }
    .roadmap__road .roadmap__road-item--step2 > i {
      right: -26%;
      top: 63px; }
  .roadmap__road .roadmap__road-item--step3 {
    right: 48.5%;
    top: -46px; }
    .roadmap__road .roadmap__road-item--step3 > i {
      right: -9px;
      top: 69px; }
  .roadmap__road .roadmap__road-item--step4 {
    right: 66.9%;
    top: -42px; }
    .roadmap__road .roadmap__road-item--step4 > i {
      right: -11px;
      top: 55px; } }

@media (max-width: 576px) {
  .store {
    top: 20px; }
    .store__create {
      margin-bottom: 15px; }
  .product__header {
    position: relative;
    margin-bottom: 15px; }
    .product__header:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 80px;
      height: 42px;
      background-image: linear-gradient(to left, transparent, #fff);
      pointer-events: none; }
    .product__header .product__header-cat {
      white-space: nowrap;
      padding-bottom: 10px;
      overflow: scroll; }
  .popular-cat {
    margin-bottom: 1px; }
    .popular-cat__header {
      margin-bottom: 15px; }
      .popular-cat__header .popular-cat__header-title {
        font-size: 15px;
        padding-left: 8px; }
      .popular-cat__header .popular-cat__header-link {
        padding-right: 8px; }
    .popular-cat__content .popular-cat__item {
      height: 155px;
      padding-bottom: 20px;
      padding-top: 20px; }
  .top-sellers {
    margin-top: 25px;
    margin-bottom: 25px; }
    .top-sellers__header {
      margin-bottom: 20px; }
      .top-sellers__header h2 {
        font-size: 15px; }
      .top-sellers__header .top-sellers__header-link {
        font-size: 13px; }
        .top-sellers__header .top-sellers__header-link i {
          font-size: 3px; }
  .amazing-offers {
    padding-bottom: 29px;
    margin-bottom: 0; }
    .amazing-offers__header {
      flex-wrap: wrap; }
      .amazing-offers__header .amazing-offers__header-range {
        order: 1; }
  .roadmap {
    margin-bottom: 50px; }
    .roadmap__header {
      margin-bottom: 60px; }
    .roadmap__road .roadmap__road-item .roadmap__road-item-icon {
      flex-direction: column;
      margin-bottom: 0; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-icon span {
        font-size: 14px; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-user-plus {
        font-size: 17px; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-cart {
        font-size: 18px;
        margin-left: 0; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-transportation {
        font-size: 17px;
        margin-left: 0; }
      .roadmap__road .roadmap__road-item .roadmap__road-item-icon i.icon-card {
        font-size: 15px;
        margin-left: 0; }
    .roadmap__road .roadmap__road-item .roadmap__road-item-step {
      font-size: 35px; }
    .roadmap__road .roadmap__road-item--step1 {
      top: -34px;
      right: 18.1%; }
    .roadmap__road .roadmap__road-item--step2 {
      right: 33.5%;
      top: -30px; }
      .roadmap__road .roadmap__road-item--step2 > i {
        right: -64%;
        top: 43px; }
      .roadmap__road .roadmap__road-item--step2 .roadmap__road-item-step {
        margin-bottom: 0; }
    .roadmap__road .roadmap__road-item--step3 {
      right: 50%;
      top: -39px; }
      .roadmap__road .roadmap__road-item--step3 > i {
        right: -18px;
        top: 55px; }
    .roadmap__road .roadmap__road-item--step4 {
      right: 68.5%;
      top: -31px; }
      .roadmap__road .roadmap__road-item--step4 > i {
        right: -20px;
        top: 39px; }
      .roadmap__road .roadmap__road-item--step4 .roadmap__road-item-step {
        margin-bottom: 0; }
  .site-header__momentary-products .site-header__momentary-products-title {
    justify-content: center; } }

@media (max-width: 400px) {
  .amazing-offers__header .amazing-offers__header-title i {
    font-size: 18px; }
  .amazing-offers__header .amazing-offers__header-link {
    font-size: 13px; }
    .amazing-offers__header .amazing-offers__header-link i {
      font-size: 3px; }
  .roadmap__road .roadmap__road-item--step1 {
    top: -41px;
    right: 16.1%; }
  .roadmap__road .roadmap__road-item--step2 {
    right: 31.5%;
    top: -30px; }
    .roadmap__road .roadmap__road-item--step2 > i {
      right: -34%;
      top: 41px; }
    .roadmap__road .roadmap__road-item--step2 .roadmap__road-item-step {
      margin-bottom: 0; }
  .roadmap__road .roadmap__road-item--step3 {
    right: 48%;
    top: -46px; }
    .roadmap__road .roadmap__road-item--step3 > i {
      right: -8px;
      top: 58px; }
  .roadmap__road .roadmap__road-item--step4 {
    right: 66.5%;
    top: -31px; }
    .roadmap__road .roadmap__road-item--step4 > i {
      right: -10px;
      top: 39px; }
    .roadmap__road .roadmap__road-item--step4 .roadmap__road-item-step {
      margin-bottom: 0;
      font-size: 30px; } }

@media (max-width: 1200px) {
  .product-info__gallery .product-info__gallery-items {
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: initial;
    overflow: scroll;
    padding-bottom: 20px; }
  .product-info__add-cart {
    padding: 0 14px 0 14px;
    margin-top: -76px; }
    .product-info__add-cart i {
      font-size: 20px;
      margin-left: 11px; }
    .product-info__add-cart .title {
      font-size: 15px; }
    .product-info__add-cart .price ins {
      font-size: 18px;
      margin-left: 6px; }
    .product-info__add-cart .price span {
      font-size: 13px; }
  .product-info__price {
    justify-content: center; }
    .product-info__price .product-info__price-items {
      margin-right: 0; }
    .product-info__price .product-info__price-features .product-info__price-features-items {
      margin-bottom: 15px; }
  .single-content .pl39 {
    padding-left: 10px; }
  .product-content__nav .product-content__nav-item {
    margin-left: 50px; }
  .seller-info {
    margin-top: -834px; }
    .seller-info__content {
      background-size: 120%; }
      .seller-info__content .seller-info__content-footer {
        padding: 0 10px; }
  .comments__form .comments__form-btn {
    flex-wrap: wrap; }
    .comments__form .comments__form-btn .item {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0; } }

@media (max-width: 992px) {
  .breadcrumb__content .breadcrumb__home {
    min-width: 154px; }
    .breadcrumb__content .breadcrumb__home i {
      margin-right: 10px; }
  .breadcrumb__content .breadcrumb__items {
    flex-wrap: wrap; }
    .breadcrumb__content .breadcrumb__items .breadcrumb__items-item {
      font-size: 12px; }
      .breadcrumb__content .breadcrumb__items .breadcrumb__items-item:after {
        font-size: 3px;
        margin-right: 10px; }
  .seller-head__info {
    margin-left: 0; }
    .seller-head__info .seller-head__info-item {
      margin-left: 18px; }
  .seller-head__logo {
    width: 220px; }
  .product-info__price {
    flex-wrap: wrap;
    justify-content: center; }
    .product-info__price .product-info__price-features {
      position: relative;
      overflow: hidden; }
      .product-info__price .product-info__price-features:before {
        content: '';
        left: 0;
        width: 60px;
        background-image: linear-gradient(to right, #fff, transparent);
        position: absolute;
        top: 0;
        bottom: 0;
        pointer-events: none; }
      .product-info__price .product-info__price-features .product-info__price-features-items {
        flex-wrap: nowrap;
        white-space: nowrap;
        padding-bottom: 15px;
        overflow: scroll; }
    .product-info__price .product-info__price-items {
      margin-right: 0; }
  .product-info__meta .product-info__meta-color {
    margin-bottom: 31px; }
    .product-info__meta .product-info__meta-color .item {
      margin-bottom: 8px; }
  .product-info__meta .product-info__meta-item {
    border-top: 1px solid #eceef3;
    padding-top: 20px; }
    .product-info__meta .product-info__meta-item .title {
      top: -18px; }
    .product-info__meta .product-info__meta-item--size .title {
      top: initial; }
  .product-info__gallery {
    overflow: scroll;
    padding: 20px;
    margin: -20px; }
  .product-info__information .product-info__information-perform .product-info__information-perform-title {
    margin-bottom: 15px; }
  .product-info__information .product-info__information-perform .product-info__information-perform-content .item {
    margin-bottom: 15px; }
    .product-info__information .product-info__information-perform .product-info__information-perform-content .item:nth-last-of-type(1) {
      margin-bottom: 0; }
  .product-info__information .product-info__information-properties {
    padding-top: 27px; }
    .product-info__information .product-info__information-properties .pr30 {
      padding-right: 0; }
    .product-info__information .product-info__information-properties .description {
      border-top: 1px solid #eeeff3;
      padding-top: 30px; }
      .product-info__information .product-info__information-properties .description__title {
        top: -14px;
        right: 0;
        padding-right: 0; }
    .product-info__information .product-info__information-properties .properties__title {
      top: -42px; }
  .product-info__add-cart {
    margin-top: -360px; }
  .single-content .pl39 {
    padding-left: 39px; }
  .seller-info {
    margin-top: -1270px; }
    .seller-info__content {
      background-size: 150%; }
      .seller-info__content .seller-info__content-footer {
        padding: 0 8px; }
  .product-content__nav {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: scroll;
    justify-content: flex-start;
    padding: 0 15px; }
  .comments__list .comments__list-item .comments__list-footer {
    flex-wrap: wrap; }
    .comments__list .comments__list-item .comments__list-footer .comments__list-date {
      margin-top: 15px; }
  .comments__list .comments__list-item--reply .comments__list-footer .comments__list-date {
    margin-top: 0; } }

@media (max-width: 768px) {
  .breadcrumb {
    margin-bottom: 15px; }
    .breadcrumb__content {
      position: relative;
      flex-wrap: wrap; }
      .breadcrumb__content:before {
        content: '';
        position: absolute;
        top: 0;
        height: 30px;
        left: 0;
        width: 80px;
        background-image: linear-gradient(to right, #fff, transparent);
        z-index: 20;
        pointer-events: none; }
      .breadcrumb__content .breadcrumb__items {
        overflow: scroll;
        padding-bottom: 13px;
        width: 100%;
        white-space: nowrap;
        flex-wrap: nowrap;
        margin-bottom: 11px; }
  .seller-head {
    padding-right: 30px; }
    .seller-head__logo {
      display: none; }
    .seller-head__info {
      margin-right: 0; }
    .seller-head__name {
      margin-left: 22px; }
  .product-info__add-cart {
    margin-top: 0; }
  .product-info__conversation {
    margin-bottom: 15px; }
  .product-info__price .product-info__price-features .product-info__price-features-items {
    margin-bottom: 10px; }
  .single-content .pl39 {
    padding-left: 0; }
  .seller-info {
    margin-top: 0; }
    .seller-info__content .seller-info__content-footer {
      padding: 0 25px; }
  .comments__list .comments__list-item .comments__list-footer {
    flex-wrap: wrap; }
    .comments__list .comments__list-item .comments__list-footer .comments__list-date {
      margin-top: 0; } }

@media (max-width: 576px) {
  .other-products {
    margin-bottom: 23px; }
    .other-products__header {
      padding-right: 15px;
      padding-top: 20px;
      margin-bottom: 20px; }
      .other-products__header .other-products__header-info .other-products__header-logo {
        margin-left: 8px; }
    .other-products--other-sellers .other-products__header {
      margin-bottom: 20px;
      padding-top: 20px; }
      .other-products--other-sellers .other-products__header > h2 {
        font-size: 14px; }
      .other-products--other-sellers .other-products__header .other-products__header-link {
        font-size: 12px; }
        .other-products--other-sellers .other-products__header .other-products__header-link i {
          margin-right: 8px; }
  .seller-head {
    height: initial;
    /*position: fixed;
    bottom: 0;
    right: 0;
    left: 0;*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 300;
    margin-bottom: 0;
    border-radius: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    flex-direction: column;
    overflow: visible; }
    .seller-head__logo {
      display: flex;
      position: absolute;
      background-size: contain;
      width: initial;
      height: initial;
      /* transform: rotate(180deg); */
      left: -30px;
      top: -39px;
      background-image: none;
      padding: 5px;
      border-radius: 60px;
      background-color: #fff;
      overflow: visible; }
    .seller-head__name {
      margin-left: 0;
      margin-bottom: 15px; }
      .seller-head__name .seller-head__name-title {
        text-align: center; }
    .seller-head__link {
      left: 15px;
      display: none; }
    .seller-head:before {
      border-radius: 10px; }
  .product-content {
    margin-bottom: 10px; }
    .product-content .features {
      margin-bottom: 10px; }
      .product-content .features table td {
        padding: 10px 0; }
      .product-content .features__key {
        border-left: 15px solid #fff; }
    .product-content__nav {
      height: 50px;
      margin-bottom: 20px; }
    .product-content__contents .product-content__contents-content p, .product-content__contents .product-content__contents-content h3 {
      margin-bottom: 13px; }
  .comments {
    margin-bottom: 20px; }
    .comments__list .comments__list-item {
      padding-bottom: 15px;
      margin-bottom: 15px; }
      .comments__list .comments__list-item .comments__list-footer {
        flex-wrap: wrap;
        justify-content: center; }
        .comments__list .comments__list-item .comments__list-footer .comments__list-date {
          margin-right: 0;
          margin-top: 20px; }
      .comments__list .comments__list-item .comments__list-header {
        margin-bottom: 10px; }
      .comments__list .comments__list-item .comments__list-text {
        line-height: 30px;
        margin-bottom: 10px; }
  .product-info {
    margin-bottom: 20px; }
    .product-info .pl39 {
      padding-left: 0; }
    .product-info .pr11 {
      padding-right: 0; }
    .product-info__add-cart {
      margin-top: 0;
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 200;
      margin-bottom: 0;
      border-radius: 0;
      opacity: .95;
      justify-content: center; }
      .product-info__add-cart .title {
        display: none; }
      .product-info__add-cart .title-responsive {
        display: block; }
      .product-info__add-cart .price {
        display: none; }
    .product-info__information .product-info__information-header {
      margin-bottom: 15px; }
    .product-info__information .product-info__information-perform {
      margin-bottom: 32px; }
    .product-info__meta {
      padding-bottom: 14px;
      margin-bottom: 20px; }
      .product-info__meta .product-info__meta-color {
        margin-bottom: 27px; }
      .product-info__meta .product-info__meta-item--size {
        position: fixed;
        visibility: hidden;
        opacity: 0;
        bottom: -100%;
        right: 0;
        width: 100%;
        transition: .4s ease-in-out;
        background-color: #fff;
        z-index: 1000;
        padding: 0 15px;
        margin-bottom: 0;
        padding-top: 50px;
        border-top: none;
        box-shadow: 0 -5px 25px -16px rgba(0, 0, 0, 0.4);
        border-radius: 15px 15px 0 0; }
        .product-info__meta .product-info__meta-item--size .product-info__meta-entry {
          display: flex;
          align-items: center;
          justify-content: center; }
        .product-info__meta .product-info__meta-item--size .close {
          font-size: 16px;
          color: #000;
          cursor: pointer;
          display: block;
          position: absolute;
          top: 15px;
          left: 15px;
          transition: .3s; }
        .product-info__meta .product-info__meta-item--size.active {
          visibility: visible;
          opacity: 1;
          bottom: 0; }
    .product-info__price {
      padding-bottom: 22px; }
  .variations-count {
    margin-bottom: 15px; }
  .seller-info {
    margin-top: 0;
    margin-bottom: 20px; }
  .single-content .pl39 {
    padding-left: 0; }
  .single-content .pr11 {
    padding-right: 0; } }

@media (max-width: 480px) {
  .seller-head__name .seller-head__name-title {
    font-size: 13px; }
  .seller-head__name h2 {
    font-size: 15px; }
  .seller-head .seller-head__info .seller-head__info-item {
    margin-left: 12px; }
    .seller-head .seller-head__info .seller-head__info-item i.icon-transportation {
      margin-bottom: 7px; }
    .seller-head .seller-head__info .seller-head__info-item i.icon-cube {
      margin-bottom: 5px; }
    .seller-head .seller-head__info .seller-head__info-item i.icon-location {
      margin-bottom: 3px; }
  .product-info__information .product-info__information-header {
    flex-wrap: wrap;
    margin-bottom: 11px; }
    .product-info__information .product-info__information-header .product-info__information-name {
      width: 100%;
      margin-bottom: 15px; }
      .product-info__information .product-info__information-header .product-info__information-name h2 {
        font-size: 21px; }
  .product-info__information .product-info__information-perform {
    margin-bottom: 30px; }
  .product-info__information .product-info__information-properties .properties__items-item {
    margin-bottom: 20px; }
  .seller-info__content .seller-info__content-meta .item__title {
    font-size: 12px; }
  .seller-info__content .seller-info__content-meta .item__value {
    font-size: 12px; }
  .seller-info__content .seller-info__content-footer {
    padding: 0 10px; }
    .seller-info__content .seller-info__content-footer .item span {
      font-size: 13px; }
  .product-content {
    margin-bottom: 15px; }
  .comments__list .comments__list-item--reply {
    padding: 32px 10px; } }

@media (max-width: 400px) {
  .seller-head {
    height: initial;
    flex-direction: column;
    align-items: center;
    background-size: 418%;
    padding: 10px 0; }
    .seller-head__name {
      width: 100%;
      margin-left: 0;
      align-items: center;
      margin-bottom: 10px; }
      .seller-head__name .seller-head__name-title {
        font-size: 12px; }
      .seller-head__name h2 {
        font-size: 13px; }
    .seller-head__link {
      left: 8px;
      font-size: 20px; }
  .comments__list .comments__list-item .comments__list-header .comments__list-name {
    margin-left: 10px; }
  .comments__list .comments__list-item .comments__list-footer .comments__list-like {
    margin-left: 15px; }
    .comments__list .comments__list-item .comments__list-footer .comments__list-like .like {
      margin-left: 10px; }
  .comments__list .comments__list-item .comments__list-footer .comments__list-reply .reply {
    margin-left: 10px; }
  .comments__list .comments__list-item--reply {
    padding: 32px 10px;
    padding-bottom: 15px !important; }
    .comments__list .comments__list-item--reply .comments__list-footer .comments__list-like {
      margin-left: 10px; }
      .comments__list .comments__list-item--reply .comments__list-footer .comments__list-like .like {
        margin-left: 5px; }
    .comments__list .comments__list-item--reply .comments__list-footer .comments__list-reply .reply {
      margin-left: 5px; } }

@media (max-width: 350px) {
  .comments__form .comments__form-btn .item input {
    width: 70%; } }

@media (max-width: 1200px) {
  .filters-summary__sorting .filters-summary__sorting-title {
    margin-left: 10px; }
  .filters-summary__sorting .filters-summary__sorting-items .filters-summary__sorting-item {
    padding: 0 10px 0 10px;
    font-size: 13px;
    margin-left: 5px; } }

@media (max-width: 992px) {
  .filters-summary {
    margin-bottom: 18px; }
    .filters-summary__sorting .filters-summary__sorting-items {
      display: none; }
    .filters-summary__sorting .filters-summary__sorting-responsive {
      display: block;
      flex: 0 1 30%; }
      .filters-summary__sorting .filters-summary__sorting-responsive .selectize-control {
        height: 35px; }
        .filters-summary__sorting .filters-summary__sorting-responsive .selectize-control.single .selectize-input:after {
          content: '\e90e';
          font-size: 8px;
          font-family: 'icomoon';
          border: none;
          width: initial;
          height: initial;
          top: 38%;
          transition: .3s;
          transform: rotateX(0); }
        .filters-summary__sorting .filters-summary__sorting-responsive .selectize-control.single .selectize-input.dropdown-active:after {
          transform: rotateX(180deg); }
    .filters-summary__apply .filters-summary__apply-content .filters-summary__apply-items .filters-summary__apply-item {
      margin-bottom: 10px; }
  .cat-products [class*=col]:nth-of-type(2n) .cat-products__item {
    border-left: none; }
  .cat-products [class*=col]:nth-of-type(2n + 1) .cat-products__item {
    border-right: none; } }

@media (max-width: 768px) {
  .filters-summary__sorting .filters-summary__sorting-responsive {
    flex: 0 1 40%; }
  .cat-filter-sidebar {
    overflow: scroll; }
    .cat-filter-sidebar .close {
      display: block; }
      .cat-filter-sidebar .close:hover {
        opacity: .8; }
  .cat-filter-sidebar {
    position: fixed;
    left: 0;
    right: 0;
    top: 100%;
    transition: .3s;
    z-index: 200;
    background-color: #fff; }
    .cat-filter-sidebar.active {
      top: 0;
      bottom: 0; }
  .cat-filter-responsive {
    display: flex;
    justify-content: center;
    font-size: 16px;
    position: fixed;
    background-color: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px 0;
    cursor: pointer;
    box-shadow: 0 -5px 32px -18px rgba(0, 0, 0, 0.4);
    z-index: 300; }
    .cat-filter-responsive i {
      font-size: 24px;
      color: #adbac9;
      margin-left: 20px; }
      .cat-filter-responsive i.icon-menu {
        font-size: 20px; }
  .filter__category {
    padding-left: 0;
    padding-bottom: 15px; }
    .filter__category .filter__category-head {
      margin-bottom: 10px; }
    .filter__category .filter__category-search {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-bottom: 15px; }
    .filter__category .filter__category-content h2 {
      margin-bottom: 15px; }
  .filter__brand {
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px; }
    .filter__brand .filter__brand-head {
      padding-bottom: 10px; }
    .filter__brand .filter__brand-search {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px; }
  .filter__true-false {
    padding-left: 0;
    padding-bottom: 15px;
    margin-bottom: 15px; }
  .filter__color {
    padding-left: 0; }
    .filter__color .filter__color-head {
      padding-bottom: 15px;
      margin-bottom: 15px; } }

@media (max-width: 576px) {
  .filters-summary {
    margin-bottom: 15px; }
    .filters-summary__sorting {
      flex-wrap: wrap;
      padding-top: 15px;
      margin-bottom: 10px; }
      .filters-summary__sorting .filters-summary__sorting-responsive {
        flex: 1 0 auto; }
      .filters-summary__sorting .filters-summary__sorting-count-product {
        width: 100%; }
    .filters-summary__apply .filters-summary__apply-header {
      margin-bottom: 15px; }
  .cat-filter-header {
    padding-bottom: 15px; }
    .cat-filter-header__wrapper {
      flex-wrap: wrap;
      justify-content: center; }
      .cat-filter-header__wrapper .cat-filter-header__breadcrumb {
        margin-bottom: 5px;
        flex-wrap: wrap; }
        .cat-filter-header__wrapper .cat-filter-header__breadcrumb a, .cat-filter-header__wrapper .cat-filter-header__breadcrumb span {
          margin-bottom: 10px; }
      .cat-filter-header__wrapper .cat-filter-header__amazing-offers {
        margin-right: 0; } }

@media (max-width: 450px) {
  .filters-summary__sorting .filters-summary__sorting-title {
    font-size: 14px;
    margin-left: 5px; }
  .filters-summary__sorting .filters-summary__sorting-responsive .selectize-control.single .selectize-input:after {
    left: 5px;
    font-size: 6px; } }

@media (max-width: 370px) {
  .filters-summary__sorting .filters-summary__sorting-title {
    margin-bottom: 15px; }
  .filters-summary__sorting .filters-summary__sorting-responsive {
    flex: 1 0 100%;
    margin-bottom: 15px; } }

@media (max-width: 576px) {
  .top-sellers--cat-one {
    margin-top: 25px;
    margin-bottom: 35px; }
    .top-sellers--cat-one .top-sellers__header {
      flex-wrap: wrap;
      margin-bottom: 15px; }
      .top-sellers--cat-one .top-sellers__header h2 {
        order: 0; }
      .top-sellers--cat-one .top-sellers__header .top-sellers__header-cat {
        width: 100%;
        margin-right: 0;
        order: 1;
        margin-top: 10px; }
      .top-sellers--cat-one .top-sellers__header .top-sellers__header-link {
        order: 0; }
  .cat-one-products {
    margin-bottom: 25px; }
    .cat-one-products__header {
      margin-bottom: 15px; }
  .catone-slider {
    margin-bottom: 10px;
    margin-top: 15px; }
  .cat-one-img {
    margin-bottom: 20px; }
    .cat-one-img__item {
      margin-bottom: 10px; }
  .cat-one-banner {
    margin-top: 30px;
    margin-bottom: 18px; }
  .cat-one-exclusive {
    padding: 15px 15px 5px 15px;
    margin-bottom: 20px; }
    .cat-one-exclusive__header {
      margin-bottom: 10px; }
    .cat-one-exclusive p {
      line-height: 30px; } }

@media (max-width: 1200px) {
  .cart-footer__wrapper {
    flex-wrap: wrap; }
    .cart-footer__wrapper .cart-footer__back {
      order: 1; }
    .cart-footer__wrapper .cart-footer__info {
      order: 0;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 15px; }
    .cart-footer__wrapper .cart-footer__continue {
      margin-right: auto;
      order: 2; }
  .cart-method__nav {
    display: flex; } }

@media (max-width: 992px) {
  .header-cart {
    padding-bottom: 15px; }
    .header-cart__wrapper {
      flex-wrap: wrap;
      justify-content: center !important; }
      .header-cart__wrapper .header-cart__breadcrumb {
        flex-wrap: wrap;
        justify-content: flex-end; }
      .header-cart__wrapper .header-cart__count {
        margin-bottom: 10px; }
  .cart-info__wrapper {
    flex-wrap: wrap;
    justify-content: space-between; }
    .cart-info__wrapper .cart-info__item {
      margin-left: 0; }
    .cart-info__wrapper .cart-info__continue {
      justify-content: center;
      width: 100%;
      margin-top: 8px; }
  .cart-items__item .cart-items__footer > div {
    margin-left: 25px; }
  .cart-footer__wrapper {
    justify-content: center; }
  .cart-footer__info {
    flex-wrap: wrap;
    margin-bottom: 15px;
    order: 0; }
  .cart-footer .cart-footer__back {
    order: 1; }
  .cart-footer .cart-footer__continue {
    margin-right: auto;
    order: 2; }
  .cart-address__items .cart-address__items-item {
    flex-wrap: wrap; }
  .cart-method__coupon {
    flex-wrap: wrap; }
    .cart-method__coupon .cart-method__coupon-title {
      margin-bottom: 15px; } }

@media (max-width: 768px) {
  .cart-info {
    padding: 15px 0 15px 0; }
    .cart-info__wrapper .cart-info__item {
      margin-left: 0; }
      .cart-info__wrapper .cart-info__item .cart-info__item-title {
        font-size: 14px; }
      .cart-info__wrapper .cart-info__item .cart-info__item-value ins {
        font-size: 16px; }
      .cart-info__wrapper .cart-info__item .cart-info__item-value .unit {
        font-size: 12px; }
  .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-title {
    font-size: 20px;
    margin-bottom: 30px; }
  .cart-items__item .cart-items__footer .cart-items__item-price .price {
    font-size: 20px; }
  .cart-items__item .cart-items__footer .cart-items__item-price .unit {
    font-size: 13px; }
  .cart-footer__wrapper .cart-footer__info {
    justify-content: center;
    margin-bottom: 5px; }
    .cart-footer__wrapper .cart-footer__info .cart-footer__info-item {
      margin-left: 30px;
      margin-bottom: 10px; } }

@media (max-width: 576px) {
  .cart-info {
    margin-bottom: 15px; }
    .cart-info__wrapper {
      justify-content: center; }
      .cart-info__wrapper .cart-info__item {
        margin-left: 20px;
        margin-bottom: 8px; }
      .cart-info__wrapper .cart-info__continue {
        margin-top: 0; }
  .cart-items {
    margin-bottom: 15px; }
    .cart-items__item {
      padding: 15px 0 15px 0;
      margin-bottom: 15px; }
      .cart-items__item .wrapper {
        flex-wrap: wrap;
        justify-content: center; }
      .cart-items__item .cart-items__item-img {
        margin-bottom: 10px; }
      .cart-items__item .cart-items__item-info {
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 20px; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-title {
          text-align: center;
          margin-bottom: 10px; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-right .cart-items__item-meta {
          justify-content: center; }
        .cart-items__item .cart-items__item-info .cart-items__item-info-left {
          margin-right: 0; }
    .cart-items__footer {
      flex-wrap: wrap;
      justify-content: center; }
      .cart-items__footer .cart-items__footer-seller {
        order: 1;
        margin-bottom: 5px; }
      .cart-items__footer .cart-items__footer-available {
        order: 1;
        margin-bottom: 5px; }
      .cart-items__footer .cart-items__footer-item {
        order: 1;
        margin-bottom: 5px; }
      .cart-items__footer .cart-items__item-price {
        width: 100%;
        justify-content: center; }
  .cart-footer {
    padding: 15px 0;
    margin-bottom: 15px; }
    .cart-footer__info {
      flex-wrap: wrap;
      margin-bottom: 15px;
      order: 0; }
    .cart-footer .cart-footer__back {
      order: 2;
      width: 100%; }
    .cart-footer .cart-footer__continue {
      margin-right: 0;
      order: 1;
      width: 100%;
      margin-bottom: 10px; }
  .cart-address {
    padding: 15px 0 15px 0;
    margin-bottom: 15px; }
    .cart-address__title {
      margin-bottom: 15px;
      font-size: 20px; }
    .cart-address__items .cart-address__items-item {
      padding-bottom: 15px;
      margin-bottom: 15px; }
      .cart-address__items .cart-address__items-item .cart-address__items-address {
        margin-bottom: 7px; }
  .cart-method {
    padding-top: 12px;
    padding-bottom: 15px;
    margin-bottom: 15px; }
    .cart-method__title {
      margin-bottom: 47px; }
    .cart-method__items {
      margin-bottom: 20px; }
    .cart-method__coupon .cart-method__coupon-input {
      flex-wrap: wrap; }
      .cart-method__coupon .cart-method__coupon-input input {
        margin-bottom: 15px;
        width: 100%;
        margin-left: 0; } }

@media (max-width: 400px) {
  .cart-items__footer {
    flex-wrap: wrap;
    justify-content: center; }
    .cart-items__footer .cart-items__footer-seller {
      margin-left: 0 !important;
      width: 100%;
      justify-content: center; }
    .cart-items__footer .cart-items__footer-available {
      margin-left: 0 !important;
      width: 100%;
      display: flex;
      justify-content: center; }
    .cart-items__footer .cart-items__footer-item {
      margin-left: 0 !important;
      width: 100%;
      justify-content: center;
      display: flex; } }

@media (max-width: 1200px) {
  .not-found {
    background-image: none; } }

@media (max-width: 576px) {
  .not-found {
    padding: 0 15px;
    padding-bottom: 60px;
    padding-top: 20px; }
    .not-found__title {
      margin-bottom: 10px;
      text-align: center; }
    .not-found__redirect {
      margin-bottom: 50px;
      text-align: center; }
    .not-found__img {
      margin-bottom: 30px; }
    .not-found__footer .not-found__footer-item {
      width: 90px;
      height: 85px;
      margin-left: 15px; }
      .not-found__footer .not-found__footer-item i {
        margin-bottom: 10px; }
    .not-found__ivahid {
      bottom: 10px;
      right: 38px;
      width: 100%; } }

@media (max-width: 768px) {
  .login-wrapper {
    background-image: none; } }

@media (max-width: 576px) {
  .login-wrapper {
    padding: 0 15px; }
  .login {
    padding-top: 40px;
    padding-bottom: 50px; }
    .login__form {
      padding: 20px 15px 20px 15px;
      margin-bottom: 20px; }
      .login__form .login__form-btn {
        flex-wrap: wrap;
        margin-bottom: 15px; }
        .login__form .login__form-btn button {
          width: 100%;
          margin-left: 0;
          margin-bottom: 10px; }
        .login__form .login__form-btn .login__form-register {
          width: 100%; }
      .login__form .login__form-label {
        font-size: 14px; }
      .login__form input {
        margin-bottom: 10px; }
    .login__header {
      margin-bottom: 15px; } }

@media (max-width: 992px) {
  .site-header.page {
    margin-bottom: 25px; }
  .header-page {
    padding-bottom: 25px; }
    .header-page figure {
      display: none; }
  .location {
    flex-direction: column;
    align-items: center; }
    .location__map {
      margin-left: 0;
      margin-bottom: 20px; }
  .contact-us {
    flex-wrap: wrap; }
    .contact-us__logo {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px; }
    .contact-us form .inputs {
      flex-wrap: wrap; }
    .contact-us form .contact-us__inputs {
      max-width: 100%;
      width: 100%;
      margin-left: 0; }
    .contact-us form .contact-us__textarea {
      max-width: 100%;
      width: 100%; } }

@media (max-width: 576px) {
  .location {
    margin-top: 15px;
    margin-bottom: 5px; }
    .location__items:before {
      display: none; }
    .location__items .location__items-item {
      flex-wrap: wrap;
      margin-bottom: 15px; }
      .location__items .location__items-item i {
        margin-right: -47px;
        margin-left: 5px; }
      .location__items .location__items-item .location__items-info .location__items-info-title {
        margin-bottom: 5px; }
  .site-header.page {
    margin-bottom: 15px; }
  .header-page {
    padding-bottom: 15px;
    margin-bottom: 15px; }
  .contact-us {
    margin-top: 15px;
    margin-bottom: 15px; }
    .contact-us__logo {
      margin-bottom: 10px; }
    .contact-us form .contact-us__inputs .contact-us__inputs-item {
      margin-bottom: 10px; }
  .page-content {
    margin-bottom: 10px; } }

@media (max-width: 576px) {
  .login__code {
    margin-bottom: 20px; }
    .login__code .login__code-inputs {
      flex-wrap: wrap;
      margin-bottom: 10px; }
      .login__code .login__code-inputs input {
        width: 50%;
        max-width: initial;
        margin-bottom: 8px;
        margin-left: 4px; }
        .login__code .login__code-inputs input:nth-last-of-type(1) {
          margin-left: 4px !important; }
    .login__code .login__code-footer {
      flex-wrap: wrap; }
      .login__code .login__code-footer .login__code-btn {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px; }
      .login__code .login__code-footer .login__code-resend {
        width: 100%; } }

@media (max-width: 1200px) {
  .site-footer__content {
    margin-bottom: 25px; }
    .site-footer__content .site-footer__content-enamad {
      margin-top: 25px;
      flex-direction: row !important;
      justify-content: center !important; }
      .site-footer__content .site-footer__content-enamad .item {
        margin-bottom: 0 !important;
        margin-left: 25px; }
  .site-footer__header .site-footer__header-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1; }
    .site-footer__header .site-footer__header-info .working-hours {
      margin-left: 0; } }

@media (max-width: 992px) {
  .site-footer__content .site-footer__content-social {
    margin-top: 45px; }
  .site-footer__content .site-footer__content-enamad {
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 45px; }
    .site-footer__content .site-footer__content-enamad .item {
      margin-bottom: 25px !important;
      margin-left: 0 !important; }
  .site-footer__footer {
    flex-wrap: wrap;
    justify-content: center; }
    .site-footer__footer .site-footer__footer-service {
      margin-right: 0 !important; }
    .site-footer__footer .site-footer__footer-feature {
      overflow: hidden;
      position: relative; }
      .site-footer__footer .site-footer__footer-feature .items {
        white-space: nowrap;
        overflow: scroll; }
      .site-footer__footer .site-footer__footer-feature:before {
        content: '';
        position: absolute;
        left: 0;
        width: 50px;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to right, #edf0f4, transparent); }
  .site-footer__header {
    flex-wrap: wrap;
    justify-content: center; }
    .site-footer__header figure {
      margin-left: 0;
      margin-bottom: 15px; }
    .site-footer__header .site-footer__header-phone {
      margin-right: 0 !important;
      margin-top: 15px; } }

@media (max-width: 576px) {
  .site-footer {
    padding-top: 0 !important; }
    .site-footer__content {
      margin-bottom: 15px !important; }
      .site-footer__content > .row {
        justify-content: center; }
      .site-footer__content .site-footer__content-item {
        margin-bottom: 20px; }
        .site-footer__content .site-footer__content-item ul li {
          margin-bottom: 10px; }
        .site-footer__content .site-footer__content-item--two {
          text-align: left; }
        .site-footer__content .site-footer__content-item--three {
          text-align: center; }
      .site-footer__content .site-footer__content-enamad {
        margin-top: 25px;
        flex-direction: row !important;
        justify-content: center !important; }
        .site-footer__content .site-footer__content-enamad .item {
          margin-bottom: 0 !important;
          margin-left: 25px !important; }
      .site-footer__content .site-footer__content-social {
        margin-top: 0 !important; }
        .site-footer__content .site-footer__content-social .newsletters {
          margin-bottom: 15px !important; }
          .site-footer__content .site-footer__content-social .newsletters .title {
            margin-bottom: 15px !important; }
    .site-footer__header {
      margin-bottom: 15px !important; }
      .site-footer__header .site-footer__header-info .working-hours {
        margin-left: 0 !important;
        text-align: center; }
      .site-footer__header .site-footer__header-info .customer-guide {
        text-align: center; }
    .site-footer__desc {
      text-align: justify;
      line-height: 35px !important;
      margin-bottom: 10px !important; }
    .site-footer__copy-right p {
      max-width: 250px !important; } }

@media (min-width: 1600px) {
  .mega-menu-cat > .container .mega-menu-cat__cat-wrapper {
    right: 400px; } }

@media (min-width: 1800px) {
  .mega-menu-cat > .container .mega-menu-cat__cat-wrapper {
    right: 500px; } }

@media (max-width: 1200px) {
  .site-header__left {
    justify-content: center; }
    .site-header__left .site-header__left-item--user-plus {
      margin-left: 30px; }
    .site-header__left .site-header__search {
      margin-left: 30px; } }

@media (max-width: 992px) {
  .mega-menu-cat .container > ul > li .mega-menu-cat__cat-wrapper {
    right: 200px; }
    .mega-menu-cat .container > ul > li .mega-menu-cat__cat-wrapper .container {
      flex-direction: column;
      justify-content: center; }
  .mega-menu-cat .container > ul > li .mega-menu-cat__cat-img {
    margin-right: 0;
    margin-top: 15px; }
  .mega-menu-cat .container .mega-menu-cat__cat-wrapper .container {
    flex-wrap: wrap; }
  .site-header__wrapper {
    flex-wrap: wrap; }
  .site-header__right {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px; }
  .site-header__left {
    margin-right: 0;
    width: 100%;
    justify-content: center; } }

@media (max-width: 768px) {
  .site-header__left {
    flex-wrap: wrap; }
    .site-header__left .site-header__search {
      width: 100%;
      margin-left: 0;
      justify-content: center;
      margin-bottom: 20px; }
  .mega-menu-cat .mega-menu-cat__cat-sub {
    flex-wrap: wrap; }
    .mega-menu-cat .mega-menu-cat__cat-sub .mega-menu-cat__cat-sub-item {
      margin-left: 50px; } }

@media (max-width: 576px) {
  .mega-menu-cat {
    display: none; }
  .site-header__wrapper {
    margin-top: 50px;
    margin-bottom: 15px; }
  .site-header__navigation {
    display: none; }
  .site-header .response-head {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 24px -14px rgba(0, 0, 0, 0.2);
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.9); }
    .site-header .response-head__menu {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      width: 50px;
      height: 50px;
      border-radius: 60px;
      box-shadow: 5px 3px 25px -11px rgba(0, 0, 0, 0.23);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
  .site-header .mega-menu-site-responsive__location {
    display: flex;
    align-items: center;
    border: 1px solid #e7e8f2;
    border-radius: 5px;
    padding: 0 15px 0 17px;
    height: 36px;
    cursor: pointer; }
    .site-header .mega-menu-site-responsive__location i {
      font-size: 18px;
      color: #4d5367;
      margin-left: 13px; }
    .site-header .mega-menu-site-responsive__location span {
      color: #4d5367;
      font-size: 15px;
      font-weight: 700; }
  .site-header .mega-menu-site-responsive__search button i {
    font-size: 17px;
    color: #5d6273; }
  .site-header__right {
    display: none; }
  .site-header__left .site-header__search {
    display: none; }
  .site-header__momentary-products .swiper-container {
    padding-top: 12px;
    padding-bottom: 15px;
    padding-right: 15px; }
  .site-header__momentary-products .pl20 {
    padding-left: 15px;
    padding-right: 15px; }
  .site-header__momentary-products-title {
    justify-content: center !important;
    padding-top: 5px !important;
    padding-bottom: 10px; } }

@media (max-width: 400px) {
  .response-head__logo {
    padding-right: 15px; } }

/* footer */
.site-footer {
  background-color: #edf0f4;
  padding-top: 85px;
  padding-bottom: 50px; }
  .site-footer__header {
    display: flex;
    align-items: center;
    margin-bottom: 52px; }
    .site-footer__header figure {
      margin-left: 42px; }
    .site-footer__header .site-footer__header-info {
      border-top: 1px solid #d4dbe7;
      border-bottom: 1px solid #d4dbe7;
      padding-top: 6px;
      padding-bottom: 10px; }
      .site-footer__header .site-footer__header-info .working-hours {
        color: #7d818e;
        font-size: 12.33px;
        font-weight: 700;
        margin-left: 58px; }
      .site-footer__header .site-footer__header-info .customer-guide {
        color: #7d818e;
        font-size: 12.33px;
        font-weight: 400; }
        .site-footer__header .site-footer__header-info .customer-guide a {
          color: #454a5b;
          font-weight: 700; }
    .site-footer__header .site-footer__header-phone {
      height: 40px;
      border-radius: 60px;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      padding: 0 24px 0 21px;
      box-shadow: 0 20px 14px -7px rgba(0, 0, 0, 0.06);
      margin-right: auto; }
      .site-footer__header .site-footer__header-phone .phone {
        color: #454a5b;
        font-size: 17.5px;
        font-weight: 700;
        letter-spacing: 0.42px;
        margin-left: 4px; }
      .site-footer__header .site-footer__header-phone .pre-phone {
        color: #767b8e;
        font-size: 16px;
        font-weight: 400; }
      .site-footer__header .site-footer__header-phone i {
        font-size: 18px;
        color: #ef473a;
        margin-right: 15px; }
  .site-footer__content {
    margin-bottom: 47px; }
    .site-footer__content .site-footer__content-item ul li {
      color: #454a5b;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 18px;
      transition: .3s; }
      .site-footer__content .site-footer__content-item ul li:last-child {
        margin-bottom: 0; }
      .site-footer__content .site-footer__content-item ul li:hover {
        color: #12b540; }
    .site-footer__content .site-footer__content-social .newsletters {
      margin-bottom: 43px; }
      .site-footer__content .site-footer__content-social .newsletters .title {
        color: #454a5b;
        font-size: 15px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        top: -1px;
        margin-bottom: 29px; }
      .site-footer__content .site-footer__content-social .newsletters form {
        max-width: 376px;
        position: relative; }
        .site-footer__content .site-footer__content-social .newsletters form input {
          width: 100%;
          height: 58px;
          background-color: #dee4eb;
          border-radius: 8px;
          padding-right: 23px; }
          .site-footer__content .site-footer__content-social .newsletters form input::placeholder {
            color: #797e90;
            font-size: 16px;
            font-weight: 400;
            opacity: 1; }
        .site-footer__content .site-footer__content-social .newsletters form button {
          width: 54px;
          height: 44px;
          box-shadow: 0 2px 2px rgba(80, 92, 106, 0.11);
          background-color: #edf0f4;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 7px;
          top: 50%;
          transform: translate(0, -50%);
          border-radius: 6px; }
          .site-footer__content .site-footer__content-social .newsletters form button i {
            font-size: 16px;
            color: #12b540; }
    .site-footer__content .site-footer__content-social .social-media .title {
      color: #454a5b;
      font-size: 15px;
      font-weight: 400;
      display: inline-block;
      margin-bottom: 30px; }
    .site-footer__content .site-footer__content-social .social-media__content {
      display: flex;
      align-items: center; }
      .site-footer__content .site-footer__content-social .social-media__content .item {
        display: flex;
        align-items: center;
        margin-left: 10px;
        transition: .3s;
        height: 20px; }
        .site-footer__content .site-footer__content-social .social-media__content .item i {
          font-size: 19px;
          color: #878d9f;
          margin-left: 21px;
          transition: .3s; }
        .site-footer__content .site-footer__content-social .social-media__content .item span {
          color: #454a5b;
          font-size: 0;
          font-weight: 700;
          transition: .3s; }
        .site-footer__content .site-footer__content-social .social-media__content .item:hover {
          margin-left: 37px; }
          .site-footer__content .site-footer__content-social .social-media__content .item:hover i {
            color: #454a5b; }
          .site-footer__content .site-footer__content-social .social-media__content .item:hover span {
            font-size: 14px; }
        .site-footer__content .site-footer__content-social .social-media__content .item:last-child {
          margin-left: 0; }
    .site-footer__content .site-footer__content-enamad {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end; }
      .site-footer__content .site-footer__content-enamad .item:nth-of-type(1) {
        margin-bottom: 32px; }
      .site-footer__content .site-footer__content-enamad .item:nth-of-type(2) {
        margin-bottom: 24px; }
  .site-footer__footer {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
    .site-footer__footer .site-footer__footer-feature {
      margin-bottom: 10px; }
      .site-footer__footer .site-footer__footer-feature .items {
        display: flex;
        align-items: center;
        border-top: 1px solid  #d4dbe7;
        border-bottom: 1px solid  #d4dbe7;
        padding: 17px 0 19px 0; }
      .site-footer__footer .site-footer__footer-feature .item {
        display: flex;
        align-items: center;
        color: #454a5b;
        font-size: 12.1px;
        font-weight: 700;
        margin-left: 45px; }
        .site-footer__footer .site-footer__footer-feature .item i {
          color: #454a5b;
          margin-left: 19px; }
          .site-footer__footer .site-footer__footer-feature .item i.icon-cube {
            font-size: 24px; }
          .site-footer__footer .site-footer__footer-feature .item i.icon-support {
            font-size: 22px; }
          .site-footer__footer .site-footer__footer-feature .item i.icon-ask {
            font-size: 22px; }
          .site-footer__footer .site-footer__footer-feature .item i.icon-transportation {
            font-size: 20px; }
          .site-footer__footer .site-footer__footer-feature .item i.icon-card {
            font-size: 20px; }
        .site-footer__footer .site-footer__footer-feature .item:nth-last-of-type(1) {
          margin-left: 0; }
    .site-footer__footer .site-footer__footer-service {
      height: 51px;
      background-color: #edf0f4;
      width: 238px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      box-shadow: -32px 21px 32px -22px rgba(0, 0, 0, 0.45);
      transition: .3s;
      padding-bottom: 11px;
      margin-right: auto; }
      .site-footer__footer .site-footer__footer-service span {
        color: #454a5b;
        font-size: 13.2px;
        font-weight: 700;
        margin-left: 27px; }
      .site-footer__footer .site-footer__footer-service i {
        font-size: 23px;
        color: #b6c0cd; }
      .site-footer__footer .site-footer__footer-service:hover {
        box-shadow: -22px 15px 32px -27px rgba(0, 0, 0, 0.45); }
  .site-footer__desc {
    color: #5f6373;
    font-size: 13px;
    font-weight: 400;
    line-height: 39px;
    margin-bottom: 42px; }
  .site-footer__copy-right {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .site-footer__copy-right p {
      max-width: 382px;
      color: #5f6373;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px; }
  .site-footer--sec-footer {
    position: relative; }
    .site-footer--sec-footer:after {
      content: '';
      position: absolute;
      top: 0;
      height: 272px;
      left: 0;
      right: 0;
      background-color: #ffffff;
      background-image: linear-gradient(to top, #edf0f4 0%, #ffffff 100%);
      z-index: 5; }
    .site-footer--sec-footer .site-footer__header {
      position: relative;
      z-index: 10; }
    .site-footer--sec-footer .site-footer__content {
      position: relative;
      z-index: 10; }
    .site-footer--sec-footer .site-footer__footer {
      position: relative;
      z-index: 10; }
    .site-footer--sec-footer .site-footer__desc {
      position: relative;
      z-index: 10; }
    .site-footer--sec-footer .site-footer__copy-right {
      position: relative;
      z-index: 10; }

/* general */
body {
  text-align: right;
  direction: rtl;
  font-family: iranyekan; }

.pl20 {
  padding-left: 20px; }

.pr11 {
  padding-right: 11px; }

.p30 {
  margin: 0 -15px; }
  .p30 > [class*=col-] {
    padding: 0 15px; }

.pr30 {
  padding-right: 30px; }

.pl39 {
  padding-left: 39px; }

.wrapper {
  overflow: hidden; }

.cart-wrapper {
  background-color: #f7f8fb;
  padding-top: 22px; }
