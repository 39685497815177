.cat-one-exclusive{
  box-shadow: 0 1px 2px rgba(75, 90, 105, 0.17);
  background-color: #f3f5f9;
  padding: 38px 33px 35px 33px;
  border-radius: 10px;
  margin-bottom: 53px;
  &__header{
    margin-bottom: 18px;
    h2{
      color: #848c9c;
      font-size: 17px;
      font-weight: 700;
    }
  }
  p{
    color: #656e80;
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
    line-height: 32px;
  }
}