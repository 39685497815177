.catone-slider{
  margin-top: 37px;
  margin-bottom: 30px;
  .cat-one__slider{
    overflow: visible;
    .swiper-slide{
      opacity: .3;
      transition: .3s;
      &.swiper-slide-active{
        opacity: 1;
      }
    }
  }
  &__image{
    border-radius: 10px;
    overflow: hidden;
  }
}