.breadcrumb{
  $self:&;
  margin-bottom: 50px;
  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    #{$self}__items{
      display: flex;
      align-items: center;
      #{$self}__items-item{
        color: #7b808e;
        font-size: 13px;
        font-weight: 400;
        margin-left: 15px;
        &:after{
          content: '\e92a';
          font-family: 'icomoon';
          font-size: 4px;
          color: #c5c8d2;
          margin-right: 16px;
          pointer-events: none;
        }
        &:nth-last-of-type(1){
          margin-left: 0;
          &:after{
            display: none;
          }
        }
      }
    }
    #{$self}__home{
      color: #383c48;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      i{
        font-size: 13px;
        color: #383c48;
        margin-right: 18px;
      }
    }
  }

}