@media (max-width: 1200px) {
  .site-footer {
    $self: &;

    &__content {
      margin-bottom: 25px;

      #{$self}__content-enamad {
        margin-top: 25px;
        flex-direction: row !important;
        justify-content: center !important;

        .item {
          margin-bottom: 0 !important;
          margin-left: 25px;
        }
      }
    }

    &__header {
      #{$self}__header-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;

        .working-hours {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .site-footer {
    $self: &;

    &__content {
      #{$self}__content-social {
        margin-top: 45px;
      }

      #{$self}__content-enamad {
        flex-direction: column !important;
        justify-content: center !important;
        margin-top: 45px;

        .item {
          margin-bottom: 25px !important;
          margin-left: 0 !important;
        }
      }
    }

    &__footer {
      flex-wrap: wrap;
      justify-content: center;

      #{$self}__footer-service {
        margin-right: 0 !important;
      }

      #{$self}__footer-feature {
        overflow: hidden;
        position: relative;

        .items {
          white-space: nowrap;
          overflow: scroll;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 50px;
          top: 0;
          bottom: 0;
          background-image: linear-gradient(to right, #edf0f4, transparent);
        }
      }
    }

    &__header {
      flex-wrap: wrap;
      justify-content: center;

      figure {
        margin-left: 0;
        margin-bottom: 15px;
      }

      #{$self}__header-phone {
        margin-right: 0 !important;
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 576px) {
  .site-footer {
    $self: &;
    padding-top: 0 !important;

    &__content {
      margin-bottom: 15px !important;
      > .row {
        justify-content: center;
      }
      #{$self}__content-item {
        margin-bottom: 20px;

        ul {
          li {
            margin-bottom: 10px;
          }
        }

        &--two {
          text-align: left;
        }

        &--three {
          text-align: center;
        }
      }
      #{$self}__content-enamad {
        margin-top: 25px;
        flex-direction: row !important;
        justify-content: center !important;

        .item {
          margin-bottom: 0 !important;
          margin-left: 25px !important;
        }
      }
      #{$self}__content-social{
        margin-top: 0 !important;
        .newsletters {
          margin-bottom: 15px !important;
          .title{
            margin-bottom: 15px !important;
          }
        }
      }
    }

    &__header {
      margin-bottom: 15px !important;

      #{$self}__header-info {
        .working-hours {
          margin-left: 0 !important;
          text-align: center;
        }

        .customer-guide {
          text-align: center;
        }
      }
    }

    &__desc{
      text-align: justify;
      line-height: 35px !important;
      margin-bottom: 10px !important;
    }
    &__copy-right{
      p{
        max-width: 250px !important;
      }
    }
  }
}