.mega-menu-cat {
  position: absolute;
  right: 0;
  left: 0;
  top: 100px;
  background-color: #fff;
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  border-top: 1px solid #edf0f3;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, .08);
  $self: &;

  .container {
    .tab-wrapper {
      display: flex;
    }

    .tab-title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: 20px;
      //padding-top: 20px;
      flex: 1 0 auto;
      border-left: 1px solid #edf0f3;
      min-width: 150px;
      height: 464px;
      > li {
        //margin-left: 50px;
        width: 100%;
        padding: 10px 15px;
        border-bottom: 1px solid #e5e7f1;
        transition: .3s;

        #{$self}__cat-item {
          color: #38424d;
          font-size: 13px;
          font-weight: 500;
          transition: .3s;
        }

        &.active {
          background-color: #f7f7f7;

          #{$self}__cat-item {
            color: #0661f8;
          }

          #{$self}__cat-wrapper {
            visibility: visible;
            opacity: 1;
          }
        }
      }

    }

    #{$self}__cat-wrapper {
      display: flex;
      align-items: center;
      //position: absolute;
      //top: 0;
      //right: 300px;
      //left: 0;
      transition: .3s;
      background-color: #fff;
      padding-top: 12px;
      padding-bottom: 33px;
      padding-right: 15px;
      padding-left: 15px;
      overflow: hidden;

      .container {
        display: flex;
        align-items: center;
        //opacity: 0;
        //visibility: hidden;
        //transform: translate(-200px,0) rotateY(50deg);
        //transition: .8s cubic-bezier(.18,.19,.02,.99);
        //flex-wrap: wrap;
      }

      /*&.active{
        .container{
          opacity: 1;
          visibility: visible;
          transform: translate(0,0) rotateY(0);
          //transition: .3s;
        }
      }*/
    }

    #{$self}__cat-sub {
      display: flex;
      align-items: center;

      #{$self}__cat-sub-item {
        margin-left: 50px;
        flex: 1 0 auto;
        /*.title{
          color: #0661f8;
          font-size: 15px;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 26px;
        }*/
        > ul {
          height: 400px;
          column-count: 4;
          column-fill: auto;

          .title {
            a {
              color: #38424d;
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              transition: .3s;

              &:after {
                content: '\e90e';
                font-family: 'icomoon';
                font-size: 6px;
                color: #38424d;
                transform: rotate(90deg);
                margin-right: 5px;
              }

              &:hover {
                color: #0661f8;

                &:after {
                  color: #0661f8;
                }
              }
            }

            &--lists {
              ul {
                li {
                  a {
                    color: #38424d;
                    font-size: 12px;
                    font-weight: 400;

                    &:after {
                      display: none;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }

    #{$self}__cat-img {
      margin-right: auto;
    }
  }

  &.active {
    top: 67%;
    visibility: visible;
    opacity: 1;
  }
}