.cat-one-img{
  margin-bottom: 50px;
  &__item{
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
  }
  &__big{
    border-radius: 15px;
    overflow: hidden;
  }
}